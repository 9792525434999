import { connect } from 'react-redux';
import React, { Component } from 'react';
import UserManagement from './UserManagement'
import * as actions from '../../../redux/actions/actionsUser';
import { reducer } from 'aws-cognito-redux-saga'

//add props from reducer here
const mapStateToProps = store =>
({
    isUserAccountsLoading: store.userServicesReducer.isUserAccountsLoading,
    userAccountData: store.userServicesReducer.userAccountData,

    userSignInData: store.userServicesReducer.userSignInData,
    currentUserEmail: store.userServicesReducer.currentUserEmail,

    isAllUsersLoading: store.userServicesReducer.isAllUsersLoading,
    AllUsersData: store.userServicesReducer.AllUsersData,

    PostApproveUserSuccessful: store.userServicesReducer.PostApproveUserSuccessful,


    UserInviteNewUserPostSuccessful: store.userServicesReducer.UserInviteNewUserPostSuccessful,

});

//add actions here
const mapDispatchToProps = dispatch =>
({
    postDeleteUser: (_email, _emailToDelete, _firstname, _lastname, _active, _admin, _pending) => {
        dispatch(actions.postDeleteUser(_email, _emailToDelete, _firstname, _lastname, _active, _admin, _pending))
    },

    //change this to AWS delete user account
    //look at HeaderContainer
    deleteUser: (username, password) => {
        dispatch(reducer.signUp(username, password))
    },

    postUserPermissions: (_email, _emailToUpdate, _permission, _newPermValue) => {
        dispatch(actions.postUserPermissions(_email, _emailToUpdate, _permission, _newPermValue))
    },

    addNewUser: (_firstname, _lastname, _email, _customer, _warehouse) => {
        dispatch(actions.addNewUser(_firstname, _lastname, _email, _customer, _warehouse))
    },


    getAllUsers: (_email, _authToken, _userEmailToSearch, _userIdToSearch, _ModuleID, _WarehouseID, _CustomerID) => {
        dispatch(actions.getAllUsers(_email, _authToken, _userEmailToSearch, _userIdToSearch, _ModuleID, _WarehouseID, _CustomerID))
    },
    postApproveUser: (_email, _authToken, _userIdToApprove, _admin, _accountApproved, _newUserReadWrite, _ModuleID, _WarehouseID, _CustomerID, _userIdList) => {
        dispatch(actions.postApproveUser(_email, _authToken, _userIdToApprove, _admin, _accountApproved, _newUserReadWrite, _ModuleID, _WarehouseID, _CustomerID, _userIdList))
    },


});

class UserManagementContainer extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {

        // this.props.getUserAccounts( this.props.currentUserEmail, this.props.userSignInData.AuthToken );

        this.props.getAllUsers(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, null, null, null, null);
        // this.props.storesUserGet( this.props.currentUserEmail, this.props.userSignInData.AuthToken, null );

    }


    componentDidUpdate(prevProps, prevState) {
        if (prevProps.PostApproveUserSuccessful !== this.props.PostApproveUserSuccessful && this.props.PostApproveUserSuccessful) {
            setTimeout(() => {
                this.props.getAllUsers(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, null, null, null, null);
            }, 1000);
        }
        else {
        }

        if (prevProps.UserInviteNewUserPostSuccessful !== this.props.UserInviteNewUserPostSuccessful && this.props.UserInviteNewUserPostSuccessful) {
            this.props.getAllUsers(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, null, null, null, null);
        }
    }

    render() {
        return (
            <UserManagement
                isUserAccountsLoading={this.props.isUserAccountsLoading}
                userAccountData={this.props.userAccountData}
                isMobile={this.props.isMobile}

                userSignInData={this.props.userSignInData}
                currentUserEmail={this.props.currentUserEmail}


                isAllUsersLoading={this.props.isAllUsersLoading}
                AllUsersData={this.props.AllUsersData}

                postApproveUser={this.props.postApproveUser}

            />
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserManagementContainer);




