import React from 'react';
import { withRouter } from 'react-router-dom';
import { Panel, List, InputGroup, Input, Icon, Loader, Row, Col, ButtonToolbar, Button, Modal, FormGroup, InputPicker } from 'rsuite';
import { formatNumberForAPI, formatStringForAPI, formatStringRemoveSpaces } from '../../../../resources/logic/functions';

import _ from 'lodash';

import PalletDetailsDiv from '../../_Divs_Lot_Pallet/PalletDetailsDiv';
import CaseDetailsDiv from '../../_Divs_Lot_Pallet/CaseDetailsDiv';

const styles = {
    marginBottom: '10px',
    width: '100%'
};

const mountRef = React.createRef();
// const paramStatusID = '1,2';
const paramStatusID = null;

const tableIdCol = "LoadID"

class BarcodeScanning extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            // Table Check Box
            checkedKeys: [],

            currentBarcode: '',
            barcodeInput: '',
            scannedBarcodeType: '',
            barcodeType: '',
            barcodeScannedList: [],
            palletContent: [],
            caseContent: [],
            caseSkus: [],
            skuContent: [],



            // Modal
            showModal: false,
            modalAction: null,
            modalBarcodeInput: '',
            modalMessage: '',

        };

        // Table Check Box
        this.handleCheckAll = this.handleCheckAll.bind(this);
        this.handleCheck = this.handleCheck.bind(this);

        // Table Sort
        this.handleSortColumn = this.handleSortColumn.bind(this);

        //Detect barcode scanning
        this.scannedInput = '';

        //Modal
        this.onClickActionButton = this.onClickActionButton.bind(this);
        this.modalHandleInputSubmit = this.modalHandleInputSubmit.bind(this);
        this.onCloseModal = this.onCloseModal.bind(this);
        this.onConfirmModal = this.onConfirmModal.bind(this);
        this.modalHandleInputChange = this.modalHandleInputChange.bind(this);
        this.getBarcodeData = this.getBarcodeData.bind(this);

    }


    resetState = () => {

        this.setState({
            // Table Check Box
            checkedKeys: [],

            currentBarcode: '',
            barcodeInput: '',
            scannedBarcodeType: '',
            barcodeType: '',
            barcodeScannedList: [],
            palletContent: [],
            caseContent: [],
            caseSkus: [],
            skuContent: [],


            // Modal
            showModal: false,
            modalAction: null,
            modalBarcodeInput: '',
            modalMessage: '',

        });
        this.forceUpdate()
    }

    componentDidMount() {
        window.addEventListener('keypress', this.handleGlobalKeyPress);
        this.loadSortFromStorage()
    }

    componentWillUnmount() {
        window.removeEventListener('keypress', this.handleGlobalKeyPress);
    }

    //Detect barcode scanning
    handleGlobalKeyPress = (e) => {
        // If the modal is shown, return early
        if (this.state.showModal) {

            // Ignore paste events and events when the input is focused
            if (e.ctrlKey || e.metaKey || document.activeElement.id === "modalbarcodeinput") {
                return;
            }

            if (e.key === 'Enter') {
                this.setState({ modalBarcodeInput: this.scannedInput }, () => {
                    this.modalHandleInputSubmit();
                });
                this.scannedInput = '';
            } else {
                this.scannedInput += e.key;
            }
        }

        else {
            // Ignore paste events and events when the input is focused
            if (e.ctrlKey || e.metaKey || document.activeElement.id === "barcodeinput") {
                return;
            }

            if (e.key === 'Enter') {
                this.setState({ barcodeInput: this.scannedInput }, () => {
                    this.handleInputSubmit();
                });
                this.scannedInput = '';
            } else {
                this.scannedInput += e.key;
            }

        }
    };




    componentDidUpdate(prevProps, prevState) {
        // if (this.props.InventoryPalletWarehouseData.length > 0 && !_.isEqual(prevProps.InventoryPalletWarehouseData, this.props.InventoryPalletWarehouseData)) {
        //     this.addPallets();
        // }

        if (this.props.InventoryPalletWarehouseData.length > 0 &&
            (!_.isEqual(prevProps.InventoryPalletWarehouseData, this.props.InventoryPalletWarehouseData) ||
                (this.state.scannedBarcodeType !== prevState.scannedBarcodeType && this.state.scannedBarcodeType === 'pallet'))) {
            this.addPallets();
        }

        if (this.props.InventoryCaseWarehouseData.length > 0 &&
            (!_.isEqual(prevProps.InventoryCaseWarehouseData, this.props.InventoryCaseWarehouseData) ||
                (this.state.scannedBarcodeType !== prevState.scannedBarcodeType && this.state.scannedBarcodeType === 'case'))) {
            this.addCases();
        }


        // if (this.props.InventoryCaseWarehouseData.length > 0 && !_.isEqual(prevProps.InventoryCaseWarehouseData, this.props.InventoryCaseWarehouseData)) {
        //     this.addCases();
        // }
        if (this.props.InventorySkuWarehouseData.length > 0 && !_.isEqual(prevProps.InventorySkuWarehouseData, this.props.InventorySkuWarehouseData)) {
            this.addSkus();
        }



        if (prevProps.InventoryPalletWarehouseLocationPostSuccessful !== this.props.InventoryPalletWarehouseLocationPostSuccessful && this.props.InventoryPalletWarehouseLocationPostSuccessful) {
            this.getBarcodeData(this.props.InventoryPalletWarehouseData[0].PalletBarcode);
            this.onCloseModal()
        }


    }




    // Table Check Box
    handleCheckAll(value, checked, data) {
        const checkedKeys = checked ? [] : data.map(item => item[tableIdCol]);
        this.setState({
            checkedKeys
        });
    }
    handleCheck(value, checked) {
        const { checkedKeys } = this.state;
        const nextCheckedKeys = checked
            ? [...checkedKeys, value]
            : checkedKeys.filter(item => item !== value);

        this.setState({
            checkedKeys: nextCheckedKeys
        });
    }

    // Table Sort
    saveSortTosessionStorage(sortColumn, sortType) {
        try {
            sessionStorage.setItem('sortcolumn', sortColumn)
        }
        catch (e) { }
        try {
            sessionStorage.setItem('sortType', sortType)
        }
        catch (e) { }
        try {
            sessionStorage.setItem('sortURLPathname', this.props.location.pathname)
        }
        catch (e) { }
    }
    loadSortFromStorage() {
        var sortColumn = null
        var sortType = null
        var pathName = null
        try {
            sortColumn = sessionStorage.getItem('sortcolumn')
        }
        catch (e) { }
        try {
            sortType = sessionStorage.getItem('sortType')
        }
        catch (e) { }
        try {
            pathName = sessionStorage.getItem('sortURLPathname')
        }
        catch (e) { }
        if (pathName === this.props.location.pathname) {
            
            this.handleSortColumn(sortColumn, sortType)
        }
    }
    handleSortColumn(sortColumn, sortType) {
        this.setState({
            loading: true
        });

        this.saveSortTosessionStorage(sortColumn, sortType)

        setTimeout(() => {
            this.setState({
                sortColumn,
                sortType,
                loading: false
            });
        }, 100);
    }



    onClickReset = (e) => {
        // if (window.confirm("Are you sure you want to reset form?")) {
        // Remove focus from the Reset button
        if (this.resetButton) {
            this.resetButton.blur();
        }

        this.resetState();
        // }
    }



    //Barcode scanning input
    handleInputChange = (value) => {
        this.setState({ barcodeInput: value });
    };
    isPallet = (barcode) => {
        const parts = barcode.split(' ');
        const thirdPosition = parts.length > 2 ? parts[2].toUpperCase() : null;
        return thirdPosition === 'P';
    };
    isCase = (barcode) => {
        const parts = barcode.split(' ');
        const thirdPosition = parts.length > 2 ? parts[2].toUpperCase() : null;
        return thirdPosition === 'C';
    };
    isWarehouseLocation = (barcode) => {
        const parts = barcode.split(' ');
        const thirdPosition = parts.length > 2 ? parts[1].toUpperCase() : null;
        return thirdPosition === 'L';
    };
    // isWarehouseLocation = (barcode) => {
    //     const parts = barcode.split(' ');
    //     const secondPosition = parts.length > 1 ? parts[1].toUpperCase() : null;
    //     return secondPosition === 'WL';
    // };
    handleInputSubmit(e, inputDisabled) {
        if (inputDisabled === true) {
            window.alert('Please wait before scanning next barcode.')
        }
        else if ((this.props.isInventoryPalletWarehouseLoading || this.props.isInventoryCaseWarehouseLoading || this.props.isInventorySkuWarehouseLoading)) {
            window.alert('Please wait before scanning next barcode.')
        }
        else {
            if (this.state.barcodeInput && (this.state.barcodeInput != this.state.currentBarcode)) {
                var scannedBarcodeType = ''
                var barcodeType = ''
                var newCurrentBarcode = this.state.currentBarcode

                // this.setState({ scannedBarcodeType: '', barcodeType: '' });

                if (this.isPallet(this.state.barcodeInput)) {
                    // this.setState({ scannedBarcodeType: 'pallet' });
                    scannedBarcodeType = 'pallet'
                    newCurrentBarcode = this.state.barcodeInput
                    // this.getPallets(barcode);
                    this.props.inventoryPalletWarehouseLocationGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, this.props.warehouseSelection, this.props.customerSelection, null, null, null, null, formatStringForAPI(this.state.barcodeInput), null, null, null, null, null, null, null, null, null, null, null, null, null, null)
                    // this.props.inventoryCaseWarehouseLocationGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, this.props.warehouseSelection, this.props.customerSelection, null, null, null, null, formatStringForAPI(this.state.barcodeInput), null, null, null, null, null, null, null, null, null, null, null, null, null)
                    // this.props.inventoryLotSkuWarehouseLocationGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, this.props.warehouseSelection, this.props.customerSelection, null, null, null, null, formatStringForAPI(this.state.barcodeInput), null, null, null, null, null, null, null, null, null, null, null, null, null, null, null)
                } else if (this.isCase(this.state.barcodeInput)) {
                    // this.setState({ scannedBarcodeType: 'case' });
                    scannedBarcodeType = 'case'
                    newCurrentBarcode = this.state.barcodeInput
                    // getCases function...
                    this.props.inventoryCaseWarehouseLocationGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, this.props.warehouseSelection, this.props.customerSelection, null, null, null, null, null, null, formatStringForAPI(this.state.barcodeInput), null, null, null, null, null, null, null, null, null, null, null)
                    // this.props.inventoryLotSkuWarehouseLocationGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, this.props.warehouseSelection, this.props.customerSelection, null, null, null, null, null, null, formatStringForAPI(this.state.barcodeInput), null, null, null, null, null, null, null, null, null, null, null, null, null)
                }
                else {
                    // this.setState({ barcodeType: '' });
                    scannedBarcodeType = ''
                    newCurrentBarcode = ''
                    window.alert('Barcode not recognized as pallet or case.');
                }

                this.setState(prevState => ({
                    barcodeScannedList: [...prevState.barcodeScannedList, this.state.barcodeInput],
                    barcodeInput: '',
                    currentBarcode: newCurrentBarcode,
                    scannedBarcodeType: scannedBarcodeType,
                    barcodeType: barcodeType
                }));
            }
        }
    };


    getBarcodeData(barcode) {
        if (this.isPallet(barcode)) {
            this.props.inventoryPalletWarehouseLocationGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, this.props.warehouseSelection, this.props.customerSelection, null, null, null, null, formatStringForAPI(barcode), null, null, null, null, null, null, null, null, null, null, null, null, null, null)
        } else if (this.isCase(barcode)) {
            this.props.inventoryCaseWarehouseLocationGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, this.props.warehouseSelection, this.props.customerSelection, null, null, null, null, null, null, formatStringForAPI(barcode), null, null, null, null, null, null, null, null, null, null, null)
        }
    }






    addPallets() {

        const { InventoryPalletWarehouseData } = this.props;
        const { palletContent } = this.state;

        const newPalletContent = [...palletContent];



        [InventoryPalletWarehouseData].forEach(data => {
            data.forEach(item => {
                if (!newPalletContent.includes(item.PalletBarcode)) {
                    newPalletContent.push(item.PalletBarcode);
                }
            });
        });
        this.setState({ palletContent: newPalletContent });

        if (this.state.scannedBarcodeType == 'pallet') {
            this.setState({ barcodeType: 'pallet' });
        }


        this.forceUpdate()
    }
    addCases() {
        const { InventoryCaseWarehouseData } = this.props;
        const { caseContent } = this.state;
        const { caseSkus } = this.state;


        const newCaseContent = [...caseContent];

        [InventoryCaseWarehouseData].forEach(data => {
            data.forEach(item => {
                if (!newCaseContent.includes(item.CaseBarcode)) {
                    newCaseContent.push(item.CaseBarcode);
                }
            });
        });





        const newCaseSkus = [...caseSkus];

        // const { processedCaseID } = this.state;
        // const newprocessedCaseID = [...processedCaseID];

        const { processedCaseID } = this.state;
        const newprocessedCaseID = new Set(processedCaseID);


        InventoryCaseWarehouseData.forEach(item => {
            const existingCaseSku = newCaseSkus.find(sku => sku.CaseSKU === item.CaseSKU);

            if (!existingCaseSku) {
                newCaseSkus.push({ CaseSKU: item.CaseSKU, quantity: 1 });
                newprocessedCaseID.add(item.CaseID);
            } else if (!newprocessedCaseID.has(item.CaseID)) {
                existingCaseSku.quantity += 1;
                newprocessedCaseID.add(item.CaseID);
            }
        });

        // this.setState({ caseSkus: newCaseSkus });


        this.setState({
            caseContent: newCaseContent,
            caseSkus: newCaseSkus,
            processedCaseID: newprocessedCaseID
        });

        if (this.state.scannedBarcodeType == 'case') {
            this.setState({ barcodeType: 'case' });
        }

        this.forceUpdate()
    }


    addSkus = () => {
        const { InventorySkuWarehouseData } = this.props;

        const { skuContent } = this.state;
        const newSkuContent = [...skuContent];

        // const { processedCaseSkuIDs } = this.state;
        // const newprocessedCaseSkuIDs = [...processedCaseSkuIDs];

        const { processedCaseSkuIDs } = this.state;
        const newprocessedCaseSkuIDs = new Set(processedCaseSkuIDs);


        InventorySkuWarehouseData.forEach(item => {
            const existingItem = newSkuContent.find(sku => sku.SKU === item.SKU);

            if (!existingItem) {
                newSkuContent.push({ SKU: item.SKU, UnitsInWhse: item.UnitsInWhse });
                newprocessedCaseSkuIDs.add(item.CaseSkuID);
            } else if (!newprocessedCaseSkuIDs.has(item.CaseSkuID)) {
                existingItem.UnitsInWhse += item.UnitsInWhse;
                newprocessedCaseSkuIDs.add(item.CaseSkuID);
            }
        });

        this.setState({ skuContent: newSkuContent, processedCaseSkuIDs: newprocessedCaseSkuIDs });
    };



    //Modal
    onClickActionButton(e, action) {
        var modalMessage = ''
        if (action === 'movepallet') {
            modalMessage = 'Scan Location Barcode'
            // this.props.history.push('/inventorymanagement/movepallet');
        }
        else if (action === 'addcase') {
            modalMessage = 'Scan Case Barcode'
            // this.props.history.push('/inventorymanagement/addcase');
        }
        else if (action === 'addCaseToPallet') {
            modalMessage = 'Scan Pallet Barcode'
            // this.props.history.push('/inventorymanagement/addCaseToPallet');
        }
        this.setState({
            showModal: true,
            modalAction: action,
            modalMessage: modalMessage
        });



    }

    modalHandleInputChange = (value) => {
        this.setState({ modalBarcodeInput: value });
    };

    modalHandleInputSubmit(e, modalInputDisabled) {
        if (modalInputDisabled === true) {
            window.alert('Please wait before scanning barcode.')
        }
        else if ((this.props.isInventoryPalletWarehouseLoading || this.props.isInventoryCaseWarehouseLoading || this.props.isInventorySkuWarehouseLoading)) {
            window.alert('Please wait before scanning barcode.')
        }
        else {
            if (this.state.modalBarcodeInput) {


                if (this.state.modalAction === 'movepallet') {
                    if (this.isWarehouseLocation(this.state.modalBarcodeInput)) {

                        // if (this.state.movePalletWarehouseLocationID == null) {
                        //     window.alert('Please select new location.')
                        // }
                        // else 
                        if (formatStringRemoveSpaces(this.state.modalBarcodeInput) === formatStringRemoveSpaces(this.props.InventoryPalletWarehouseData[0].WarehouseLocationBarcode)) {
                            window.alert('Pallet is already in this location.')
                        }
                        else {
                            // This function could be used to handle the saving of data when the modal's save button is clicked
                            // You would typically make an API call here to save your data
                            var palletBarcode = formatStringRemoveSpaces(this.state.modalBarcodeInput)
                            this.props.inventoryPalletWarehouseLocationPost(this.props.currentUserEmail, this.props.userSignInData.AuthToken, formatNumberForAPI(this.props.InventoryPalletWarehouseData[0].WarehouseID), formatNumberForAPI(this.props.InventoryPalletWarehouseData[0].CustomerID), formatNumberForAPI(this.props.InventoryPalletWarehouseData[0].PalletID), null, null, null, null, null, null, formatStringForAPI(palletBarcode))

                            // After saving the data, you can close the modal
                            // this.setState({ showModal: false });
                        }
                    }

                    else {
                        window.alert('Barcode not recognized as warehouse location.');
                    }
                }
                else if (this.state.modalAction === 'addcase') {

                }


                // if (this.isPallet(this.state.modalBarcodeInput)) {




                //     this.props.inventoryPalletWarehouseLocationGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, this.props.warehouseSelection, this.props.customerSelection, null, null, null, null, formatStringForAPI(this.state.modalBarcodeInput), null, null, null, null, null, null, null, null, null, null, null, null, null)

                // } else if (this.isCase(this.state.modalBarcodeInput)) {

                //     // scannedBarcodeType = 'case'
                //     // getCases function...
                //     this.props.inventoryCaseWarehouseLocationGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, this.props.warehouseSelection, this.props.customerSelection, null, null, null, null, null, null, formatStringForAPI(this.state.modalBarcodeInput), null, null, null, null, null, null, null, null, null, null, null)

                // }
                // else {
                //     // scannedBarcodeType = ''
                //     window.alert('Barcode not recognized as pallet or case.');
                // }


            }
        }



    }

    onCloseModal = () => {
        this.setState({ showModal: false });
    }

    onConfirmModal = (barcode) => {
        this.props.inventoryPalletWarehouseLocationPost(barcode);
        this.onCloseModal();
    }



    render() {
        const { checkedKeys, sortColumn, sortType } = this.state;

        let inputDisabled = false;
        if (this.props.isInventoryPalletWarehouseLoading || this.props.isInventoryCaseWarehouseLoading || this.props.isInventorySkuWarehouseLoading) {
            inputDisabled = true;
        }
        else {
            inputDisabled = false;
        }

        let modalInputDisabled = false;
        if (this.props.isInventoryPalletWarehouseLoading || this.props.isInventoryCaseWarehouseLoading || this.props.isInventorySkuWarehouseLoading) {
            modalInputDisabled = true;
        }
        else {
            modalInputDisabled = false;
        }


        return (

            <div style={{ margin: '0 auto' }}>
                <Panel header={<h3>Inventory Management - Barcode Scanning</h3>} bordered style={styles}>

                    <InputGroup style={{ marginBottom: '10px' }}>
                        <InputGroup.Addon>Barcode:</InputGroup.Addon>
                        <Input
                            id="barcodeinput"
                            // innerRef={this.inputRef}
                            value={this.state.barcodeInput}
                            onChange={this.handleInputChange}
                            // onPressEnter={this.handleInputSubmit(inputDisabled)}
                            onPressEnter={(e) => { this.handleInputSubmit(e, inputDisabled) }}
                            // disabled={inputDisabled}
                            autoFocus
                        />
                        <InputGroup.Button onClick={(e) => { this.handleInputSubmit(e, inputDisabled) }} onPressEnter={(e) => { this.handleInputSubmit(e, inputDisabled) }} disabled={inputDisabled}>
                            {inputDisabled ? <Loader size="md" /> : <Icon icon="barcode" />}
                        </InputGroup.Button>
                    </InputGroup>








                    <Row >
                        <Col md={24} sm={24}>
                            <ButtonToolbar >
                                {/* <Button block ref={resetButton => this.resetButton = resetButton?.button} onClick={this.onClickReset} appearance="default">Reset</Button> */}
                                {/* <button className="defaultbutton" ref={resetButton => this.resetButton = resetButton} onClick={this.onClickReset}>Reset</button> */}
                            </ButtonToolbar>
                        </Col>
                    </Row>



                    {this.state.barcodeScannedList.length > 0 && (
                        <div>
                            <br />
                            <h4>Barcodes Scanned</h4>

                            <List>
                                {
                                    this.state.barcodeScannedList.map((barcode, index) => {
                                        const isBarcodeInContent = this.state.palletContent.indexOf(barcode) !== -1 || this.state.caseContent.indexOf(barcode) !== -1; // Supports Internet Explorer
                                        return (
                                            <div key={barcode + index}>
                                                {isBarcodeInContent ? '✅' : '❌'} {barcode}
                                            </div>
                                        )
                                    })
                                }
                            </List>

                        </div>
                    )}


                </Panel>

                {
                    this.state.barcodeType == 'pallet' && (
                        <div>
                            <Panel bordered style={styles}>
                                <PalletDetailsDiv
                                    isInventoryPalletWarehouseLoading={this.props.isInventoryPalletWarehouseLoading}
                                    InventoryPalletWarehouseData={this.props.InventoryPalletWarehouseData}
                                />
                            </Panel>
                            <Panel bordered>
                                <Row style={{ display: 'flex', justifyContent: 'center' }}>
                                    <Col md={24} sm={12} style={{ display: 'flex', justifyContent: 'center' }}>
                                        <Button block appearance="primary" style={{ height: '200px', fontSize: '24px' }} onClick={(e) => { this.onClickActionButton(e, 'movepallet') }}>Move Pallet</Button>
                                    </Col>
                                    {/* <Col md={12} sm={12} style={{ display: 'flex', justifyContent: 'center' }}>
                                        <Button block appearance="primary" style={{ height: '200px', fontSize: '24px' }} onClick={(e) => { this.onClickActionButton(e, 'addcase') }}>Add Case</Button>
                                    </Col> */}
                                </Row>
                            </Panel>
                        </div>
                    )

                }

                {
                    this.state.barcodeType == 'case' && (
                        <div>
                            <Panel bordered style={styles}>
                                <CaseDetailsDiv
                                    isInventoryCaseWarehouseLoading={this.props.isInventoryCaseWarehouseLoading}
                                    InventoryCaseWarehouseData={this.props.InventoryCaseWarehouseData}
                                />
                            </Panel>
                            {/* <Panel bordered>
                                <Row style={{ display: 'flex', justifyContent: 'center' }}>
                                    <Col md={24} sm={24} style={{ display: 'flex', justifyContent: 'center' }}>
                                        <Button block appearance="primary" style={{ height: '200px', fontSize: '24px' }} onClick={(e) => { this.onClickActionButton(e, 'addCaseToPallet') }}>Add to Pallet</Button>
                                    </Col>
                                    <Col md={12} sm={12} style={{ display: 'flex', justifyContent: 'center' }}>
                                        <Button block appearance="primary" style={{  height: '200px', fontSize: '24px' }}>Add Case</Button>
                                    </Col>
                                </Row>
                            </Panel> */}
                        </div>
                    )

                }









                <Modal backdrop={'static'} show={this.state.showModal} onHide={this.onCloseModal}>
                    <Modal.Header>
                        <Modal.Title>{this.state.modalMessage}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {/* Replace this with your barcode scanner input */}
                        {/* <input type="text" onBlur={(e) => this.onConfirmModal(e.target.value)} /> */}


                        <InputGroup style={{ marginBottom: '10px' }}>
                            <InputGroup.Addon>Barcode:</InputGroup.Addon>
                            <Input
                                id="modalbarcodeinput"
                                // innerRef={this.inputRef}
                                value={this.state.modalBarcodeInput}
                                onChange={this.modalHandleInputChange}
                                // onPressEnter={this.handleInputSubmit(inputDisabled)}
                                onPressEnter={(e) => { this.modalHandleInputSubmit(e, modalInputDisabled) }}
                                // disabled={inputDisabled}
                                autoFocus
                            />
                            <InputGroup.Button onClick={(e) => { this.modalHandleInputSubmit(e, modalInputDisabled) }} onPressEnter={(e) => { this.modalHandleInputSubmit(e, modalInputDisabled) }} disabled={modalInputDisabled}>
                                {modalInputDisabled ? <Loader size="md" /> : <Icon icon="barcode" />}
                            </InputGroup.Button>
                        </InputGroup>




                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.onCloseModal}>Cancel</Button>
                    </Modal.Footer>
                </Modal>





            </div>

        );
    }
}

export default withRouter(BarcodeScanning);

