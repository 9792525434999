import { connect } from 'react-redux';
import React, { Component } from 'react';
import Load from './Load'
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import * as actions from '../../../redux/actions/vasActions';
import { queryStringParse, formatStringForAPI } from '../../../resources/logic/functions';

const mapStateToProps = store =>
({

    currentUserEmail: store.userServicesReducer.currentUserEmail,
    userSignInData: store.userServicesReducer.userSignInData,

    isLoadLoading: store.vasReducer.isLoadLoading,
    LoadData: store.vasReducer.LoadData,

    isLoadHawbLoading: store.vasReducer.isLoadHawbLoading,
    LoadHawbData: store.vasReducer.LoadHawbData,

    PostLoadSuccessful: store.vasReducer.PostLoadSuccessful,


});

const mapDispatchToProps = dispatch =>
({

    loadGet: (_email, _authToken, _LoadID, _HouseID, _MasterID, _PickupDateStart, _PickupDateEnd, _DeliveryDateStart, _DeliveryDateEnd, _LoadStatusID, _Carrier, _BOL, _Trailer, _Seal, _ConsigneeID, _DestinationID, _WarehouseID, _CustomerID) => {
        dispatch(actions.loadGet(_email, _authToken, _LoadID, _HouseID, _MasterID, _PickupDateStart, _PickupDateEnd, _DeliveryDateStart, _DeliveryDateEnd, _LoadStatusID, _Carrier, _BOL, _Trailer, _Seal, _ConsigneeID, _DestinationID, _WarehouseID, _CustomerID))
    },
    loadHawbGet: (_email, _authToken, _LoadID, _HouseID, _MasterID, _WarehouseID, _CustomerID) => {
        dispatch(actions.loadHawbGet(_email, _authToken, _LoadID, _HouseID, _MasterID, _WarehouseID, _CustomerID))
    },
    loadPost: (_email, _authToken, _LoadID, _PickupDate, _DeliveryDate, _Carrier, _BOL, _Trailer, _Seal, _TotalPCS, _TotalPallets, _CurrentLoadStatusID, _LoadStatusID, _LoadHawbData, _HouseID, _PCS, _Pallets, _WarehouseID, _CustomerID) => {
        dispatch(actions.loadPost(_email, _authToken, _LoadID, _PickupDate, _DeliveryDate, _Carrier, _BOL, _Trailer, _Seal, _TotalPCS, _TotalPallets, _CurrentLoadStatusID, _LoadStatusID, _LoadHawbData, _HouseID, _PCS, _Pallets, _WarehouseID, _CustomerID))
    },


});

class LoadContainer extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.handleUrlParam();
        // this.props.userWarehousesGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null)
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.warehouseSelection !== this.props.warehouseSelection) {
            this.handleUrlParam();
        }
        if (prevProps.customerSelection !== this.props.customerSelection) {
            this.handleUrlParam();
        }
        
        if (prevProps.location.search !== this.props.location.search) {
            this.handleUrlParam();
        }

        if (prevProps.PostLoadSuccessful !== this.props.PostLoadSuccessful && this.props.PostLoadSuccessful) {
            setTimeout(() => {
                this.handleUrlParam()
            }, 1000);
        }
        else {
        }


    }



    handleUrlParam = () => {
        const values = queryString.parse(this.props.location.search)



        try {
            var paramLoadStatus = queryStringParse(values.loadstatus)
        }
        catch (e) {
            var paramLoadStatus = null
        }
        // try {
        //     var paramWarehouseID = queryStringParse(values.warehouseid)
        // }
        // catch (e) {
        //     var paramWarehouseID = null
        // }


        // try{
        //     var paramPickerMain = queryStringParse( values.filter )
        // }
        // catch(e){
        //     var paramPickerMain = null
        // }

        // this.props.ordersGet( this.props.currentUserEmail, this.props.userSignInData.AuthToken, paramorderid, null, paramordernumber, paramPickerSeller, null, paramPickerStatus, paramPickerService, null, paramPickerCountry, paramPickerProduct, null );

        this.props.loadGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, null, null, null, null, null, null, paramLoadStatus, null, null, null, null, null, null, this.props.warehouseSelection, this.props.customerSelection)
    }



    render() {

        return (

            <Load
                currentUserEmail={this.props.currentUserEmail}
                userSignInData={this.props.userSignInData}
                isMobile={this.props.isMobile}
                warehouseSelection={this.props.warehouseSelection}
                customerSelection={this.props.customerSelection}
                
                isLoadLoading={this.props.isLoadLoading}
                LoadData={this.props.LoadData}

                loadHawbGet={this.props.loadHawbGet}

                isLoadHawbLoading={this.props.isLoadHawbLoading}
                LoadHawbData={this.props.LoadHawbData}
                loadPost={this.props.loadPost}

                PostLoadSuccessful={this.props.PostLoadSuccessful}

            />


        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(LoadContainer));



