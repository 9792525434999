import { connect } from 'react-redux';
import React, { Component } from 'react';
import OrderPicking from './OrderPicking'
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import * as userActions from '../../../../redux/actions/actionsUser';
import { queryStringParse, formatDateForAPI, formatStringForAPI } from '../../../../resources/logic/functions';
import * as inventoryActions from '../../../../redux/actions/inventoryActions'


const mapStateToProps = store =>
({

    currentUserEmail: store.userServicesReducer.currentUserEmail,
    userSignInData: store.userServicesReducer.userSignInData,

    isWarehouseLocationsLoading: store.userServicesReducer.isWarehouseLocationsLoading,
    WarehouseLocationsData: store.userServicesReducer.WarehouseLocationsData,

    isInventoryCustomerRefLoading: store.inventoryReducer.isInventoryCustomerRefLoading,
    InventoryCustomerRefData: store.inventoryReducer.InventoryCustomerRefData,

    isInventoryProjectLoading: store.inventoryReducer.isInventoryProjectLoading,
    InventoryProjectData: store.inventoryReducer.InventoryProjectData,


    isInventoryLotOrderOutboundLoading: store.inventoryReducer.isInventoryLotOrderOutboundLoading,
    InventoryLotOrderOutboundPostSuccessful: store.inventoryReducer.InventoryLotOrderOutboundPostSuccessful,
    NewOutboundOrderId: store.inventoryReducer.NewOutboundOrderId,

    isInventoryLotOrderOutboundSkuLoading: store.inventoryReducer.isInventoryLotOrderOutboundSkuLoading,
    InventoryLotOrderOutboundSkuPostSuccessful: store.inventoryReducer.InventoryLotOrderOutboundSkuPostSuccessful,


    isInventoryLotOrderOutboundGetLoading: store.inventoryReducer.isInventoryLotOrderOutboundGetLoading,
    InventoryLotOrderOutboundData: store.inventoryReducer.InventoryLotOrderOutboundData,

    isInventorySkuRefLoading: store.inventoryReducer.isInventorySkuRefLoading,
    InventorySkuRefData: store.inventoryReducer.InventorySkuRefData,


    isInventoryLotOrderOutboundSkuGetLoading: store.inventoryReducer.isInventoryLotOrderOutboundSkuGetLoading,
    InventoryLotOrderOutboundSkuData: store.inventoryReducer.InventoryLotOrderOutboundSkuData,


    isInventoryLotOrderOutboundSkuEachLocationsGetLoading: store.inventoryReducer.isInventoryLotOrderOutboundSkuEachLocationsGetLoading,
    InventoryLotOrderOutboundSkuEachLocationsData: store.inventoryReducer.InventoryLotOrderOutboundSkuEachLocationsData,

    isInventoryLotOrderOutboundSkuCaseLocationsGetLoading: store.inventoryReducer.isInventoryLotOrderOutboundSkuCaseLocationsGetLoading,
    InventoryLotOrderOutboundSkuCaseLocationsData: store.inventoryReducer.InventoryLotOrderOutboundSkuCaseLocationsData,


    isInventoryLotBarcodeScanValidateLoading: store.inventoryReducer.isInventoryLotBarcodeScanValidateLoading,
    InventoryLotBarcodeScanValidateData: store.inventoryReducer.InventoryLotBarcodeScanValidateData,


    isInventoryLotPalletSkuUnitsGetLoading: store.inventoryReducer.isInventoryLotPalletSkuUnitsGetLoading,
    InventoryLotPalletSkuUnitsData: store.inventoryReducer.InventoryLotPalletSkuUnitsData,

    isInventoryLotPalletSkuCasesGetLoading: store.inventoryReducer.isInventoryLotPalletSkuCasesGetLoading,
    InventoryLotPalletSkuCasesData: store.inventoryReducer.InventoryLotPalletSkuCasesData,

    isInventoryLotCaseSkuUnitsGetLoading: store.inventoryReducer.isInventoryLotCaseSkuUnitsGetLoading,
    InventoryLotCaseSkuUnitsData: store.inventoryReducer.InventoryLotCaseSkuUnitsData,


    isInventoryLoadLotLoading: store.inventoryReducer.isInventoryLoadLotLoading,
    InventoryLoadLotData: store.inventoryReducer.InventoryLoadLotData,


    isInventoryLoadPalletCaseUnitLoading: store.inventoryReducer.isInventoryLoadPalletCaseUnitLoading,
    InventoryLoadPalletCaseUnitData: store.inventoryReducer.InventoryLoadPalletCaseUnitData,


    isInventoryDestinationLoading: store.inventoryReducer.isInventoryDestinationLoading,
    InventoryDestinationData: store.inventoryReducer.InventoryDestinationData,

    InventoryLoadPalletCaseSkuPostSuccessful: store.inventoryReducer.InventoryLoadPalletCaseSkuPostSuccessful,
    InventoryLoadPalletCaseSkuPostNewLoadID: store.inventoryReducer.InventoryLoadPalletCaseSkuPostNewLoadID,

    InventoryLoadRemovePalletCaseSkuPostSuccessful: store.inventoryReducer.InventoryLoadRemovePalletCaseSkuPostSuccessful,


    isInventoryLotOrderOutboundSkuPalletLocationsGetLoading: store.inventoryReducer.isInventoryLotOrderOutboundSkuPalletLocationsGetLoading,
    InventoryLotOrderOutboundSkuPalletLocationsData: store.inventoryReducer.InventoryLotOrderOutboundSkuPalletLocationsData,

});

const mapDispatchToProps = dispatch =>
({

    userWarehouseLocationsGet: (_email, _authToken, _WarehouseID, _CustomerID) => {
        dispatch(userActions.userWarehouseLocationsGet(_email, _authToken, _WarehouseID, _CustomerID))
    },


    inventoryCustomerRefGet: (_email, _authToken, _WarehouseID, _CustomerID) => {
        dispatch(inventoryActions.inventoryCustomerRefGet(_email, _authToken, _WarehouseID, _CustomerID))
    },


    inventoryProjectGet: (_email, _authToken, _WarehouseID, _CustomerID) => {
        dispatch(inventoryActions.inventoryProjectGet(_email, _authToken, _WarehouseID, _CustomerID))
    },

    inventoryDestinationGet: (_email, _authToken, _WarehouseID, _CustomerID) => {
        dispatch(inventoryActions.inventoryDestinationGet(_email, _authToken, _WarehouseID, _CustomerID))
    },

    inventoryLotOrderOutboundPost: (_email, _authToken, _OrderOutboundID, _OrderRef, _WarehouseID, _CustomerID, _OrderStatusID, _OrderTypeID, _OrderDate, _ShipByDate, _DeliverByDate, _ShipToName, _ShipToAddressLine1, _ShipToAddressLine2, _ShipToCity, _ShipToState, _ShipToZip, _ShipToCountry, _OrderPriorityID, _Notes, _Delete) => {
        dispatch(inventoryActions.inventoryLotOrderOutboundPost(_email, _authToken, _OrderOutboundID, _OrderRef, _WarehouseID, _CustomerID, _OrderStatusID, _OrderTypeID, _OrderDate, _ShipByDate, _DeliverByDate, _ShipToName, _ShipToAddressLine1, _ShipToAddressLine2, _ShipToCity, _ShipToState, _ShipToZip, _ShipToCountry, _OrderPriorityID, _Notes, _Delete))
    },

    inventoryLotOrderOutboundSkuPost: (_email, _authToken, _WarehouseID, _CustomerID, _OrderOutboundID, _OrderOutboundSkuID, _SkuID, _SKU, _UomID, _Qty, _LotCode, _SerialNumber, _CustomerRefID, _CustomerRef, _ProjectID, _Project, _Notes, _Delete) => {
        dispatch(inventoryActions.inventoryLotOrderOutboundSkuPost(_email, _authToken, _WarehouseID, _CustomerID, _OrderOutboundID, _OrderOutboundSkuID, _SkuID, _SKU, _UomID, _Qty, _LotCode, _SerialNumber, _CustomerRefID, _CustomerRef, _ProjectID, _Project, _Notes, _Delete))
    },

    inventoryLotOrderOutboundGet: (_email, _authToken, _WarehouseID, _CustomerID, _OrderOutboundID, _OrderRef, _OrderStatusID, _SkuID, _SKU, _WarehouseLocationID) => {
        dispatch(inventoryActions.inventoryLotOrderOutboundGet(_email, _authToken, _WarehouseID, _CustomerID, _OrderOutboundID, _OrderRef, _OrderStatusID, _SkuID, _SKU, _WarehouseLocationID))
    },

    inventoryLotOrderOutboundSkuGet: (_email, _authToken, _WarehouseID, _CustomerID, _OrderOutboundID, _OrderRef, _OrderStatusID, _SkuID, _SKU, _WarehouseLocationID) => {
        dispatch(inventoryActions.inventoryLotOrderOutboundSkuGet(_email, _authToken, _WarehouseID, _CustomerID, _OrderOutboundID, _OrderRef, _OrderStatusID, _SkuID, _SKU, _WarehouseLocationID))
    },

    inventorySkuRefGet: (_email, _authToken, _WarehouseID, _CustomerID, _SkuID, _SKU, _SkuStatusID, _ProjectID, _SkuLotCode, _SkuSerialNumber) => {
        dispatch(inventoryActions.inventorySkuRefGet(_email, _authToken, _WarehouseID, _CustomerID, _SkuID, _SKU, _SkuStatusID, _ProjectID, _SkuLotCode, _SkuSerialNumber))
    },

    inventoryLotOrderOutboundSkuEachLocationGet: (_email, _authToken, _WarehouseID, _CustomerID, _OrderOutboundID, _OrderRef, _OrderStatusID, _SkuID, _SKU, _WarehouseLocationID, _UomID) => {
        dispatch(inventoryActions.inventoryLotOrderOutboundSkuEachLocationGet(_email, _authToken, _WarehouseID, _CustomerID, _OrderOutboundID, _OrderRef, _OrderStatusID, _SkuID, _SKU, _WarehouseLocationID, _UomID))
    },
    inventoryLotOrderOutboundSkuCaseLocationGet: (_email, _authToken, _WarehouseID, _CustomerID, _OrderOutboundID, _OrderRef, _OrderStatusID, _SkuID, _SKU, _WarehouseLocationID, _UomID) => {
        dispatch(inventoryActions.inventoryLotOrderOutboundSkuCaseLocationGet(_email, _authToken, _WarehouseID, _CustomerID, _OrderOutboundID, _OrderRef, _OrderStatusID, _SkuID, _SKU, _WarehouseLocationID, _UomID))
    },

    inventoryLotBarcodeScanValidateGet: (_email, _authToken, _WarehouseID, _CustomerID, _Barcode) => {
        dispatch(inventoryActions.inventoryLotBarcodeScanValidateGet(_email, _authToken, _WarehouseID, _CustomerID, _Barcode))
    },

    inventoryLotPalletSkuUnitsGet: (_email, _authToken, _ContainerID, _PalletID) => {
        dispatch(inventoryActions.inventoryLotPalletSkuUnitsGet(_email, _authToken, _ContainerID, _PalletID))
    },

    inventoryLotPalletCaseUnitsGet: (_email, _authToken, _ContainerID, _PalletID) => {
        dispatch(inventoryActions.inventoryLotPalletCaseUnitsGet(_email, _authToken, _ContainerID, _PalletID))
    },

    inventoryLotCaseSkuUnitsGet: (_email, _authToken, _CaseID, _CaseBarcode) => {
        dispatch(inventoryActions.inventoryLotCaseSkuUnitsGet(_email, _authToken, _CaseID, _CaseBarcode))
    },


    inventoryLoadLotGet: (_email, _authToken, _WarehouseID, _CustomerID, _LoadID, _LoadStatusID, _ContainerID, _Container, _PalletID, _PalletBarcode, _CaseID, _CaseBarcode, _CaseSkuID, _CaseSku, _CaseLotCode, _CaseSerialNumber, _SkuID, _SKU, _SkuLotCode, _SkuSerialNumber, _CustomerRefID, _PickupDateStart, _PickupDateEnd, _DeliveryDateStart, _DeliveryDateEnd, _Carrier, _BOL, _Trailer, _Seal, _DestinationID, _OrderOutboundID) => {
        dispatch(inventoryActions.inventoryLoadLotGet(_email, _authToken, _WarehouseID, _CustomerID, _LoadID, _LoadStatusID, _ContainerID, _Container, _PalletID, _PalletBarcode, _CaseID, _CaseBarcode, _CaseSkuID, _CaseSku, _CaseLotCode, _CaseSerialNumber, _SkuID, _SKU, _SkuLotCode, _SkuSerialNumber, _CustomerRefID, _PickupDateStart, _PickupDateEnd, _DeliveryDateStart, _DeliveryDateEnd, _Carrier, _BOL, _Trailer, _Seal, _DestinationID, _OrderOutboundID))
    },

    inventoryLoadPalletCaseUnitGet: (_email, _authToken, _WarehouseID, _CustomerID, _LoadID, _PalletID, _PalletBarcode, _CaseID, _CaseBarcode, _SkuID, _CaseSkuID) => {
        dispatch(inventoryActions.inventoryLoadPalletCaseUnitGet(_email, _authToken, _WarehouseID, _CustomerID, _LoadID, _PalletID, _PalletBarcode, _CaseID, _CaseBarcode, _SkuID, _CaseSkuID))
    },


    inventoryLoadPalletCaseSkuPost: (_email, _authToken, _WarehouseID, _CustomerID, _DataJson, _DataType, _LoadID, _PickupDate, _DeliveryDate, _Carrier, _BOL, _Trailer, _Seal, _TotalUnits, _TotalCases, _TotalPallets, _Destination, _DestinationID, _LoadStatusID, _OrderOutboundID) => {
        dispatch(inventoryActions.inventoryLoadPalletCaseSkuPost(_email, _authToken, _WarehouseID, _CustomerID, _DataJson, _DataType, _LoadID, _PickupDate, _DeliveryDate, _Carrier, _BOL, _Trailer, _Seal, _TotalUnits, _TotalCases, _TotalPallets, _Destination, _DestinationID, _LoadStatusID, _OrderOutboundID))
    },

    inventoryLoadRemovePalletCaseSkuPost: (_email, _authToken, _WarehouseID, _CustomerID, _LoadID, _PalletID, _PalletBarcode, _CaseID, _CaseBarcode, _NewPalletID, _CaseSkuID, _UnitsToRemove, _NewCaseID, _DeleteLoad,) => {
        dispatch(inventoryActions.inventoryLoadRemovePalletCaseSkuPost(_email, _authToken, _WarehouseID, _CustomerID, _LoadID, _PalletID, _PalletBarcode, _CaseID, _CaseBarcode, _NewPalletID, _CaseSkuID, _UnitsToRemove, _NewCaseID, _DeleteLoad,))
    },


    inventoryLotOrderOutboundSkuPalletLocationsGet: (_email, _authToken, _WarehouseID, _CustomerID, _OrderOutboundID, _OrderRef, _PalletID, _PalletBarcode) => {
        dispatch(inventoryActions.inventoryLotOrderOutboundSkuPalletLocationsGet(_email, _authToken, _WarehouseID, _CustomerID, _OrderOutboundID, _OrderRef, _PalletID, _PalletBarcode))
    },





});

class OrderPickingContainer extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.inventoryCustomerRefGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection)
        this.props.inventoryProjectGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection)
        this.handleUrlParam();
        // this.props.userWarehousesGet( this.props.currentUserEmail, this.props.userSignInData.AuthToken, null )
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.warehouseSelection !== this.props.warehouseSelection) {
            this.handleUrlParam();
        }
        if (prevProps.customerSelection !== this.props.customerSelection) {
            this.handleUrlParam();
        }

        // if (prevProps.InventorySkuWarehouseLocationPostSuccessful !== this.props.InventorySkuWarehouseLocationPostSuccessful && this.props.InventorySkuWarehouseLocationPostSuccessful) {
        //     this.handleUrlParam();
        // }

        if (prevProps.location.search !== this.props.location.search) {
            this.handleUrlParam();
        }

        if (prevProps.InventoryLoadPalletCaseSkuPostSuccessful !== this.props.InventoryLoadPalletCaseSkuPostSuccessful && this.props.InventoryLoadPalletCaseSkuPostSuccessful) {
            this.handleUrlParam()
        }

        if (prevProps.InventoryLoadRemovePalletCaseSkuPostSuccessful !== this.props.InventoryLoadRemovePalletCaseSkuPostSuccessful && this.props.InventoryLoadRemovePalletCaseSkuPostSuccessful) {
            this.handleUrlParam()
        }

    }



    handleUrlParam = () => {

        const values = queryString.parse(this.props.location.search)

        try {
            var paramOrderID = queryStringParse(values.orderid)
        }
        catch (e) {
            var paramOrderID = null
        }

        try {
            var paramLoadID = queryStringParse(values.loadid)
        }
        catch (e) {
            var paramLoadID = null
        }

        this.props.inventoryDestinationGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection);


        this.props.inventoryLotOrderOutboundGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, null, paramOrderID, null, null, null, null, null)
        this.props.inventoryLotOrderOutboundSkuGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, null, paramOrderID, null, null, null, null, null)

        this.props.inventoryLotOrderOutboundSkuEachLocationGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, null, paramOrderID, null, null, null, null, null, null)
        this.props.inventoryLotOrderOutboundSkuCaseLocationGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, null, paramOrderID, null, null, null, null, null, null)

        this.props.inventoryLotOrderOutboundSkuPalletLocationsGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, null, paramOrderID, null, null, null)

        this.props.inventoryLoadLotGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection, paramLoadID, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, paramOrderID)

        if (paramLoadID !== null) {
            this.props.inventoryLoadPalletCaseUnitGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, null, paramLoadID, null, null, null, null, null, null)

        }
        else {
            this.props.inventoryLoadPalletCaseUnitGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, null, -100, null, null, null, null, null, null)
        }

    }



    render() {

        return (

            <OrderPicking
                currentUserEmail={this.props.currentUserEmail}
                userSignInData={this.props.userSignInData}
                isMobile={this.props.isMobile}
                warehouseSelection={this.props.warehouseSelection}
                customerSelection={this.props.customerSelection}

                inventoryCustomerRefGet={this.props.inventoryCustomerRefGet}
                isInventoryCustomerRefLoading={this.props.isInventoryCustomerRefLoading}
                InventoryCustomerRefData={this.props.InventoryCustomerRefData}

                inventoryProjectGet={this.props.inventoryProjectGet}
                isInventoryProjectLoading={this.props.isInventoryProjectLoading}
                InventoryProjectData={this.props.InventoryProjectData}


                inventoryLotOrderOutboundPost={this.props.inventoryLotOrderOutboundPost}
                isInventoryLotOrderOutboundLoading={this.props.isInventoryLotOrderOutboundLoading}
                InventoryLotOrderOutboundPostSuccessful={this.props.InventoryLotOrderOutboundPostSuccessful}
                NewOutboundOrderId={this.props.NewOutboundOrderId}

                inventoryLotOrderOutboundGet={this.props.inventoryLotOrderOutboundGet}
                isInventoryLotOrderOutboundGetLoading={this.props.isInventoryLotOrderOutboundGetLoading}
                InventoryLotOrderOutboundData={this.props.InventoryLotOrderOutboundData}


                inventorySkuRefGet={this.props.inventorySkuRefGet}
                isInventorySkuRefLoading={this.props.isInventorySkuRefLoading}
                InventorySkuRefData={this.props.InventorySkuRefData}


                inventoryLotOrderOutboundSkuPost={this.props.inventoryLotOrderOutboundSkuPost}
                isInventoryLotOrderOutboundSkuLoading={this.props.isInventoryLotOrderOutboundSkuLoading}
                InventoryLotOrderOutboundSkuPostSuccessful={this.props.InventoryLotOrderOutboundSkuPostSuccessful}

                inventoryLotOrderOutboundSkuGet={this.props.inventoryLotOrderOutboundSkuGet}
                isInventoryLotOrderOutboundSkuGetLoading={this.props.isInventoryLotOrderOutboundSkuGetLoading}
                InventoryLotOrderOutboundSkuData={this.props.InventoryLotOrderOutboundSkuData}



                inventoryLotOrderOutboundSkuEachLocationGet={this.props.inventoryLotOrderOutboundSkuEachLocationGet}
                isInventoryLotOrderOutboundSkuEachLocationsGetLoading={this.props.isInventoryLotOrderOutboundSkuEachLocationsGetLoading}
                InventoryLotOrderOutboundSkuEachLocationsData={this.props.InventoryLotOrderOutboundSkuEachLocationsData}

                inventoryLotOrderOutboundSkuCaseLocationGet={this.props.inventoryLotOrderOutboundSkuCaseLocationGet}
                isInventoryLotOrderOutboundSkuCaseLocationsGetLoading={this.props.isInventoryLotOrderOutboundSkuCaseLocationsGetLoading}
                InventoryLotOrderOutboundSkuCaseLocationsData={this.props.InventoryLotOrderOutboundSkuCaseLocationsData}

                inventoryLotBarcodeScanValidateGet={this.props.inventoryLotBarcodeScanValidateGet}
                isInventoryLotBarcodeScanValidateLoading={this.props.isInventoryLotBarcodeScanValidateLoading}
                InventoryLotBarcodeScanValidateData={this.props.InventoryLotBarcodeScanValidateData}

                inventoryLotPalletSkuUnitsGet={this.props.inventoryLotPalletSkuUnitsGet}
                isInventoryLotPalletSkuUnitsGetLoading={this.props.isInventoryLotPalletSkuUnitsGetLoading}
                InventoryLotPalletSkuUnitsData={this.props.InventoryLotPalletSkuUnitsData}

                inventoryLotPalletCaseUnitsGet={this.props.inventoryLotPalletCaseUnitsGet}
                isInventoryLotPalletSkuCasesGetLoading={this.props.isInventoryLotPalletSkuCasesGetLoading}
                InventoryLotPalletSkuCasesData={this.props.InventoryLotPalletSkuCasesData}

                inventoryLotCaseSkuUnitsGet={this.props.inventoryLotCaseSkuUnitsGet}
                isInventoryLotCaseSkuUnitsGetLoading={this.props.isInventoryLotCaseSkuUnitsGetLoading}
                InventoryLotCaseSkuUnitsData={this.props.InventoryLotCaseSkuUnitsData}


                inventoryLoadLotGet={this.props.inventoryLoadLotGet}
                isInventoryLoadLotLoading={this.props.isInventoryLoadLotLoading}
                InventoryLoadLotData={this.props.InventoryLoadLotData}


                inventoryLoadPalletCaseUnitGet={this.props.inventoryLoadPalletCaseUnitGet}
                isInventoryLoadPalletCaseUnitLoading={this.props.isInventoryLoadPalletCaseUnitLoading}
                InventoryLoadPalletCaseUnitData={this.props.InventoryLoadPalletCaseUnitData}

                isInventoryDestinationLoading={this.props.isInventoryDestinationLoading}
                InventoryDestinationData={this.props.InventoryDestinationData}

                InventoryLoadPalletCaseSkuPostSuccessful={this.props.InventoryLoadPalletCaseSkuPostSuccessful}
                InventoryLoadPalletCaseSkuPostNewLoadID={this.props.InventoryLoadPalletCaseSkuPostNewLoadID}
                inventoryLoadPalletCaseSkuPost={this.props.inventoryLoadPalletCaseSkuPost}

                InventoryLoadRemovePalletCaseSkuPostSuccessful={this.props.InventoryLoadRemovePalletCaseSkuPostSuccessful}
                inventoryLoadRemovePalletCaseSkuPost={this.props.inventoryLoadRemovePalletCaseSkuPost}



                isInventoryLotOrderOutboundSkuPalletLocationsGetLoading={this.props.isInventoryLotOrderOutboundSkuPalletLocationsGetLoading}
                InventoryLotOrderOutboundSkuPalletLocationsData={this.props.InventoryLotOrderOutboundSkuPalletLocationsData}



            />

        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(OrderPickingContainer));



