//TODO make dynamic

////npm imports
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import React, {
  PureComponent
} from 'react';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { withRouter } from 'react-router-dom';
import { Col, InputPicker, Loader, Panel, Row } from 'rsuite';
import validator from 'validator';
import { formatJsonStringForAPI, formatNumberForAPI } from '../../../resources/logic/functions';
import RButtonPrimary from '../../componentsGeneral/Rsuite/Button/RButtonPrimary';
import RTable from '../../componentsGeneral/Rsuite/Table/RTable';
import RToggle from '../../componentsGeneral/Rsuite/Toggle/RToggle';
import UserAccountsDataElements from '../../dataComponentsUser/UserAccountsDataElements';
import InviteNewUser from '../InviteNewUser/InviteNewUser';

const tableIdCol = "UserID"

const required = value => (value ? undefined : 'Required')
const email = value =>
  validator.isEmail(value) ? undefined : 'Not Valid Email'

const waitFor = delay => new Promise(resolve => setTimeout(resolve, delay));


class UserManagement extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      //Table Check Box
      checkedKeys: [],

      toggleAdmin: false,

      toggleReadWrite: false,

      warehouseArray: {},

      selectedWarehouse: null,
      selectedModule: null,
      selectedCustomer: null

    }
    //Table Check Box
    this.handleCheckAll = this.handleCheckAll.bind(this);
    this.handleCheck = this.handleCheck.bind(this);

    //Table Sort
    this.handleSortColumn = this.handleSortColumn.bind(this);

    this.onClickApproveAccount = this.onClickApproveAccount.bind(this);
    this.onClickRejectAccount = this.onClickRejectAccount.bind(this);

    this.onChangeToggleAdmin = this.onChangeToggleAdmin.bind(this);
    this.onChangeToggleReadWrite = this.onChangeToggleReadWrite.bind(this);


    this.onClickDetails = this.onClickDetails.bind(this);


    this.onChangeWarehouseSelection = this.onChangeWarehouseSelection.bind(this);
    this.onChangeModuleSelection = this.onChangeModuleSelection.bind(this);
    this.onChangeCustomerSelection = this.onChangeCustomerSelection.bind(this);
  }


  componentDidMount() {
    this.getsessionStorageWarehouse() //this will preserve menu selection
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.warehouseSelection !== this.props.warehouseSelection) {
      this.setState({
        checkedKeys: []
      });
    }
    if (prevProps.customerSelection !== this.props.customerSelection) {
      this.setState({
        checkedKeys: []
      });
    }
  }

  getsessionStorageWarehouse() {

    try {
      let warehouseMenuList = JSON.parse(sessionStorage.getItem('AppConfig.WarehouseArray'));
      if (warehouseMenuList != null && warehouseMenuList != undefined) {
        warehouseMenuList = warehouseMenuList.filter(x => x.Admin === true)
        this.setState({
          warehouseArray: warehouseMenuList
        })
      }
    }
    catch (e) { }


    try {
      let moduleMenuList = JSON.parse(sessionStorage.getItem('AppConfig.ModuleArray'));
      if (moduleMenuList != null && moduleMenuList != undefined) {
        moduleMenuList = moduleMenuList.filter(x => x.Admin === true)
        this.setState({
          moduleArray: moduleMenuList
        })
      }
    }
    catch (e) { }


    try {
      let customerMenuList = JSON.parse(sessionStorage.getItem('AppConfig.CustomerArray'));
      if (customerMenuList != null && customerMenuList != undefined) {
        customerMenuList = customerMenuList.filter(x => x.Admin === true)
        this.setState({
          customerArray: customerMenuList
        })
      }
    }
    catch (e) { }

  }

  onChangeWarehouseSelection(value) {
    this.setState({
      selectedWarehouse: value
    });
  }

  onChangeModuleSelection(value) {
    this.setState({
      selectedModule: value
    });
  }

  onChangeCustomerSelection(value) {
    this.setState({
      selectedCustomer: value
    });
  }




  //Table Check Box
  handleCheckAll(value, checked, data) {
    const checkedKeys = checked ? [] : data.map(item => item[tableIdCol]);
    this.setState({
      checkedKeys
    });
  }
  handleCheck(value, checked) {
    const { checkedKeys } = this.state;
    const nextCheckedKeys = checked
      ? [...checkedKeys, value]
      : checkedKeys.filter(item => item !== value);
    this.setState({
      checkedKeys: nextCheckedKeys
    });
  }

  //Table Sort
  handleSortColumn(sortColumn, sortType) {
    this.setState({
      loading: true
    });
    setTimeout(() => {
      this.setState({
        sortColumn,
        sortType,
        loading: false
      });
    }, 500);
  }


  onClickApproveAccount(e) {
    var paramAdmin = 0
    var paramReadWrite = 0
    if (this.state.toggleAdmin === true) {
      paramAdmin = 1
    }

    if (this.state.toggleReadWrite === true) {
      paramReadWrite = 1
    }

    // if (this.state.selectedWarehouse === null) {
    //   window.alert('Please select warehouse.')
    // }
    // else if (this.state.selectedCustomer === null) {
    //   window.alert('Please select customer.')
    // }
    if (this.state.selectedModule === null) {
      window.alert('Please select module.')
    }



    // try {
    else if (this.state.checkedKeys.length == 1) {
      var userIdToApprove = this.state.checkedKeys[0]

      this.props.postApproveUser(this.props.currentUserEmail, this.props.userSignInData.AuthToken, userIdToApprove, paramAdmin, 1, paramReadWrite, this.state.selectedModule, formatNumberForAPI(this.state.selectedWarehouse), formatNumberForAPI(this.state.selectedCustomer), null)
    }
    else if (this.state.checkedKeys.length > 1) {
      var keysString = JSON.stringify(this.state.checkedKeys)
      var keysList = formatJsonStringForAPI(keysString)

      this.props.postApproveUser(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, paramAdmin, 1, paramReadWrite, this.state.selectedModule, formatNumberForAPI(this.state.selectedWarehouse), formatNumberForAPI(this.state.selectedCustomer), keysList)
    }
    else {
      window.alert('Please select accounts')
    }
    // }
    // catch (e) { }

  }
  onClickRejectAccount(e, rowData) {

    // try {
    if (this.state.checkedKeys.length == 1) {
      var userIdToApprove = this.state.checkedKeys[0]

      this.props.postApproveUser(this.props.currentUserEmail, this.props.userSignInData.AuthToken, userIdToApprove, 0, 0, 0, null, null, null, null)

    }
    else if (this.state.checkedKeys.length > 1) {
      var keysString = JSON.stringify(this.state.checkedKeys)
      var keysList = formatJsonStringForAPI(keysString)

      this.props.postApproveUser(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, 0, 0, 0, null, null, null, keysList)
    }
    else {
      window.alert('Please select accounts')
    }
    // }
    // catch (e) { }

  }

  onChangeToggleAdmin(e) {
    this.setState({
      toggleAdmin: e
    });
  }

  onChangeToggleReadWrite(e) {
    this.setState({
      toggleReadWrite: e
    });
  }





  onClickDetails(e, rowData, history) {
    try {
      var paramID = rowData['UserID']
      history.push({
        pathname: 'accountdetails',
        search: 'userid=\'' + paramID + '\''
      });
    }
    catch (e) { }
  }



  render() {
    const { checkedKeys, sortColumn, sortType, warehouseArray, moduleArray, customerArray, selectedWarehouse, selectedModule, selectedCustomer } = this.state;


    let pendingAccountsData;
    pendingAccountsData = this.props.AllUsersData.filter(x => x.PendingApproval === true)


    let pendingAccountsDiv;
    if (this.props.isAllUsersLoading) {
      pendingAccountsDiv = <div align="center"><Loader /></div>
    }
    else if (typeof (pendingAccountsData) !== 'undefined' && pendingAccountsData != null) {
      if (pendingAccountsData.length > 0) {
        pendingAccountsDiv =

          <Container style={{ width: '100%', marginBottom: '10px' }}>
            <Paper elevation={3} style={{ padding: '15px', marginTop: '15px', width: '100%' }}>
              {/* <div style={{ marginBottom: '10px' }}> */}

              <h5>
                Pending Accounts
              </h5>

              <Row>
                <Col md={24} sm={24} >
                  <Panel bordered style={{ marginBottom: '10px' }}>
                    <Row>
                      <Col md={12} sm={24} >
                        {RButtonPrimary(this.props.isMobile, 'Approve', this.onClickApproveAccount)}
                      </Col>
                      <Col md={12} sm={24} >
                        {RButtonPrimary(this.props.isMobile, 'Reject', this.onClickRejectAccount)}
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12} sm={24} >
                        {RToggle(this.props.isMobile, 'Admin', this.onChangeToggleAdmin)}
                      </Col>
                      <Col md={12} sm={24} >
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12} sm={24} >
                        {RToggle(this.props.isMobile, 'Read/Write', this.onChangeToggleReadWrite)}
                      </Col>
                      <Col md={12} sm={24} >
                      </Col>
                    </Row>


                    <Row style={{ marginBottom: '5px' }}>
                      <Col md={12} sm={24} >
                        <InputPicker preventOverflow
                          preventOverflow
                          // placeholder={label}
                          placeholder={'Module...'}
                          value={selectedModule}
                          onChange={(e) => { this.onChangeModuleSelection(e) }}
                          data={moduleArray}
                          // block
                          cleanable={false}
                        />
                      </Col>
                      <Col md={12} sm={24} >
                      </Col>
                    </Row>
                    <Row style={{ marginBottom: '5px' }}>
                      <Col md={12} sm={24} >
                        <InputPicker preventOverflow
                          preventOverflow
                          // placeholder={label}
                          placeholder={'Warehouse...'}
                          value={selectedWarehouse}
                          onChange={(e) => { this.onChangeWarehouseSelection(e) }}
                          data={warehouseArray}
                          // block
                          cleanable={false}
                        />
                      </Col>
                      <Col md={12} sm={24} >
                      </Col>
                    </Row>
                    <Row style={{ marginBottom: '5px' }}>
                      <Col md={12} sm={24} >
                        <InputPicker preventOverflow
                          preventOverflow
                          // placeholder={label}
                          placeholder={'Customer...'}
                          value={selectedCustomer}
                          onChange={(e) => { this.onChangeCustomerSelection(e) }}
                          data={customerArray}
                          // block
                          cleanable={false}
                        />
                      </Col>
                      <Col md={12} sm={24} >
                      </Col>

                    </Row>
                  </Panel>
                </Col>
              </Row>


              {RTable(
                pendingAccountsData,        // tableData,
                UserAccountsDataElements,  // DataElements,
                this.sortColumn,                 // sortColumn,
                this.state.sortType,                   // sortType,
                this.props.isOrdersProductLoading,         // loading,
                this.handleSortColumn,      // handleSortColumn,
                this.onClickDetails,        // onClickDetails,
                true,                      // checkColEntry,
                tableIdCol,                 // keyColumn,
                checkedKeys,                       // checkedKeys,
                this.handleCheckAll,                       // handleCheckAll,
                this.handleCheck,                       // handleCheck,
                false,                      // boolSelectButton,
                null,     // onClickSelect,
                null,                       // selectButtonText,
                this.props.isMobile,        // isMobile,
                this.props.history,         // history,
                null                        // highlightRowKey
              )}
              {/* </div> */}

            </Paper>
          </Container>
      }
      else {
        pendingAccountsDiv = <div></div>
      }
    }
    else {
      pendingAccountsDiv = <div></div>
    }


    let approvedAccountsData;
    approvedAccountsData = this.props.AllUsersData.filter(x => x.PendingApproval !== true)


    let approvedAccountsDiv;
    if (this.props.isAllUsersLoading) {
      approvedAccountsDiv = <div align="center"><Loader /></div>
    }
    else if (typeof (approvedAccountsData) !== 'undefined' && approvedAccountsData != null) {
      approvedAccountsDiv =
        <Container style={{ width: '100%', marginBottom: '10px' }}>
          <Paper elevation={3} style={{ padding: '15px', marginTop: '15px', width: '100%' }}>
            {/* <div style={{ marginBottom: '10px' }}> */}
            <h3>
              User Accounts
            </h3>
            {RTable(
              approvedAccountsData,        // tableData,
              UserAccountsDataElements,  // DataElements,
              sortColumn,                 // sortColumn,
              sortType,                   // sortType,
              this.props.isOrdersProductLoading,         // loading,
              this.handleSortColumn,      // handleSortColumn,
              this.onClickDetails,        // onClickDetails,
              false,                      // checkColEntry,
              tableIdCol,                 // keyColumn,
              checkedKeys,                       // checkedKeys,
              null,                       // handleCheckAll,
              null,                       // handleCheck,
              false,                      // boolSelectButton,
              null,     // onClickSelect,
              null,                       // selectButtonText,
              this.props.isMobile,        // isMobile,
              this.props.history,         // history,
              null                        // highlightRowKey
            )}
            {/* </div> */}

          </Paper>
        </Container>

    }
    else {
      approvedAccountsDiv = <div></div>
    }


    return (

      <div>



        {pendingAccountsDiv}

        {approvedAccountsDiv}

        <InviteNewUser currentUserEmail={this.props.currentUserEmail} AuthToken={this.props.userSignInData.AuthToken} />


      </div>
    );
  }
}

export default withRouter(UserManagement);
