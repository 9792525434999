import { connect } from 'react-redux';
import React, { Component } from 'react';
import HouseDetails from './HouseDetails'
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import * as actions from '../../../redux/actions/vasActions';
import { queryStringParse, formatStringForAPI } from '../../../resources/logic/functions';

const mapStateToProps = store =>
({

    currentUserEmail:                           store.userServicesReducer.currentUserEmail,
    userSignInData:                             store.userServicesReducer.userSignInData,

    isHawbLoading:                              store.vasReducer.isHawbLoading,
    HawbData:                                   store.vasReducer.HawbData,

    isHawbRecoveryLoading:                                   store.vasReducer.isHawbRecoveryLoading,
    HawbRecoveryData:                                   store.vasReducer.HawbRecoveryData,
    HawbRecoverySaveSuccessful:               store.vasReducer.HawbRecoverySaveSuccessful,

    isLoadLoading:                              store.vasReducer.isLoadLoading,
    LoadData:                                   store.vasReducer.LoadData,

    PostLoadRemoveSuccessful:                            store.vasReducer.PostLoadRemoveSuccessful,

    isLoadHawbHistoryLoading:                            store.vasReducer.isLoadHawbHistoryLoading,
    LoadHawbHistoryData:                                 store.vasReducer.LoadHawbHistoryData,

    isGetCustomsCityAPILoading: store.vasReducer.isGetCustomsCityAPILoading,
    GetCustomsCityAPISuccessful: store.vasReducer.GetCustomsCityAPISuccessful,
    isCustomsCityMessagesLoading: store.vasReducer.isCustomsCityMessagesLoading,
    CustomsCityMessagesData: store.vasReducer.CustomsCityMessagesData,

});

const mapDispatchToProps = dispatch =>
({

    hawbGet:( _email, _authToken, _HouseID, _HouseRef, _MasterID, _Master, _ShipmentETDStart, _ShipmentETDEnd, _Origin, _LastDischargePort,  _ETADischargeStart,  _ETADischargeEnd, _EstCartageDeliveryStart,  _EstCartageDeliveryEnd,  _ActualCartageDeliveryStart, _ActualCartageDeliveryEnd, _HawbStatusID, _ConsigneeID, _Destination, _WarehouseID, _WarehousePort, _OrderNo, _CustomerID) =>
    {
        dispatch( actions.hawbGet( _email, _authToken, _HouseID, _HouseRef, _MasterID, _Master, _ShipmentETDStart, _ShipmentETDEnd, _Origin, _LastDischargePort,  _ETADischargeStart,  _ETADischargeEnd, _EstCartageDeliveryStart,  _EstCartageDeliveryEnd,  _ActualCartageDeliveryStart, _ActualCartageDeliveryEnd, _HawbStatusID, _ConsigneeID, _Destination, _WarehouseID, _WarehousePort, _OrderNo, _CustomerID ) )
    },

    hawbRecoveryGet:( _email, _authToken, _HouseID, _HouseRef, _WarehouseID, _CustomerID ) =>
    {
        dispatch( actions.hawbRecoveryGet( _email, _authToken, _HouseID, _HouseRef, _WarehouseID, _CustomerID ) )
    },

    hawbRecoveryPost: (_email, _authToken, _MasterID, _HouseID,    _HouseRef,    _RecoveredPCS,    _Pallets,    _Notes, _CustomsCleared, _WarehouseID, _CustomerID) => {
        dispatch(actions.hawbRecoveryPost(_email, _authToken, _MasterID, _HouseID,    _HouseRef,    _RecoveredPCS,    _Pallets,    _Notes, _CustomsCleared, _WarehouseID, _CustomerID))
    },

    loadGet:( _email, _authToken, _LoadID, _HouseID,_MasterID,_PickupDateStart,_PickupDateEnd,_DeliveryDateStart,_DeliveryDateEnd,_LoadStatusID,_Carrier,_BOL,_Trailer,_Seal,_ConsigneeID,_DestinationID , _WarehouseID, _CustomerID) =>
    {
        dispatch( actions.loadGet( _email, _authToken, _LoadID, _HouseID,_MasterID,_PickupDateStart,_PickupDateEnd,_DeliveryDateStart,_DeliveryDateEnd,_LoadStatusID,_Carrier,_BOL,_Trailer,_Seal,_ConsigneeID,_DestinationID, _WarehouseID, _CustomerID ) )
    },

    loadRemovePost:( _email, _authToken, _LoadID, _HouseID, _PCS, _Pallets, _WarehouseID, _CustomerID ) =>
    {
        dispatch( actions.loadRemovePost( _email, _authToken, _LoadID, _HouseID, _PCS, _Pallets, _WarehouseID, _CustomerID ) )
    },
    loadHawbHistoryGet:( _email, _authToken, _HouseID,  _LoadID, _WarehouseID, _CustomerID ) =>
    {
        dispatch( actions.loadHawbHistoryGet( _email, _authToken, _HouseID,  _LoadID, _WarehouseID, _CustomerID ) )
    },

    customsCityMessageGet: (_email, _authToken, _HouseID, _HouseRef, _MasterID, _Master) => {
        dispatch(actions.customsCityMessageGet(_email, _authToken, _HouseID, _HouseRef, _MasterID, _Master))
    },

    customsCityAPIMessages:( _email, _authToken, _paramHouse, _paramMaster ) =>
    {
        dispatch( actions.customsCityAPIMessages( _email, _authToken, _paramHouse, _paramMaster ) )
    },

});

class HouseDetailsContainer extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.handleUrlParam();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.warehouseSelection !== this.props.warehouseSelection) {
            this.handleUrlParam();
        }
        if (prevProps.customerSelection !== this.props.customerSelection) {
            this.handleUrlParam();
        }
        
        if (prevProps.location.search !== this.props.location.search) {
            this.handleUrlParam();
        }

        if (prevProps.HawbRecoverySaveSuccessful !== this.props.HawbRecoverySaveSuccessful && this.props.HawbRecoverySaveSuccessful) {
            setTimeout(() => {
                this.handleUrlParam()
            }, 1000);
        }
        else{
        }

        if (prevProps.PostLoadRemoveSuccessful !== this.props.PostLoadRemoveSuccessful && this.props.PostLoadRemoveSuccessful) {
            setTimeout(() => {
                this.handleUrlParam()
            }, 1000);
        }
        else{}


        if (prevProps.GetCustomsCityAPISuccessful !== this.props.GetCustomsCityAPISuccessful && this.props.GetCustomsCityAPISuccessful) {
            setTimeout(() => {
                const values = queryString.parse(this.props.location.search)
                try{
                    var paramHouseID = queryStringParse( values.houseid )
                }
                catch(e){
                    var paramHouseID = null
                }
                this.props.customsCityMessageGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, paramHouseID, null, null, null)
            }, 1000);
        }
        else{
        }
    }



    handleUrlParam = () => {
        const values = queryString.parse(this.props.location.search)

        try{
            var paramHouseID = queryStringParse( values.houseid )
        }
        catch(e){
            var paramHouseID = null
        }
        try{
            var paramHouseRef = formatStringForAPI( values.houseref )
        }
        catch(e){
            var paramHouseRef = null
        }
        this.props.hawbGet( this.props.currentUserEmail, this.props.userSignInData.AuthToken, paramHouseID, paramHouseRef, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, this.props.warehouseSelection, null, null, this.props.customerSelection );
        this.props.hawbRecoveryGet( this.props.currentUserEmail, this.props.userSignInData.AuthToken, paramHouseID, paramHouseRef, this.props.warehouseSelection, this.props.customerSelection );

        this.props.loadGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, paramHouseID, null, null, null, null, null, null, null, null, null, null, null, null, this.props.warehouseSelection, this.props.customerSelection)
        this.props.loadHawbHistoryGet( this.props.currentUserEmail, this.props.userSignInData.AuthToken, paramHouseID, null, this.props.warehouseSelection, this.props.customerSelection)

        this.props.customsCityMessageGet( this.props.currentUserEmail, this.props.userSignInData.AuthToken, paramHouseID, null, null, null)


    }



    render() {

        return (

            <HouseDetails
                currentUserEmail={this.props.currentUserEmail}
                userSignInData={this.props.userSignInData}
                isMobile={this.props.isMobile}
                warehouseSelection={this.props.warehouseSelection}
                customerSelection={this.props.customerSelection}

                isHawbLoading={this.props.isHawbLoading}
                HawbData={this.props.HawbData}

                isHawbRecoveryLoading={this.props.isHawbRecoveryLoading}
                HawbRecoveryData={this.props.HawbRecoveryData}

                hawbRecoveryPost={this.props.hawbRecoveryPost}
                HawbRecoverySaveSuccessful={this.props.HawbRecoverySaveSuccessful}

                isLoadLoading={this.props.isLoadLoading}
                LoadData={this.props.LoadData}

                loadRemovePost={this.props.loadRemovePost} 
                PostLoadRemoveSuccessful={this.props.PostLoadRemoveSuccessful}

                isLoadHawbHistoryLoading={this.props.isLoadHawbHistoryLoading}
                LoadHawbHistoryData={this.props.LoadHawbHistoryData}

                isCustomsCityMessagesLoading={this.props.isCustomsCityMessagesLoading}
                CustomsCityMessagesData={this.props.CustomsCityMessagesData}

                isGetCustomsCityAPILoading={this.props.isGetCustomsCityAPILoading}

                customsCityAPIMessages={this.props.customsCityAPIMessages}
            

            />
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(HouseDetailsContainer));




