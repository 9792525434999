import { connect } from 'react-redux';
import React, { Component } from 'react';
import LoadBuildByScanning from './LoadBuildByScanning'
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import * as inventoryActions from '../../../../redux/actions/inventoryActions'
import { queryStringParse, formatDateForAPI, formatStringForAPI } from '../../../../resources/logic/functions';
import * as userActions from '../../../../redux/actions/actionsUser'

const mapStateToProps = store =>
({

    currentUserEmail: store.userServicesReducer.currentUserEmail,
    userSignInData: store.userServicesReducer.userSignInData,

    isWarehouseLocationsLoading: store.userServicesReducer.isWarehouseLocationsLoading,
    WarehouseLocationsData: store.userServicesReducer.WarehouseLocationsData,

    isInventoryLotSkuLoading: store.inventoryReducer.isInventoryLotSkuLoading,
    InventoryLotSkuData: store.inventoryReducer.InventoryLotSkuData,

    isInventorySkuWarehouseLocationLoading: store.inventoryReducer.isInventorySkuWarehouseLocationLoading,
    InventorySkuWarehouseLocationData: store.inventoryReducer.InventorySkuWarehouseLocationData,
    SkuLocationDataGrouped: store.inventoryReducer.SkuLocationDataGrouped,



    isInventoryDestinationLoading: store.inventoryReducer.isInventoryDestinationLoading,
    InventoryDestinationData: store.inventoryReducer.InventoryDestinationData,



    isInventorySkuWarehouseLoading: store.inventoryReducer.isInventorySkuWarehouseLoading,
    InventorySkuWarehouseData: store.inventoryReducer.InventorySkuWarehouseData,

    isInventoryCaseWarehouseLoading: store.inventoryReducer.isInventoryCaseWarehouseLoading,
    InventoryCaseWarehouseData: store.inventoryReducer.InventoryCaseWarehouseData,

    isInventoryPalletWarehouseLoading: store.inventoryReducer.isInventoryPalletWarehouseLoading,
    InventoryPalletWarehouseData: store.inventoryReducer.InventoryPalletWarehouseData,

    InventoryLoadPalletCaseSkuPostLoading: store.inventoryReducer.InventoryLoadPalletCaseSkuPostLoading,
    InventoryLoadPalletCaseSkuPostSuccessful: store.inventoryReducer.InventoryLoadPalletCaseSkuPostSuccessful,

    InventoryLoadBarcodeScanPostSuccessful: store.inventoryReducer.InventoryLoadBarcodeScanPostSuccessful,

    isInventoryLoadLotLoading: store.inventoryReducer.isInventoryLoadLotLoading,
    InventoryLoadLotData: store.inventoryReducer.InventoryLoadLotData,




    isInventoryLotBarcodeScanValidateLoading: store.inventoryReducer.isInventoryLotBarcodeScanValidateLoading,
    InventoryLotBarcodeScanValidateData: store.inventoryReducer.InventoryLotBarcodeScanValidateData,

});

const mapDispatchToProps = dispatch =>
({
    userWarehouseLocationsGet: (_email, _authToken, _WarehouseID, _CustomerID) => {
        dispatch(userActions.userWarehouseLocationsGet(_email, _authToken, _WarehouseID, _CustomerID))
    },


    inventoryLotSkuGet: (_email, _authToken, _WarehouseID, _CustomerID, _SkuID, _SKU, _ContainerID, _Container, _SkuStatusID, _WarehouseLocationID) => {
        dispatch(inventoryActions.inventoryLotSkuGet(_email, _authToken, _WarehouseID, _CustomerID, _SkuID, _SKU, _ContainerID, _Container, _SkuStatusID, _WarehouseLocationID))
    },
    inventorySkuWarehouseLocGet: (_email, _authToken, _WarehouseID, _CustomerID, _SkuID, _SKU, _ContainerID, _Container, _SkuStatusID, _WarehouseLocationID) => {
        dispatch(inventoryActions.inventorySkuWarehouseLocGet(_email, _authToken, _WarehouseID, _CustomerID, _SkuID, _SKU, _ContainerID, _Container, _SkuStatusID, _WarehouseLocationID))
    },

    inventoryDestinationGet: (_email, _authToken, _WarehouseID, _CustomerID) => {
        dispatch(inventoryActions.inventoryDestinationGet(_email, _authToken, _WarehouseID, _CustomerID))
    },


    inventoryLotSkuWarehouseLocationGet: (_email, _authToken, _WarehouseLocationID, _WarehouseID, _CustomerID, _PalletStatusID, _CaseStatusID, _SkuStatusID, _PalletID, _PalletBarcode, _CaseID, _CaseBarcode, _CaseSkuID, _CaseSku, _CaseLotCode, _CaseSerialNumber, _SkuID, _SKU, _SkuLotCode, _SkuSerialNumber, _ContainerID, _Container, _RecoveryDateStart, _RecoveryDateEnd, _CaseOrUnitSkuID) => {
        dispatch(inventoryActions.inventoryLotSkuWarehouseLocationGet(_email, _authToken, _WarehouseLocationID, _WarehouseID, _CustomerID, _PalletStatusID, _CaseStatusID, _SkuStatusID, _PalletID, _PalletBarcode, _CaseID, _CaseBarcode, _CaseSkuID, _CaseSku, _CaseLotCode, _CaseSerialNumber, _SkuID, _SKU, _SkuLotCode, _SkuSerialNumber, _ContainerID, _Container, _RecoveryDateStart, _RecoveryDateEnd, _CaseOrUnitSkuID))
    },

    inventoryCaseWarehouseLocationGet: (_email, _authToken, _WarehouseLocationID, _WarehouseID, _CustomerID, _PalletStatusID, _CaseStatusID, _SkuStatusID, _PalletID, _PalletBarcode, _CaseID, _CaseBarcode, _CaseSkuID, _CaseLotCode, _CaseSerialNumber, _SkuID, _SKU, _SkuLotCode, _SkuSerialNumber, _ContainerID, _Container, _RecoveryDateStart, _RecoveryDateEnd) => {
        dispatch(inventoryActions.inventoryCaseWarehouseLocationGet(_email, _authToken, _WarehouseLocationID, _WarehouseID, _CustomerID, _PalletStatusID, _CaseStatusID, _SkuStatusID, _PalletID, _PalletBarcode, _CaseID, _CaseBarcode, _CaseSkuID, _CaseLotCode, _CaseSerialNumber, _SkuID, _SKU, _SkuLotCode, _SkuSerialNumber, _ContainerID, _Container, _RecoveryDateStart, _RecoveryDateEnd))
    },

    inventoryPalletWarehouseLocationGet: (_email, _authToken, _WarehouseLocationID, _WarehouseID, _CustomerID, _PalletStatusID, _CaseStatusID, _SkuStatusID, _PalletID, _PalletBarcode, _CaseID, _CaseBarcode, _CaseSkuID, _CaseLotCode, _CaseSerialNumber, _SkuID, _SKU, _SkuLotCode, _SkuSerialNumber, _ContainerID, _Container, _RecoveryDateStart, _RecoveryDateEnd, _PalletOversize) => {
        dispatch(inventoryActions.inventoryPalletWarehouseLocationGet(_email, _authToken, _WarehouseLocationID, _WarehouseID, _CustomerID, _PalletStatusID, _CaseStatusID, _SkuStatusID, _PalletID, _PalletBarcode, _CaseID, _CaseBarcode, _CaseSkuID, _CaseLotCode, _CaseSerialNumber, _SkuID, _SKU, _SkuLotCode, _SkuSerialNumber, _ContainerID, _Container, _RecoveryDateStart, _RecoveryDateEnd, _PalletOversize))
    },


    inventoryLoadPalletCaseSkuPost: (_email, _authToken, _WarehouseID, _CustomerID, _DataJson, _DataType, _LoadID, _PickupDate, _DeliveryDate, _Carrier, _BOL, _Trailer, _Seal, _TotalUnits, _TotalCases, _TotalPallets, _Destination, _DestinationID, _LoadStatusID, _OrderOutboundID) => {
        dispatch(inventoryActions.inventoryLoadPalletCaseSkuPost(_email, _authToken, _WarehouseID, _CustomerID, _DataJson, _DataType, _LoadID, _PickupDate, _DeliveryDate, _Carrier, _BOL, _Trailer, _Seal, _TotalUnits, _TotalCases, _TotalPallets, _Destination, _DestinationID, _LoadStatusID, _OrderOutboundID))
    },



    inventoryLoadBarcodeScanPost: (_email, _authToken, _WarehouseID, _CustomerID, _PalletData, _CaseData, _UnitData, _LoadID, _PickupDate, _DeliveryDate, _Carrier, _BOL, _Trailer, _Seal, _TotalUnits, _TotalCases, _TotalPallets, _Destination, _DestinationID, _LoadStatusID) => {
        dispatch(inventoryActions.inventoryLoadBarcodeScanPost(_email, _authToken, _WarehouseID, _CustomerID, _PalletData, _CaseData, _UnitData, _LoadID, _PickupDate, _DeliveryDate, _Carrier, _BOL, _Trailer, _Seal, _TotalUnits, _TotalCases, _TotalPallets, _Destination, _DestinationID, _LoadStatusID))
    },
    inventoryLoadLotGet: (_email, _authToken, _WarehouseID, _CustomerID, _LoadID, _LoadStatusID, _ContainerID, _Container, _PalletID, _PalletBarcode, _CaseID, _CaseBarcode, _CaseSkuID, _CaseSku, _CaseLotCode, _CaseSerialNumber, _SkuID, _SKU, _SkuLotCode, _SkuSerialNumber, _CustomerRefID, _PickupDateStart, _PickupDateEnd, _DeliveryDateStart, _DeliveryDateEnd, _Carrier, _BOL, _Trailer, _Seal, _DestinationID, _OrderOutboundID) => {
        dispatch(inventoryActions.inventoryLoadLotGet(_email, _authToken, _WarehouseID, _CustomerID, _LoadID, _LoadStatusID, _ContainerID, _Container, _PalletID, _PalletBarcode, _CaseID, _CaseBarcode, _CaseSkuID, _CaseSku, _CaseLotCode, _CaseSerialNumber, _SkuID, _SKU, _SkuLotCode, _SkuSerialNumber, _CustomerRefID, _PickupDateStart, _PickupDateEnd, _DeliveryDateStart, _DeliveryDateEnd, _Carrier, _BOL, _Trailer, _Seal, _DestinationID, _OrderOutboundID))
    },


    inventoryLotBarcodeScanValidateGet: (_email, _authToken, _WarehouseID, _CustomerID, _Barcode) => {
        dispatch(inventoryActions.inventoryLotBarcodeScanValidateGet(_email, _authToken, _WarehouseID, _CustomerID, _Barcode))
    },


});

class LoadBuildByScanningContainer extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.handleUrlParam();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.location.search !== this.props.location.search) {
            this.handleUrlParam();
        }
        if (prevProps.warehouseSelection !== this.props.warehouseSelection) {
            this.handleUrlParam();
        }
        if (prevProps.customerSelection !== this.props.customerSelection) {
            this.handleUrlParam();
        }
        if (prevProps.InventoryLoadPalletCaseSkuPostSuccessful !== this.props.InventoryLoadPalletCaseSkuPostSuccessful && this.props.InventoryLoadPalletCaseSkuPostSuccessful) {
            setTimeout(() => {
                this.handleUrlParam()
            }, 1000);
        }
        else {
        }

    }



    handleUrlParam = () => {
        const values = queryString.parse(this.props.location.search)

        this.props.userWarehouseLocationsGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection);

        this.props.inventoryDestinationGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection);

        this.props.inventoryLoadLotGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection, null, '1', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null)


    }



    render() {

        return (

            <LoadBuildByScanning
                currentUserEmail={this.props.currentUserEmail}
                userSignInData={this.props.userSignInData}
                isMobile={this.props.isMobile}
                warehouseSelection={this.props.warehouseSelection}
                customerSelection={this.props.customerSelection}

                isWarehouseLocationsLoading={this.props.isWarehouseLocationsLoading}
                WarehouseLocationsData={this.props.WarehouseLocationsData}


                isInventoryLotSkuLoading={this.props.isInventoryLotSkuLoading}
                InventoryLotSkuData={this.props.InventoryLotSkuData}

                isInventorySkuWarehouseLocationLoading={this.props.isInventorySkuWarehouseLocationLoading}
                InventorySkuWarehouseLocationData={this.props.InventorySkuWarehouseLocationData}
                SkuLocationDataGrouped={this.props.SkuLocationDataGrouped}



                inventoryPalletWarehouseLocationGet={this.props.inventoryPalletWarehouseLocationGet}
                inventoryCaseWarehouseLocationGet={this.props.inventoryCaseWarehouseLocationGet}
                inventoryLotSkuWarehouseLocationGet={this.props.inventoryLotSkuWarehouseLocationGet}


                isInventorySkuWarehouseLoading={this.props.isInventorySkuWarehouseLoading}
                InventorySkuWarehouseData={this.props.InventorySkuWarehouseData}

                isInventoryCaseWarehouseLoading={this.props.isInventoryCaseWarehouseLoading}
                InventoryCaseWarehouseData={this.props.InventoryCaseWarehouseData}

                isInventoryPalletWarehouseLoading={this.props.isInventoryPalletWarehouseLoading}
                InventoryPalletWarehouseData={this.props.InventoryPalletWarehouseData}

                InventoryLoadPalletCaseSkuPostLoading={this.props.InventoryLoadPalletCaseSkuPostLoading}
                InventoryLoadPalletCaseSkuPostSuccessful={this.props.InventoryLoadPalletCaseSkuPostSuccessful}
                inventoryLoadPalletCaseSkuPost={this.props.inventoryLoadPalletCaseSkuPost}



                inventoryLoadBarcodeScanPost={this.props.inventoryLoadBarcodeScanPost}
                InventoryLoadBarcodeScanPostSuccessful={this.props.InventoryLoadBarcodeScanPostSuccessful}

                isInventoryLoadLotLoading={this.props.isInventoryLoadLotLoading}
                InventoryLoadLotData={this.props.InventoryLoadLotData}


                isInventoryDestinationLoading={this.props.isInventoryDestinationLoading}
                InventoryDestinationData={this.props.InventoryDestinationData}


                inventoryLotBarcodeScanValidateGet={this.props.inventoryLotBarcodeScanValidateGet}
                isInventoryLotBarcodeScanValidateLoading={this.props.isInventoryLotBarcodeScanValidateLoading}
                InventoryLotBarcodeScanValidateData={this.props.InventoryLotBarcodeScanValidateData}
            />

        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(LoadBuildByScanningContainer));



