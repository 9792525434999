import React from 'react';
import PropTypes from 'prop-types';
import {
    Drawer,
    Paragraph,
    Button,
    ButtonToolbar,
    Checkbox,
    Form,
    FormControl,
    FormGroup,
    ControlLabel,
    HelpBlock,
    DatePicker,

    Row,
    Col,
    Panel,
    InputGroup,
    InputNumber,
    Input,
    Loader

} from 'rsuite';
import RFormControl from '../../componentsGeneral/Rsuite/Form/RFormControl';
import { lettersNumbersDashesInputMask, numbersInputMask, lettersNumbersDashesSpacesInputMask, textStringInputMask } from '../../../resources/logic/inputMasks';
import RButtonSmall from '../../componentsGeneral/Rsuite/Button/RButtonSmall';
import RDatePicker from '../../componentsGeneral/Rsuite/DatePicker/RDatePicker';
import RSelectPicker from '../../componentsGeneral/Rsuite/InputPicker/RSelectPicker';

const yesNoDropDown = [
    {
        "label": "No",
        "value": 0
    },
    {
        "label": "Yes",
        "value": 1
    },

]


const styles = {
    width: '100%'
};

// style={{ overflowX: 'hidden!important' }} 

const mountRef = React.createRef();

const AirlineRecoveryDrawer = ({ onChange, show, placement, confirm, close, masterLabel, onChangePlannedPickupDT, onChangeLFD, onChangeAirlineRecoveryPMC, onChangeAirlineTripMade, onChangeAirlineFreightRecovered, onChangePickupDT, state, ...props }) => (

    props.isMawbLoading ?

        <div align="center">
            <section className="panel">
            </section>
            <Loader
                type="TailSpin"
                color="#3396E5"
                height="50"
                width="50"
            />
        </div>
        :

        <Drawer show={state.showAirlineRecoveryDrawer} onHide={close} placement={placement} style={props.isMobile ? { width: '350px' } : { width: '650px' }}    >
            <Drawer.Header>
                <Drawer.Title style={{ marginBottom: '15px' }}>Master: {masterLabel}</Drawer.Title>

                <FormGroup>
                    <ButtonToolbar >
                        <Button onClick={confirm} appearance="primary">Save</Button>
                        <Button onClick={close} appearance="default">Cancel</Button>

                    </ButtonToolbar>
                </FormGroup>
            </Drawer.Header>
            <Drawer.Body> <div ref={mountRef}> 

                <Form fluid>


                    <Col md={24} sm={24}>

                        <Panel bordered style={{ marginBottom: 10 }} >
                            <FormGroup >
                                {RDatePicker(
                                    props.isMobile,
                                    'LFD',
                                    onChangeLFD,
                                    state.AirlineRecoveryLFD,
                                )}

                                {RDatePicker(
                                    props.isMobile,
                                    'Planned Pickup Date',
                                    onChangePlannedPickupDT,
                                    state.AirlineRecoveryPlannedPickupDT,
                                )}

                                <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
                                    <InputGroup.Addon>Driver Assigned:</InputGroup.Addon>
                                    <Input onChange={(e) => { onChange(e, 'AirlineRecoveryDriverAssigned') }} onInput={(e) => e.target.value = lettersNumbersDashesSpacesInputMask(e)} />
                                </InputGroup>

                                <InputGroup style={{ marginBottom: '5px', width: '100%' }}>
                                    <InputGroup.Addon>PMC:</InputGroup.Addon>
                                    <InputNumber defaultValue={state.AirlineRecoveryPMC} className={'custom-input-number'} onChange={(e) => onChangeAirlineRecoveryPMC(e)} scrollable={false} />
                                </InputGroup>

                                <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
                                    <InputGroup.Addon>Note:</InputGroup.Addon>
                                    <Input onChange={(e) => { onChange(e, 'AirlineRecoveryNote') }} onInput={(e) => e.target.value = textStringInputMask(e)} />
                                </InputGroup>

                                {RSelectPicker(
                                    props.isMobile,
                                    yesNoDropDown,
                                    state.AirlineRecoveryTripMade,
                                    'Trip Made',
                                    onChangeAirlineTripMade,
                                    props.history,
                                    mountRef
                                )}

                                {RSelectPicker(
                                    props.isMobile,
                                    yesNoDropDown,
                                    state.AirlineRecoveryFreightRecovered,
                                    'Freight Recovered',
                                    onChangeAirlineFreightRecovered,
                                    props.history,
                                    mountRef
                                )}
                            </FormGroup>
                        </Panel>

                        {state.AirlineRecoveryFreightRecovered == 1 ?
                            <Panel bordered style={{ marginBottom: 10 }} >
                                <FormGroup >

                                    {RDatePicker(
                                        props.isMobile,
                                        'Pickup Date',
                                        onChangePickupDT,
                                        state.AirlineRecoveryPickupDate,
                                    )}

                                    <InputGroup style={{ marginBottom: '5px', width: '100%' }}>
                                        <InputGroup.Addon>Cartons Recovered:</InputGroup.Addon>
                                        <InputNumber defaultValue={0} className={'custom-input-number'} onChange={(e) => onChange(e, 'AirlineRecoveryCartonsRecovered')} scrollable={false} />
                                    </InputGroup>

                                    <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
                                        <InputGroup.Addon>Picked Up By:</InputGroup.Addon>
                                        <Input onChange={(e) => { onChange(e, 'AirlineRecoveryPickedUpBy') }} onInput={(e) => e.target.value = lettersNumbersDashesSpacesInputMask(e)} />
                                    </InputGroup>

                                    <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
                                        <InputGroup.Addon>Carrier:</InputGroup.Addon>
                                        <Input onChange={(e) => { onChange(e, 'AirlineRecoveryCarrier') }} onInput={(e) => e.target.value = lettersNumbersDashesSpacesInputMask(e)} />
                                    </InputGroup>

                                </FormGroup>
                            </Panel>
                            : <div></div>
                        }

                    </Col>

                </Form>

            </div> </Drawer.Body> 



            <Drawer.Footer>



            </Drawer.Footer>


        </Drawer>

);


AirlineRecoveryDrawer.defaultProps = {
    show: false
};

export default AirlineRecoveryDrawer;


