import React, {
    PureComponent
} from 'react';
import { withRouter } from 'react-router-dom';
import { MawbDataElements } from '../../dataComponentsXdock/MawbDataElements';
import RTableGeneral from '../../componentsGeneral/Rsuite/Table/RTableGeneral'
import { Row, Col, Panel, Loader, Button, ControlLabel, InputGroup, Input } from 'rsuite';
import RSelectPicker from '../../componentsGeneral/Rsuite/InputPicker/RSelectPicker';
import queryString from 'query-string';
import { queryStringParse, formatStringForAPI, formatDateForUrlSearch, formatNumberForAPI, formatStringWithoutQuotesForAPI, isNumeric, formatTrueFalseToBit } from '../../../resources/logic/functions';
import RButtonHideShowFilter from '../../componentsGeneral/Rsuite/Button/RButtonHideShowFilter'
import ReactExport from "react-export-excel";
import * as moment from 'moment'
import { mawbStatus, colorColumnsValuesDropDownFilter } from '../../../app/appConfig';
import RDatePickerRange from '../../componentsGeneral/Rsuite/DatePicker/RDatePickerRange';
import RSearchInputNumberAsString from '../../componentsGeneral/Rsuite/SearchInput/RSearchInputNumberAsString';
import { lettersNumbersDashesSpacesInputMask } from '../../../resources/logic/inputMasks';
import PickupDrawer from './PickupDrawer';
import { appAPI } from '../../../app/appConfig';


//For excel export
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
var currentDateTime = moment().format("_YYYY-MM-DDTHH-mm-ss")

const tableIdCol = "MasterID"

const mountRef = React.createRef();

class Shipment extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            // Table Check Box
            checkedKeys: [],

            //Search Values
            searchMaster: '',
            searchOrderNo: '',
            searchStatus: null,
            searchCarrier: null,
            searchMinETADischargeStart: null,
            searchMinETADischargeEnd: null,

            searchPlannedPickupStart: null,
            searchPlannedPickupEnd: null,
            serachAssignedDriver: '',
            searchMawbColorCode: null,

            isFilterShown: false,

            //Add New Master Values
            // MasterID: null,
            Master: null,
            // TotCartons: null,
            // TotWeight: null,
            // TotChargeable: null,
            // TotVolume: null,
            MinETADischarge: null,
            CarrierName: null,
            FlightVoyage: null,
            ExpectedCartons: null,
            // AgentNote: null,
            // CurrentMawbStatusID: null

            //Add Evolve Data Values
            showEvolveDataDrawer: false,
            EvolveDataMasterID: null,
            EvolveDataMaster: null,
            EvolveDataNo: null,
            EvolveDataPMC: null,
            EvolveDataPickupBy: null,
            EvolveDataTrips: null,
            EvolveDataRecovered: null,
            EvolveDataArrivalTime: null,
            EvolveDataLFD: null,
            EvolveDataArrivalAtWarehouse: null,
            EvolveDataDeliveryTime: null,
            EvolveDataNotes: null,

            //Receive Master Drawer
            ReceiveDict: {},
            unsavedReceiveChanges: false,
            showReceiveDrawer: false,
            ReceiveDrawerMasterID: null,
            ReceiveDrawerMaster: null,
            ReceiveDrawerNo: null,
            ReceiveDrawerPMC: null,
            ReceiveDrawerPickupBy: null,
            ReceiveDrawerTrips: null,
            ReceiveDrawerArrivalTime: null,
            ReceiveDrawerLFD: null,
            ReceiveDrawerArrivalAtWarehouse: null,
            ReceiveDrawerDeliveryTime: null,
            ReceiveDrawerNotes: null,


            //Pickup Drawer
            AirlineRecoveryDict: {

                AirlineRecoveryDriverAssigned: null,
                AirlineRecoveryNote: null,


                AirlineRecoveryCartonsRecovered: null,
                AirlineRecoveryPickedUpBy: null,
                AirlineRecoveryCarrier: null,
                PartialPickup: null,


            },
            AirlineRecoveryPlannedPickupDT: null,
            AirlineRecoveryPickupDate: null,
            AirlineRecoveryLFD: null,
            AirlineRecoveryPMC: null,

            unsavedAirlineRecoveryChanges: false,
            showPickupDrawer: false,
            AirlineRecoveryFreightRecovered: null,
            AirlineRecoveryTripMade: null,

            //Add new HAWB modal
            addNewModalShown: false,

            newModalHouseRef: null,
            newModalConsigneeID: null,
            // newModalNotes: null,
            // newModalOrigin: null,
            // newModalHAWBDestination: null,
            // newModalLastDischargePort: null,
            // newModalDeliverToAddress: null,
            // newModalOrders: null,
            // newModalShipmentETD: null,
            // newModalEstCartageDelivery: null,
            // newModalETADischarge: null,
            // newModalActualCartageDelivery: null,
            newModalCartons: null,
            // newModalNewPallets: null,
            // newModalWeight: null,
            // newModalChargeable: null,
            // newModalVolume: null



            userColorArray: []

        };
        // Table Check Box
        this.handleCheckAll = this.handleCheckAll.bind(this);
        this.handleCheck = this.handleCheck.bind(this);

        // Table Sort
        this.handleSortColumn = this.handleSortColumn.bind(this);

        // //Pickup
        // this.onClickPickup = this.onClickPickup.bind(this);

        //Filter hide Show
        this.onClickIsFilterShown = this.onClickIsFilterShown.bind(this);
        this.onClickResetFilters = this.onClickResetFilters.bind(this);

        //Add new drawer
        this.closeAddNewDrawer = this.closeAddNewDrawer.bind(this);
        this.onClickConfirmAddNewMaster = this.onClickConfirmAddNewMaster.bind(this);

        //Evolve Data drawer
        this.onClickOpenEvolveDataDrawer = this.onClickOpenEvolveDataDrawer.bind(this);
        this.closeEvolveDataDrawer = this.closeEvolveDataDrawer.bind(this);
        this.onClickConfirmEvolveData = this.onClickConfirmEvolveData.bind(this);

        //Airline Recovery drawer
        this.onChangePickupDrawer = this.onChangePickupDrawer.bind(this);
        // this.onClickAirlineRecovery = this.onClickAirlineRecovery.bind(this);
        this.closePickupDrawer = this.closePickupDrawer.bind(this);
        this.onClickPickupDrawerSave = this.onClickPickupDrawerSave.bind(this);
        this.onClickAirlineRecoveryButton = this.onClickAirlineRecoveryButton.bind(this);


        //Receive drawer
        this.onClickAdd = this.onClickAdd.bind(this);
        this.onChangeReceiveDrawer = this.onChangeReceiveDrawer.bind(this);


        this.onClickReceive = this.onClickReceive.bind(this);
        this.onClickAddMissing = this.onClickAddMissing.bind(this);
        this.closeReceiveDrawer = this.closeReceiveDrawer.bind(this);
        this.onClickReceiveDrawerSave = this.onClickReceiveDrawerSave.bind(this);

        //Add new HAWB modal
        this.handleSaveAddNewModal = this.handleSaveAddNewModal.bind(this);
        this.handleCloseAddNewModal = this.handleCloseAddNewModal.bind(this);
        this.onChangeAddNewModal = this.onChangeAddNewModal.bind(this);


        //Search Values
        this.onChangeSearchMaster = this.onChangeSearchMaster.bind(this);
        this.onChangeSearchOrderNo = this.onChangeSearchOrderNo.bind(this);
        this.onChangeSearchStatus = this.onChangeSearchStatus.bind(this);
        this.onChangeSearchCarrier = this.onChangeSearchCarrier.bind(this);
        this.onChangeSearchMinETADischargeStart = this.onChangeSearchMinETADischargeStart.bind(this);
        this.onChangeSearchMinETADischargeEnd = this.onChangeSearchMinETADischargeEnd.bind(this);

        this.onChangeSearchPlannedPickupStart = this.onChangeSearchPlannedPickupStart.bind(this);
        this.onChangeSearchPlannedPickupEnd = this.onChangeSearchPlannedPickupEnd.bind(this);
        this.onChangeSearchAssigedDriver = this.onChangeSearchAssigedDriver.bind(this);
        this.onChangeSearchMawbColorCode = this.onChangeSearchMawbColorCode.bind(this);

        this.addMawb = this.addMawb.bind(this);

        this.onChangeColor = this.onChangeColor.bind(this);


        this.onClickDownloadFileUpload = this.onClickDownloadFileUpload.bind(this);

    }


    // Table Check Box
    handleCheckAll(value, checked, data) {
        const checkedKeys = checked ? [] : data.map(item => item[tableIdCol]);
        this.setState({
            checkedKeys
        });
    }
    handleCheck(value, checked) {
        const { checkedKeys } = this.state;
        const nextCheckedKeys = checked
            ? [...checkedKeys, value]
            : checkedKeys.filter(item => item !== value);

        this.setState({
            checkedKeys: nextCheckedKeys
        });
    }



    // Table Sort
    saveSortTosessionStorage(sortColumn, sortType) {
        try {
            sessionStorage.setItem('sortcolumn', sortColumn)
        }
        catch (e) { }
        try {
            sessionStorage.setItem('sortType', sortType)
        }
        catch (e) { }
        try {
            sessionStorage.setItem('sortURLPathname', this.props.location.pathname)
        }
        catch (e) { }
    }
    loadSortFromStorage() {
        var sortColumn = null
        var sortType = null
        var pathName = null
        try {
            sortColumn = sessionStorage.getItem('sortcolumn')
        }
        catch (e) { }
        try {
            sortType = sessionStorage.getItem('sortType')
        }
        catch (e) { }
        try {
            pathName = sessionStorage.getItem('sortURLPathname')
        }
        catch (e) { }
        if (pathName === this.props.location.pathname) {
            
            this.handleSortColumn(sortColumn, sortType)
        }
    }
    handleSortColumn(sortColumn, sortType) {
        this.setState({
            loading: true
        });
        this.saveSortTosessionStorage(sortColumn, sortType)
        setTimeout(() => {
            this.setState({
                sortColumn,
                sortType,
                loading: false
            });
        }, 100);
    }



    componentDidMount() {
        this.loadSortFromStorage()
        this.updateParamsFromURL()

        const values = queryString.parse(this.props.location.search)

        try {
            var paramMaster = queryStringParse(values.master)
        }
        catch (e) {
            var paramMaster = null
        }
        if (paramMaster != null) {
            this.onChangeSearchMaster(paramMaster)
        }
        try {
            var paramOrderNo = queryStringParse(values.orderno)
        }
        catch (e) {
            var paramOrderNo = null
        }
        if (paramOrderNo != null) {
            this.onChangeSearchOrderNo(paramOrderNo)
        }
        try {
            var date = queryStringParse(values.etadischargestart)
            if (date === null) {
                var paramShipmentETDStart = null
            }
            else {
                var dateValue = new Date(date);
                var dayAdd = moment(dateValue).add(1, 'days')
                var paramShipmentETDStart = dayAdd
            }
        }
        catch (e) {
            var paramShipmentETDStart = null
        }
        this.onChangeSearchMinETADischargeStart(paramShipmentETDStart)
        try {
            var date = queryStringParse(values.etadischargeend)
            if (date === null) {
                var paramShipmentETDEnd = null
            }
            else {
                var dateValue = new Date(date);
                var dayAdd = moment(dateValue).add(1, 'days')
                var paramShipmentETDEnd = dayAdd
            }
        }
        catch (e) {
            var paramShipmentETDEnd = null
        }
        this.onChangeSearchMinETADischargeEnd(paramShipmentETDEnd)


        try {
            var paramCarrier = queryStringParse(values.carrier)
        }
        catch (e) {
            var paramCarrier = null
        }
        this.onChangeSearchCarrier(paramCarrier)
        try {
            var paramStatus = queryStringParse(values.masterstatus)
        }
        catch (e) {
            var paramStatus = null
        }
        this.onChangeSearchStatus(paramStatus)





        try {
            var date = queryStringParse(values.plannedpickupstart)
            if (date === null) {
                var paramPlannedPickupStart = null
            }
            else {
                var dateValue = new Date(date);
                var dayAdd = moment(dateValue).add(1, 'days')
                var paramPlannedPickupStart = dayAdd
            }
        }
        catch (e) {
            var paramPlannedPickupStart = null
        }
        this.onChangeSearchPlannedPickupStart(paramPlannedPickupStart)


        try {
            var date = queryStringParse(values.plannedpickupend)
            if (date === null) {
                var paramPlannedPickupEnd = null
            }
            else {
                var dateValue = new Date(date);
                var dayAdd = moment(dateValue).add(1, 'days')
                var paramPlannedPickupEnd = dayAdd
            }
        }
        catch (e) {
            var paramPlannedPickupEnd = null
        }
        this.onChangeSearchPlannedPickupEnd(paramPlannedPickupEnd)



        try {
            var paramAssignedDriver = queryStringParse(values.assigneddriver)
        }
        catch (e) {
            var paramAssignedDriver = null
        }
        if (paramAssignedDriver != null) {
            this.onChangeSearchAssigedDriver(paramAssignedDriver)
        }






    }
    updateParamsFromURL = () => {

        const values = queryString.parse(this.props.location.search)
        this.onChangeSearchMaster(formatStringWithoutQuotesForAPI(values.master))
        this.onChangeSearchStatus(queryStringParse(values.masterstatus))
        this.onChangeSearchCarrier(queryStringParse(values.carrier))
    }


    componentDidUpdate(prevProps, prevState) {
        if (prevProps.warehouseSelection !== this.props.warehouseSelection) {
            this.setState({
                checkedKeys: []
            });
        }
        if (prevProps.customerSelection !== this.props.customerSelection) {
            this.setState({
                checkedKeys: []
            });
        }

        if (prevProps.MawbEvolveDataSaveSuccessful !== this.props.MawbEvolveDataSaveSuccessful && this.props.MawbEvolveDataSaveSuccessful) {
            this.closeEvolveDataDrawer()
        }
        if (prevProps.HawbData !== this.props.HawbData && this.props.HawbData.length > 0) {
            this.preloadReceiveState()
        }
        if (prevProps.isMawbLoading === true && this.props.isMawbLoading === false && this.state.searchMaster !== '' && this.props.MawbData.length == 0) {
            this.addMawb(this.state.searchMaster)
        }
        if (prevProps.MawbSaveSuccessful !== this.props.MawbSaveSuccessful && this.props.MawbSaveSuccessful) {
            this.closeAddNewDrawer()
        }
        if (prevProps.PostHawbSuccessful !== this.props.PostHawbSuccessful && this.props.PostHawbSuccessful) {
            this.onClickReceive(null, this.state.receiveDataRowData, null)
        }

        if (prevProps.MawbData !== this.props.MawbData && this.props.MawbData.length > 0) {
            const newColorArray = {}
            try {



                var WarehouseData = this.props.MawbData
                for (let key in WarehouseData) {
                    newColorArray[WarehouseData[key]['MasterID']] = WarehouseData[key]['MawbColorCode']

                }

                this.setState({
                    userColorArray: newColorArray
                });

            }
            catch (e) { }
        }
    }


    onClickIsFilterShown(e, isFilterShown) {
        if (isFilterShown) {
            this.setState({
                isFilterShown: false
            });
        }
        else {
            this.setState({
                isFilterShown: true
            });
        }
    }

    onClickResetFilters(e) {
        this.setState({
            isFilterShown: false
        });
        this.setState({
            searchMaster: '',
            searchOrderNo: '',
            searchStatus: null,
            searchCarrier: null,
            searchMinETADischargeStart: null,
            searchMinETADischargeEnd: null,
            searchPlannedPickupStart: null,
            searchPlannedPickupEnd: null,
            serachAssignedDriver: '',
            searchMawbColorCode: null

        });
        this.props.history.push({
            search: ''
        });

        setTimeout(() => {
            this.setState({
                isFilterShown: true
            });
        }, 500);
    }

    onClickApplySearch(e) {
        this.setState({
            searchMaster: '',
            searchOrderNo: '',
        });

        var sDT = null
        var eDT = null

        // if (this.state.searchMinETADischargeStart === null) {
        //     sDT = null
        // }
        // else {
        //     sDT = "'" + moment(this.state.searchMinETADischargeStart).format('YYYY-MM-DD') + "'"
        // }

        // if (this.state.searchMinETADischargeEnd === null) {
        //     eDT = null
        // }
        // else {
        //     eDT = "'" + moment(this.state.searchMinETADischargeEnd).format('YYYY-MM-DD') + "'"
        // }

        if (this.state.searchPlannedPickupStart === null) {
            sDT = null
        }
        else {
            sDT = "'" + moment(this.state.searchPlannedPickupStart).format('YYYY-MM-DD') + "'"
        }
        if (this.state.searchPlannedPickupEnd === null) {
            eDT = null
        }
        else {
            eDT = "'" + moment(this.state.searchPlannedPickupEnd).format('YYYY-MM-DD') + "'"
        }

        try {
            var searchStatus = this.state.searchStatus
            var searchCarrier = this.state.searchCarrier
            var serachAssignedDriver = this.state.serachAssignedDriver
            var searchMawbColorCode = this.state.searchMawbColorCode
            this.props.history.push({
                search:
                    'masterstatus=' + searchStatus + '&' +
                    'carrier=' + searchCarrier + '&' +
                    'assigneddriver=' + serachAssignedDriver + '&' +
                    'mawbcolorcode=' + searchMawbColorCode + '&' +
                    'plannedpickupstart=' + sDT + '&' +
                    'plannedpickupend=' + eDT
            });
        }
        catch (e) { }
    }

    onSearchClearOtherFilters() {
        this.setState({
            searchStatus: null,
            searchCarrier: null,
            searchMinETADischargeStart: null,
            searchMinETADischargeEnd: null,
            searchPlannedPickupStart: null,
            searchPlannedPickupEnd: null,
            serachAssignedDriver: '',
            searchMawbColorCode: null
        });
    }

    onChangeSearchMaster(e) {
        this.onSearchClearOtherFilters()
        this.setState({
            searchMaster: e,
            searchOrderNo: '',
        });
    }

    onChangeSearchOrderNo(e) {
        this.onSearchClearOtherFilters()
        this.setState({
            searchOrderNo: e,
            searchMaster: '',
        });
    }

    onChangeSearchStatus(e) {
        this.setState({
            searchStatus: e
        });
    }

    onChangeSearchCarrier(e) {
        this.setState({
            searchCarrier: e
        });
    }

    onChangeSearchMinETADischargeStart(e) {
        this.setState({
            searchMinETADischargeStart: e
        });
    }
    onChangeSearchMinETADischargeEnd(e) {
        this.setState({
            searchMinETADischargeEnd: e
        });
    }

    onChangeSearchPlannedPickupStart(e) {
        this.setState({
            searchPlannedPickupStart: e
        });
    }
    onChangeSearchPlannedPickupEnd(e) {
        this.setState({
            searchPlannedPickupEnd: e
        });
    }
    onChangeSearchAssigedDriver(e) {
        this.setState({
            serachAssignedDriver: e
        });
    }
    onChangeSearchMawbColorCode(e) {
        this.setState({
            searchMawbColorCode: e
        });
    }

    onClickDetails(e, rowData, history) {
        try {
            var paramID = rowData[tableIdCol]
            history.push({
                pathname: 'masterdetails',
                search: 'masterid=\'' + paramID + '\''
            });
        }
        catch (e) {
        }
    }

    onClick(e) {
        window.alert('Click')
    }




    // //Pickup
    // onClickPickup(e, rowData, history) {
    //     if (window.confirm('Confirm Pickup?')) {
    //         this.props.mawbEvolveDataEntryPost(
    //             this.props.currentUserEmail,
    //             this.props.userSignInData.AuthToken,
    //             formatNumberForAPI( rowData.MasterID ),
    //             null,

    //             formatNumberForAPI(this.state.EvolveDataNo),
    //             formatNumberForAPI(this.state.EvolveDataPMC),
    //             formatStringForAPI(this.state.EvolveDataPickupBy),
    //             formatNumberForAPI(this.state.EvolveDataTrips),
    //             formatDateForUrlSearch(this.state.EvolveDataArrivalTime),
    //             formatDateForUrlSearch(this.state.EvolveDataLFD),
    //             formatDateForUrlSearch(this.state.EvolveDataArrivalAtWarehouse),
    //             formatDateForUrlSearch(this.state.EvolveDataDeliveryTime),
    //             formatStringForAPI(this.state.EvolveDataNotes),
    //             formatNumberForAPI(this.state.EvolveDataRecovered),
    //             this.props.warehouseSelection,
    //             this.props.customerSelection

    //         )
    //     }


    //     this.props.hawbGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, null, rowData.MasterID, null, null, null, null, null, null, null, null, null, null, null, null, null, null, this.props.warehouseSelection, null, null, this.props.customerSelection)
    // }



    //Evolve Data drawer
    onClickOpenEvolveDataDrawer(rowData) {
        this.setState({
            evolveDataRowData: rowData,
            showEvolveDataDrawer: true,
            // EvolveDataMasterID: rowData.MasterID,

            // //Add Evolve Data Values
            EvolveDataMasterID: rowData.MasterID,
            EvolveDataMaster: rowData.Master,
            EvolveDataNo: rowData.No,
            EvolveDataPMC: rowData.PMC,
            EvolveDataPickupBy: rowData.PickupBy,
            EvolveDataTrips: rowData.Trips,
            EvolveDataRecovered: rowData.AirlineRecovered,
            EvolveDataArrivalTime: rowData.ArrivalTime,
            EvolveDataLFD: rowData.LFD,
            EvolveDataArrivalAtWarehouse: rowData.ArrivalAtWarehouse,
            EvolveDataDeliveryTime: rowData.DeliveryTime,
            EvolveDataNotes: rowData.Notes
        });
    }
    onChangeEvolveData = (event, field) => {
        var dict = {};
        dict[field] = event;
        this.setState(
            dict
        );
    };
    onClickConfirmEvolveData(e) {
        if (typeof (this.props.warehouseSelection) === 'number' && typeof (this.props.customerSelection) === 'number') {

            // if (window.confirm('Save New Data?')) {
                this.props.mawbEvolveDataEntryPost(
                    this.props.currentUserEmail,
                    this.props.userSignInData.AuthToken,
                    formatNumberForAPI(this.state.EvolveDataMasterID),
                    formatStringForAPI(this.state.EvolveDataMaster),

                    formatNumberForAPI(this.state.EvolveDataNo),
                    formatNumberForAPI(this.state.EvolveDataPMC),
                    formatStringForAPI(this.state.EvolveDataPickupBy),
                    formatNumberForAPI(this.state.EvolveDataTrips),
                    formatDateForUrlSearch(this.state.EvolveDataArrivalTime),
                    formatDateForUrlSearch(this.state.EvolveDataLFD),
                    formatDateForUrlSearch(this.state.EvolveDataArrivalAtWarehouse),
                    formatDateForUrlSearch(this.state.EvolveDataDeliveryTime),
                    formatStringForAPI(this.state.EvolveDataNotes),
                    formatNumberForAPI(this.state.EvolveDataRecovered),
                    this.props.warehouseSelection,
                    this.props.customerSelection

                )
            // }
        }
        else { window.alert('Please select only one Warehouse and one Customer to save data.') }
    }
    closeEvolveDataDrawer() {
        this.setState({
            showEvolveDataDrawer: false,

            EvolveDataMasterID: null,
            EvolveDataMaster: null,
            EvolveDataNo: null,
            EvolveDataPMC: null,
            EvolveDataPickupBy: null,
            EvolveDataTrips: null,
            EvolveDataRecovered: null,
            EvolveDataArrivalTime: null,
            EvolveDataLFD: null,
            EvolveDataArrivalAtWarehouse: null,
            EvolveDataDeliveryTime: null,
            EvolveDataNotes: null,
        });
    }





    onClickAdd(e, rowData, history) {
        if (typeof (this.props.warehouseSelection) === 'number' && typeof (this.props.customerSelection) === 'number') {
            this.props.mawbAddPost(this.props.currentUserEmail, this.props.userSignInData.AuthToken, formatNumberForAPI(rowData.MasterID), null, this.props.warehouseSelection, this.props.customerSelection)
        }
        else { window.alert('Please select only one Warehouse and one Customer to save data.') }
    }

    //Receive Master drawer
    onClickReceive(e, rowData, history) {
        this.props.hawbGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, null, rowData.MasterID, null, null, null, null, null, null, null, null, null, null, null, null, null, null, this.props.warehouseSelection, null, null, this.props.customerSelection)



        this.setState({
            ReceiveDict: {},
            unsavedReceiveChanges: false,
            receiveDataRowData: rowData,
            showReceiveDrawer: true,
            ReceiveDrawerMasterID: rowData.MasterID,

            //Add Evolve Data Values
            ReceiveDrawerMasterID: rowData.MasterID,
            ReceiveDrawerMaster: rowData.Master,
            ReceiveDrawerNo: rowData.No,
            ReceiveDrawerPMC: rowData.PMC,
            ReceiveDrawerPickupBy: rowData.PickupBy,
            ReceiveDrawerTrips: rowData.Trips,
            ReceiveDrawerArrivalTime: rowData.ArrivalTime,
            ReceiveDrawerLFD: rowData.LFD,
            ReceiveDrawerArrivalAtWarehouse: rowData.ArrivalAtWarehouse,
            ReceiveDrawerDeliveryTime: rowData.DeliveryTime,
            ReceiveDrawerNotes: rowData.Notes
        });
    }
    preloadReceiveState() {
        var receivedDict = {};
        // var testDict = {}
        for (let k in this.props.HawbData) {
            // receivedDict[this.props.HawbData[k].HouseID] = { 'Recovered': this.props.HawbData[k].Cartons - this.props.HawbData[k].RecoveredPCS, 'Pallets': null, 'Notes': '', 'Customs': false }
            receivedDict[this.props.HawbData[k].HouseID] = { 'Recovered': null, 'Pallets': null, 'Notes': '', 'Customs': false }
        }
        this.setState({
            unsavedReceiveChanges: false,
            ReceiveDict: receivedDict
        });
    }
    onChangeReceiveDrawer = (event, item, label) => {
        var currentReceiveDict = this.state.ReceiveDict
        var houseId = item.HouseID;

        currentReceiveDict[houseId][label] = event

        this.setState({
            unsavedReceiveChanges: true,
            ReceiveDict: currentReceiveDict
        });

    };

    onClickReceiveDrawerSave(e) {
        if (typeof (this.props.warehouseSelection) === 'number' && typeof (this.props.customerSelection) === 'number') {

            if (this.state.ReceiveDrawerWarehouse === null) {
                window.alert('Please select warehouse.')
            }
            else {
                for (let k in this.state.ReceiveDict) {
                    try { var recoveredNum = this.state.ReceiveDict[k]['Recovered'] }
                    catch (e) { var recoveredNum = 0 }
                    try { var palletNum = this.state.ReceiveDict[k]['Pallets'] }
                    catch (e) { var palletNum = 0 }

                    if ((recoveredNum === null || recoveredNum == 0) && (palletNum === null || palletNum == 0)) {
                    }
                    else {
                        try {
                            this.props.hawbRecoveryPost(
                                this.props.currentUserEmail,
                                this.props.userSignInData.AuthToken,
                                formatNumberForAPI(this.state.receiveDataRowData.MasterID),
                                formatNumberForAPI(k),
                                null,
                                formatNumberForAPI(this.state.ReceiveDict[k]['Recovered']),
                                formatNumberForAPI(this.state.ReceiveDict[k]['Pallets']),
                                formatStringForAPI(this.state.ReceiveDict[k]['Notes']),
                                this.state.ReceiveDict[k]['Customs'],
                                this.props.warehouseSelection,
                                this.props.customerSelection

                            )
                        }
                        catch (e) { }
                    }
                }
                try {

                    // check customs city api for events
                    this.props.customsCityAPIMessages(
                        this.props.currentUserEmail,
                        this.props.userSignInData.AuthToken,
                        null,
                        this.state.receiveDataRowData.Master
                    )
                }
                catch (e) { }
                this.setState({
                    unsavedReceiveChanges: false,
                    showReceiveDrawer: false
                });
            }
        }
        else { window.alert('Please select only one Warehouse and one Customer to save data.') }
    }

    closeReceiveDrawer() {
        if (this.state.unsavedReceiveChanges) {
            if (window.confirm('Leave without saving changes?')) {
                this.setReceiveDrawerToClose()
            }
        }
        else {
            this.setReceiveDrawerToClose()
        }
    }

    setReceiveDrawerToClose() {
        this.setState({
            ReceiveDict: {},

            unsavedReceiveChanges: false,

            showReceiveDrawer: false,


            ReceiveDrawerMasterID: null,
            ReceiveDrawerMaster: null,
            ReceiveDrawerNo: null,
            ReceiveDrawerPMC: null,
            ReceiveDrawerPickupBy: null,
            ReceiveDrawerTrips: null,
            ReceiveDrawerArrivalTime: null,
            ReceiveDrawerLFD: null,
            ReceiveDrawerArrivalAtWarehouse: null,
            ReceiveDrawerDeliveryTime: null,
            ReceiveDrawerNotes: null,
        });
    }





    //Airline Recovery drawer
    onClickAirlineRecoveryButton(e, rowData, history) {


        this.props.xDockMawbFileUploadGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.customerSelection, null, rowData.MasterID, null, null, null, 0)
   

        var plannedPickupDT = null
        try {
            plannedPickupDT = rowData.PlannedPickupDT
        }
        catch (e) { }
        var lfd = null
        try {
            lfd = rowData.LFD
        }
        catch (e) { }
        var pmc = null
        try {
            pmc = rowData.PMC
        }
        catch (e) { }

        this.setState({
            AirlineRecoveryDict: {
                AirlineRecoveryDriverAssigned: null,
                AirlineRecoveryNote: null,
                AirlineRecoveryCartonsRecovered: null,
                AirlineRecoveryPickedUpBy: null,
                AirlineRecoveryCarrier: null,
                PartialPickup: null,

            },
            evolveDataRowData: rowData,
            unsavedAirlineRecoveryChanges: false,
            showPickupDrawer: true,
            AirlineRecoveryFreightRecovered: null,
            AirlineRecoveryTripMade: null,
            AirlineRecoveryPlannedPickupDT: plannedPickupDT,
            AirlineRecoveryPickupDate: null,
            AirlineRecoveryLFD: lfd,
            AirlineRecoveryPMC: pmc,

        });
    }


    onChangePickupDrawer = (event, label) => {
        var currentReceiveDict = this.state.AirlineRecoveryDict

        currentReceiveDict[label] = event

        this.setState({
            unsavedAirlineRecoveryChanges: true,
            AirlineRecoveryDict: currentReceiveDict
        });
    };
    onChangePlannedPickupDT = (event) => {
        this.setState({
            unsavedAirlineRecoveryChanges: true,
            AirlineRecoveryPlannedPickupDT: event
        });
    }
    onChangeLFD = (event) => {
        this.setState({
            unsavedAirlineRecoveryChanges: true,
            AirlineRecoveryLFD: event
        });
    }
    onChangeAirlineRecoveryPMC = (event) => {
        this.setState({
            unsavedAirlineRecoveryChanges: true,
            AirlineRecoveryPMC: event
        });
    }
    onChangeAirlineTripMade = (event) => {
        // var currentReceiveDict = this.state.AirlineRecoveryDict
        // currentReceiveDict["AirlineRecoveryTripMade"] = event

        this.setState({
            unsavedAirlineRecoveryChanges: true,
            AirlineRecoveryTripMade: event
        });
    }
    onChangeAirlineFreightRecovered = (event) => {
        if (event == 0) {
            var currentReceiveDict = this.state.AirlineRecoveryDict

            currentReceiveDict["AirlineRecoveryCartonsRecovered"] = null
            currentReceiveDict["AirlineRecoveryPickedUpBy"] = null
            currentReceiveDict["AirlineRecoveryCarrier"] = null
            currentReceiveDict["PartialPickup"] = null

            this.setState({
                AirlineRecoveryDict: currentReceiveDict,
                AirlineRecoveryPickupDate: null
            });
        }

        this.setState({
            unsavedAirlineRecoveryChanges: true,
            AirlineRecoveryFreightRecovered: event,
        });

    }
    onChangePickupDT = (event) => {
        this.setState({
            unsavedAirlineRecoveryChanges: true,
            AirlineRecoveryPickupDate: event
        });
    }
    onClickPickupDrawerSave(e) {
        if (typeof (this.props.warehouseSelection) === 'number' && typeof (this.props.customerSelection) === 'number') {

            // if (window.confirm('Save Data?')) {
            

                try {
                    this.props.transportationMawbPickupPost(
                        this.props.currentUserEmail,
                        this.props.userSignInData.AuthToken,

                        formatNumberForAPI(this.state.evolveDataRowData.MasterID),

                        null,
                        null,
                        null,
                        0, //change this to 0? 
                        1,
                        formatDateForUrlSearch(this.state.AirlineRecoveryPickupDate),
                        formatNumberForAPI(this.state.AirlineRecoveryDict.AirlineRecoveryCartonsRecovered),
                        formatStringForAPI(this.state.AirlineRecoveryDict.AirlineRecoveryPickedUpBy),
                        formatStringForAPI(this.state.AirlineRecoveryDict.AirlineRecoveryCarrier),

                        null,
                        null,

                        this.props.warehouseSelection,
                        this.props.customerSelection,

                        null
                        // formatTrueFalseToBit(this.state.AirlineRecoveryDict.PartialPickup)

                    )
                }
                catch (e) { }
            // }

            this.setState({
                unsavedAirlineRecoveryChanges: false,
                showPickupDrawer: false
            });
        }
        else { window.alert('Please select only one Warehouse and one Customer to save data.') }
    }
    closePickupDrawer() {
        if (this.state.unsavedAirlineRecoveryChanges) {
            if (window.confirm('Leave without saving changes?')) {
                this.setAirlineReceiveDrawerToClose()
            }
        }
        else {
            this.setAirlineReceiveDrawerToClose()
        }
    }
    setAirlineReceiveDrawerToClose() {
        this.setState({
            AirlineRecoveryDict: {
                AirlineRecoveryDriverAssigned: null,
                AirlineRecoveryNote: null,

                AirlineRecoveryCartonsRecovered: null,
                AirlineRecoveryPickedUpBy: null,
                AirlineRecoveryCarrier: null,
                PartialPickup: null,
            },
            unsavedAirlineRecoveryChanges: false,
            showPickupDrawer: false,
            AirlineRecoveryTripMade: null,
            AirlineRecoveryFreightRecovered: null,
            AirlineRecoveryPlannedPickupDT: null,
            AirlineRecoveryPickupDate: null,
            AirlineRecoveryLFD: null,
            AirlineRecoveryPMC: null,

        });
    }


    onClickDownloadFileUpload(e, rowData, history) {
        try {
          this.props.downloadFileFromS3(this.props.currentUserEmail, this.props.userSignInData.AuthToken, rowData.S3FileName, rowData.FileName, appAPI.API.data.FileDownloadXDockS3)
        }
        catch (e) {
          window.alert("Error Downloading File.")
        }
      }

    //Add new HAWB modal
    onClickAddMissing() {
        this.handleOpenAddNewModal()
    }
    handleOpenAddNewModal() {
        this.setState({
            addNewModalShown: true,

            newModalHouseRef: null,
            newModalConsigneeID: null,
            // newModalNotes: null,
            // newModalOrigin: null,
            // newModalHAWBDestination: null,
            // newModalLastDischargePort: null,
            // newModalDeliverToAddress: null,
            // newModalOrders: null,
            // newModalShipmentETD: null,
            // newModalEstCartageDelivery: null,
            // newModalETADischarge: null,
            // newModalActualCartageDelivery: null,
            newModalCartons: null,
            // newModalNewPallets: null,
            // newModalWeight: null,
            // newModalChargeable: null,
            // newModalVolume: null
        });
    }
    // onChangeAddNewModal = (event, item, label) => {
    //     var existingItem = this.state.item

    //     existingItem[label] = isNumeric(event)

    //     this.setState({
    //         item: existingItem
    //     });
    // };
    onChangeAddNewModal = (event, field) => {
        var dict = {};
        dict[field] = event;
        this.setState(
            dict
        );
    };
    handleCloseAddNewModal() {
        this.setState({
            addNewModalShown: false,

            newModalHouseRef: null,
            newModalConsigneeID: null,
            // newModalNotes: null,
            // newModalOrigin: null,
            // newModalHAWBDestination: null,
            // newModalLastDischargePort: null,
            // newModalDeliverToAddress: null,
            // newModalOrders: null,
            // newModalShipmentETD: null,
            // newModalEstCartageDelivery: null,
            // newModalETADischarge: null,
            // newModalActualCartageDelivery: null,
            newModalCartons: null,
            // newModalNewPallets: null,
            // newModalWeight: null,
            // newModalChargeable: null,
            // newModalVolume: null
        });
    }
    handleSaveAddNewModal() {
        if (typeof (this.props.warehouseSelection) === 'number' && typeof (this.props.customerSelection) === 'number') {

            if (window.confirm('Do you wish to add missing House Ref?')) {
                this.props.hawbPost(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.state.receiveDataRowData.MasterID, formatStringForAPI(this.state.newModalHouseRef), null, this.state.newModalConsigneeID, formatStringForAPI('Manual Add'), null, null, null, null, null, null, null, null, null, this.state.newModalCartons, null, null, null, null, this.props.warehouseSelection, this.props.customerSelection)
            }
            this.handleCloseAddNewModal()
        }
        else { window.alert('Please select only one Warehouse and one Customer to save data.') }
    }



    //Add new master drawer
    addMawb(searchValue) {
        if (searchValue === null || searchValue == 'null') {

        }
        // else if (window.confirm(searchValue + ' not found, do you wish to add?')) {
        //     this.onClickAddNew()
        //     this.setState({
        //         Master: searchValue
        //     });
        // }
    }
    onClickAddNew(placement) {
        this.setState({
            placement,
            showAddNewMasterDrawer: true
        });
    }
    onChangeAddNewMaster = (event, field) => {
        var dict = {};
        dict[field] = event;
        this.setState(
            dict
        );
    };
    onClickConfirmAddNewMaster(e) {
        if (typeof (this.props.warehouseSelection) === 'number' && typeof (this.props.customerSelection) === 'number') {

            if (this.state.Master == null || this.state.Master == '') {
                window.alert('Please enter new Master.')
            }
            else if (window.confirm('Add New Master?')) {
                // this.props.mawbPost(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, formatStringForAPI(this.state.Master), null, formatNumberForAPI(this.state.TotWeight), formatNumberForAPI(this.state.TotChargeable), formatNumberForAPI(this.state.TotVolume), formatDateForUrlSearch(this.state.MinETADischarge), formatStringForAPI(this.state.CarrierName), formatStringForAPI(this.state.FlightVoyage), formatStringForAPI(this.state.AgentNote), formatNumberForAPI(this.state.CurrentMawbStatusID ))
                this.props.mawbPost(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, formatStringForAPI(this.state.Master), null, null, null, null, formatDateForUrlSearch(this.state.MinETADischarge), formatStringForAPI(this.state.CarrierName), formatStringForAPI(this.state.FlightVoyage), null, 1, this.props.warehouseSelection, this.props.customerSelection, null)
            }
        }
        else { window.alert('Please select only one Warehouse and one Customer to save data.') }
    }
    closeAddNewDrawer() {
        this.setState({
            showAddNewMasterDrawer: false,

            // MasterID: null,
            Master: null,
            // TotCartons: null,
            // TotWeight: null,
            // TotChargeable: null,
            // TotVolume: null,
            MinETADischarge: null,
            CarrierName: null,
            FlightVoyage: null,
            ExpectedCartons: null,
            // AgentNote: null,
            // CurrentMawbStatusID: null
        });
    }


    //Change MAWB row color
    onChangeColor(e, rowData) {
        if (typeof (this.props.warehouseSelection) === 'number' && typeof (this.props.customerSelection) === 'number') {

            this.props.userColorPost(this.props.currentUserEmail, this.props.userSignInData.AuthToken, rowData['MasterID'], e, this.props.warehouseSelection, this.props.customerSelection)
            var updatedColors = this.state.userColorArray
            updatedColors[rowData['MasterID']] = e
            this.setState({
                userColorArray: updatedColors
            })
        }
        else { window.alert('Please select only one Warehouse and one Customer to save data.') }

    }


    render() {

        const { checkedKeys, sortColumn, sortType } = this.state;

        let hideShowSearch;
        hideShowSearch = <Row style={{ marginBottom: '5px' }}>

            <Col md={24} sm={24} >

                <p>
                    {RButtonHideShowFilter(this.props.isMobile, this.state.isFilterShown, 'Filters', this.onClickIsFilterShown, this.onClickResetFilters)}
                </p>

            </Col>
        </Row>

        let searchDiv;

        if (this.state.isFilterShown) {
            searchDiv = <Row  >
                <Col md={24} sm={24} >
                    <Panel bordered style={{ marginBottom: '10px' }}>
                        <Row>
                            {RSearchInputNumberAsString(
                                this.props.isMobile,
                                'Master',
                                this.onChangeSearchMaster,
                                this.props.history,
                                'master',
                                this.state.searchMaster
                            )}
                        </Row>
                        <Row>
                            {RSearchInputNumberAsString(
                                this.props.isMobile,
                                'PO #',
                                this.onChangeSearchOrderNo,
                                this.props.history,
                                'orderno',
                                this.state.searchOrderNo
                            )}
                        </Row>
                    </Panel>
                    <Panel bordered style={{ marginBottom: '10px' }}>
                        <Row>
                            {RSelectPicker(
                                this.props.isMobile,
                                mawbStatus,
                                this.state.searchStatus,
                                'Status',
                                this.onChangeSearchStatus,
                                this.props.history,
                                mountRef
                            )}
                        </Row>
                        <Row>
                            {RSelectPicker(
                                this.props.isMobile,
                                this.props.MawbCarrierData,
                                this.state.searchCarrier,
                                'Carrier',
                                this.onChangeSearchCarrier,
                                this.props.history,
                                mountRef
                            )}
                        </Row>

                        <Row>
                            {RDatePickerRange(
                                this.props.isMobile,
                                'Planned Pickup Date',
                                this.onChangeSearchPlannedPickupStart,
                                this.onChangeSearchPlannedPickupEnd,
                                this.state.searchPlannedPickupStart,
                                this.state.searchPlannedPickupEnd
                            )}
                        </Row>
                        <Row>


                            <div >
                                <ControlLabel>Assigned Driver</ControlLabel>
                                <InputGroup style={{ marginBottom: 10 }}>

                                    <Input value={this.state.serachAssignedDriver} onChange={(e) => { this.onChangeSearchAssigedDriver(e) }} onInput={(e) => e.target.value = lettersNumbersDashesSpacesInputMask(e)} />



                                </InputGroup>
                            </div>
                        </Row>

                        <Row>
                            {RSelectPicker(
                                this.props.isMobile,
                                colorColumnsValuesDropDownFilter,
                                this.state.searchMawbColorCode,
                                'Color',
                                this.onChangeSearchMawbColorCode,
                                this.props.history,
                                mountRef
                            )}
                        </Row>

                        <Row>
                            <Button onClick={(e) => { this.onClickApplySearch(e) }} style={{ marginRight: '10px' }}> Apply </Button>
                            <Button onClick={(e) => { this.onClickResetFilters(e) }}> Reset </Button>
                        </Row>
                    </Panel>
                </Col>
            </Row>
        }
        else if (!this.state.isFilterShown) {
            searchDiv = <div></div>
        }

        let mawbDiv;
        let rTable;
        if (this.props.isMawbLoading) {

            mawbDiv =
                <div align="center">
                    <section className="panel">
                    </section>
                    <Loader
                        type="TailSpin"
                        color="#3396E5"
                        height="50"
                        width="50"
                    />
                </div>
        }
        else if (typeof (this.props.MawbData) !== 'undefined' && this.props.MawbData != null) {


            rTable = RTableGeneral(
                tableIdCol,     //   keyColumn,
                this.props.MawbData,     //   tableData,
                MawbDataElements,       //   DataElements,
                this.props.isMawbLoading,        //   loading,

                this.props.isMobile,        //   isMobile,
                this.props.history,     //   history,

                sortColumn,     //   sortColumn,
                sortType,       //   sortType,
                this.handleSortColumn,      //   handleSortColumn,

                null, // checkColEntry,
                null,       //   checkedKeys,
                null,       //   handleCheckAll,
                null,       //   handleCheck,

                true,      //   boolSelectButton,
                this.onClickAirlineRecoveryButton,       //   onClickSelect,
                'Pickup',       //   selectButtonText,
                null,       //   selectHighlightRowKey,

                false,       //   link1bool,
                null,     //   link1text,
                null,     //   link1onClick,

                false,       //   link2bool,
                null,       //   link2text,
                null  //   link2onClick


            )

            mawbDiv = <div>

                {/* <Button style={{ marginRight: '10px' }} onClick={(e) => { this.onClickAddNew(e) }} >Add New</Button> */}

                {/* <Button onClick={(e) => { this.onClickCustomsCleared(e) }}>Customs Cleared - Selected</Button> */}
                {(this.props.isMobile == true) ? <div></div> :
                    <div>
                        <Row>
                            <ExcelFile element={<Button style={{ float: 'right' }}>Export Excel</Button>} filename={'Shipment_' + currentDateTime}>
                                <ExcelSheet data={this.props.MawbData} name="Shipment">
                                    {
                                        MawbDataElements.map(
                                            ({ dBKey, displayName }, idx) => {
                                                return (
                                                    <ExcelColumn label={displayName} value={dBKey} />
                                                );
                                            }
                                        )
                                    }
                                </ExcelSheet>
                            </ExcelFile>
                        </Row>
                    </div>
                }

            </div>
        }
        else {
            rTable = <div></div>
            mawbDiv = <div></div>
        }

        let currentEvolveDataMaster;
        try {
            if (typeof (this.state.evolveDataRowData.Master) !== 'undefined' && this.props.MawbData != null) {

                currentEvolveDataMaster = this.state.evolveDataRowData.Master
            }
            else {
                currentEvolveDataMaster = ''
            }
        }
        catch (e) {
            currentEvolveDataMaster = ''
        }


        let airlineReceiveDrawer;
        if (this.props.isMawbLoading) {
            airlineReceiveDrawer = <div align="center">
                <section className="panel">
                </section>
                <Loader
                    type="TailSpin"
                    color="#3396E5"
                    height="50"
                    width="50"
                />
            </div>
        }
        else {

            airlineReceiveDrawer = <PickupDrawer {...this.props} state={this.state} onChange={this.onChangePickupDrawer} confirm={this.onClickPickupDrawerSave} close={this.closePickupDrawer} masterLabel={currentEvolveDataMaster} onChangePlannedPickupDT={this.onChangePlannedPickupDT} onChangeAirlineTripMade={this.onChangeAirlineTripMade} onChangeAirlineFreightRecovered={this.onChangeAirlineFreightRecovered} onChangePickupDT={this.onChangePickupDT} onChangeLFD={this.onChangeLFD} onChangeAirlineRecoveryPMC={this.onChangeAirlineRecoveryPMC} onClickDownloadFileUpload={this.onClickDownloadFileUpload}/>

        }

        return (
            <div>
                <h3>
                    Transportation - Pickup
                </h3>
                {hideShowSearch}
                {searchDiv}
                {mawbDiv}
                {rTable}


                {airlineReceiveDrawer}

            </div>
        );

    }
}

export default withRouter(Shipment);

