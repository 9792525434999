import { connect } from 'react-redux';
import React, { Component } from 'react';
import PalletDetail from './PalletDetail'
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import * as userActions from '../../../../redux/actions/actionsUser';
import * as inventoryActions from '../../../../redux/actions/inventoryActions'
import { queryStringParse, formatDateForAPI, formatStringForAPI } from '../../../../resources/logic/functions';


const mapStateToProps = store =>
({

    currentUserEmail: store.userServicesReducer.currentUserEmail,
    userSignInData: store.userServicesReducer.userSignInData,

    isInventorySkuWarehouseLocationLoading: store.inventoryReducer.isInventorySkuWarehouseLocationLoading,
    InventorySkuWarehouseLocationData: store.inventoryReducer.InventorySkuWarehouseLocationData,

    isInventoryLotSkuLoading: store.inventoryReducer.isInventoryLotSkuLoading,
    InventoryLotSkuData: store.inventoryReducer.InventoryLotSkuData,

    isInventorySkuRecoveryHistoryLoading: store.inventoryReducer.isInventorySkuRecoveryHistoryLoading,
    InventorySkuRecoveryHistoryData: store.inventoryReducer.InventorySkuRecoveryHistoryData,

    isInventorySkuWarehouseLocationHistoryLoading: store.inventoryReducer.isInventorySkuWarehouseLocationHistoryLoading,
    InventorySkuWarehouseLocationHistoryData: store.inventoryReducer.InventorySkuWarehouseLocationHistoryData,

    isInventoryLoadSkuLoading: store.inventoryReducer.isInventoryLoadSkuLoading,
    InventoryLoadSkuData: store.inventoryReducer.InventoryLoadSkuData,


    isWarehouseLocationsLoading: store.userServicesReducer.isWarehouseLocationsLoading,
    WarehouseLocationsData: store.userServicesReducer.WarehouseLocationsData,

    InventorySkuWarehouseLocationPostSuccessful: store.inventoryReducer.InventorySkuWarehouseLocationPostSuccessful,

    InventorySkuPostSuccessful: store.inventoryReducer.InventorySkuPostSuccessful,
    SkuID: store.inventoryReducer.SkuID,

    isInventoryCustomerRefLoading: store.inventoryReducer.isInventoryCustomerRefLoading,
    InventoryCustomerRefData: store.inventoryReducer.InventoryCustomerRefData,

    isInventoryProjectLoading: store.inventoryReducer.isInventoryProjectLoading,
    InventoryProjectData: store.inventoryReducer.InventoryProjectData,


    isInventorySkuWarehouseLoading: store.inventoryReducer.isInventorySkuWarehouseLoading,
    InventorySkuWarehouseData: store.inventoryReducer.InventorySkuWarehouseData,

    isInventorySkuWarehouseGroupedLoading: store.inventoryReducer.isInventorySkuWarehouseGroupedLoading,
    InventorySkuWarehouseGroupedData: store.inventoryReducer.InventorySkuWarehouseGroupedData,


    isInventoryLoadLotLoading: store.inventoryReducer.isInventoryLoadLotLoading,
    InventoryLoadLotData: store.inventoryReducer.InventoryLoadLotData,


    isInventoryCaseWarehouseLoading: store.inventoryReducer.isInventoryCaseWarehouseLoading,
    InventoryCaseWarehouseData: store.inventoryReducer.InventoryCaseWarehouseData,

    isInventoryPalletWarehouseLoading: store.inventoryReducer.isInventoryPalletWarehouseLoading,
    InventoryPalletWarehouseData: store.inventoryReducer.InventoryPalletWarehouseData,

    isInventoryPalletWarehouseLoading2: store.inventoryReducer.isInventoryPalletWarehouseLoading2,
    InventoryPalletWarehouseData2: store.inventoryReducer.InventoryPalletWarehouseData2,

    isInventoryPalletContentsLoading: store.inventoryReducer.isInventoryPalletContentsLoading,
    InventoryPalletContentsData: store.inventoryReducer.InventoryPalletContentsData,

    InventoryCaseWarehouseLocationPostSuccessful: store.inventoryReducer.InventoryCaseWarehouseLocationPostSuccessful,

    InventoryPalletWarehouseLocationPostSuccessful: store.inventoryReducer.InventoryPalletWarehouseLocationPostSuccessful,




    isInventoryCaseHistoryLoading: store.inventoryReducer.isInventoryCaseHistoryLoading,
    InventoryCaseHistoryData: store.inventoryReducer.InventoryCaseHistoryData,

    isInventoryPalletWarehouseLocationHistoryLoading: store.inventoryReducer.isInventoryPalletWarehouseLocationHistoryLoading,
    InventoryPalletWarehouseLocationHistoryData: store.inventoryReducer.InventoryPalletWarehouseLocationHistoryData,

    isInventoryCaseSkusHistoryLoading: store.inventoryReducer.isInventoryCaseSkusHistoryLoading,
    InventoryCaseSkusHistoryData: store.inventoryReducer.InventoryCaseSkusHistoryData,

    isInventoryLoadPalletsHistoryLoading: store.inventoryReducer.isInventoryLoadPalletsHistoryLoading,
    InventoryLoadPalletsHistoryData: store.inventoryReducer.InventoryLoadPalletsHistoryData,


    isInventoryUnitWarehouseGroupedLoading: store.inventoryReducer.isInventoryUnitWarehouseGroupedLoading,
    InventoryUnitWarehouseGroupedData: store.inventoryReducer.InventoryUnitWarehouseGroupedData,


    isInventoryLotHistoryTypeLoading: store.inventoryReducer.isInventoryLotHistoryTypeLoading,
    InventoryLotHistoryTypeData: store.inventoryReducer.InventoryLotHistoryTypeData,

    InventoryDeleteContainerPalletCaseUnitPostSuccessful: store.inventoryReducer.InventoryDeleteContainerPalletCaseUnitPostSuccessful,
    isInventoryDeleteContainerPalletCaseUnitLoading: store.inventoryReducer.isInventoryDeleteContainerPalletCaseUnitLoading,


    InventoryLotPalletEditPostSuccessful: store.inventoryReducer.InventoryLotPalletEditPostSuccessful,
    isInventoryLotPalletRefEditLoading: store.inventoryReducer.isInventoryLotPalletRefEditLoading,

});

const mapDispatchToProps = dispatch =>
({
    userWarehouseLocationsGet: (_email, _authToken, _WarehouseID, _CustomerID) => {
        dispatch(userActions.userWarehouseLocationsGet(_email, _authToken, _WarehouseID, _CustomerID))
    },
    inventoryLotSkuGet: (_email, _authToken, _WarehouseID, _CustomerID, _SkuID, _SKU, _ContainerID, _Container, _SkuStatusID, _WarehouseLocationID) => {
        dispatch(inventoryActions.inventoryLotSkuGet(_email, _authToken, _WarehouseID, _CustomerID, _SkuID, _SKU, _ContainerID, _Container, _SkuStatusID, _WarehouseLocationID))
    },
    inventorySkuWarehouseLocGet: (_email, _authToken, _WarehouseID, _CustomerID, _SkuID, _SKU, _ContainerID, _Container, _SkuStatusID, _WarehouseLocationID) => {
        dispatch(inventoryActions.inventorySkuWarehouseLocGet(_email, _authToken, _WarehouseID, _CustomerID, _SkuID, _SKU, _ContainerID, _Container, _SkuStatusID, _WarehouseLocationID))
    },

    inventorySkuRecoverHistoryGet: (_email, _authToken, _WarehouseID, _CustomerID, _SkuID, _SKU, _ContainerID, _Container,) => {
        dispatch(inventoryActions.inventorySkuRecoverHistoryGet(_email, _authToken, _WarehouseID, _CustomerID, _SkuID, _SKU, _ContainerID, _Container,))
    },
    inventorySkuWarehouseLocationHistoryGet: (_email, _authToken, _WarehouseID, _CustomerID, _SkuID, _SKU, _ContainerID, _Container, _SkuStatusID, _WarehouseLocationID) => {
        dispatch(inventoryActions.inventorySkuWarehouseLocationHistoryGet(_email, _authToken, _WarehouseID, _CustomerID, _SkuID, _SKU, _ContainerID, _Container, _SkuStatusID, _WarehouseLocationID))
    },
    inventoryLoadSkuGet: (_email, _authToken, _WarehouseID, _CustomerID, _LoadID, _SkuID) => {
        dispatch(inventoryActions.inventoryLoadSkuGet(_email, _authToken, _WarehouseID, _CustomerID, _LoadID, _SkuID))
    },
    inventorySkuWarehouseLocationPost: (_email, _authToken, _SkuID, _SkuWarehouseLocationID, _WarehouseLocationID, _Units, _Cases, _Pallets, _Oversize, _PreviousWarehouseLocationID, _WarehouseID, _CustomerID, _SkuRecovery, _RecoveryContainerID, _RecoveryDate, _SkuDescription) => {
        dispatch(inventoryActions.inventorySkuWarehouseLocationPost(_email, _authToken, _SkuID, _SkuWarehouseLocationID, _WarehouseLocationID, _Units, _Cases, _Pallets, _Oversize, _PreviousWarehouseLocationID, _WarehouseID, _CustomerID, _SkuRecovery, _RecoveryContainerID, _RecoveryDate, _SkuDescription))
    },

    inventorySkuPost: (_email, _authToken, _SkuID, _SKU, _ContainerID, _WarehouseID, _CustomerID, _CurrentSkuStatusID, _CustomerRefID, _CustomerRef, _ProjectID, _Project, _Notes) => {
        dispatch(inventoryActions.inventorySkuPost(_email, _authToken, _SkuID, _SKU, _ContainerID, _WarehouseID, _CustomerID, _CurrentSkuStatusID, _CustomerRefID, _CustomerRef, _ProjectID, _Project, _Notes))
    },

    inventoryCustomerRefGet: (_email, _authToken, _WarehouseID, _CustomerID) => {
        dispatch(inventoryActions.inventoryCustomerRefGet(_email, _authToken, _WarehouseID, _CustomerID))
    },


    inventoryProjectGet: (_email, _authToken, _WarehouseID, _CustomerID) => {
        dispatch(inventoryActions.inventoryProjectGet(_email, _authToken, _WarehouseID, _CustomerID))
    },



    inventoryLotSkuWarehouseLocationGet: (_email, _authToken, _WarehouseLocationID, _WarehouseID, _CustomerID, _PalletStatusID, _CaseStatusID, _SkuStatusID, _PalletID, _PalletBarcode, _CaseID, _CaseBarcode, _CaseSkuID, _CaseSku, _CaseLotCode, _CaseSerialNumber, _SkuID, _SKU, _SkuLotCode, _SkuSerialNumber, _ContainerID, _Container, _RecoveryDateStart, _RecoveryDateEnd, _CaseOrUnitSkuID) => {
        dispatch(inventoryActions.inventoryLotSkuWarehouseLocationGet(_email, _authToken, _WarehouseLocationID, _WarehouseID, _CustomerID, _PalletStatusID, _CaseStatusID, _SkuStatusID, _PalletID, _PalletBarcode, _CaseID, _CaseBarcode, _CaseSkuID, _CaseSku, _CaseLotCode, _CaseSerialNumber, _SkuID, _SKU, _SkuLotCode, _SkuSerialNumber, _ContainerID, _Container, _RecoveryDateStart, _RecoveryDateEnd, _CaseOrUnitSkuID))
    },

    inventorySkuWarehouseLocationGroupedGet: (_email, _authToken, _WarehouseLocationID, _WarehouseID, _CustomerID, _PalletStatusID, _CaseStatusID, _SkuStatusID, _PalletID, _PalletBarcode, _CaseID, _CaseBarcode, _CaseSkuID, _CaseSku, _CaseLotCode, _CaseSerialNumber, _SkuID, _SKU, _SkuLotCode, _SkuSerialNumber, _ContainerID, _Container, _RecoveryDateStart, _RecoveryDateEnd, _CaseOrUnitSkuID) => {
        dispatch(inventoryActions.inventorySkuWarehouseLocationGroupedGet(_email, _authToken, _WarehouseLocationID, _WarehouseID, _CustomerID, _PalletStatusID, _CaseStatusID, _SkuStatusID, _PalletID, _PalletBarcode, _CaseID, _CaseBarcode, _CaseSkuID, _CaseSku, _CaseLotCode, _CaseSerialNumber, _SkuID, _SKU, _SkuLotCode, _SkuSerialNumber, _ContainerID, _Container, _RecoveryDateStart, _RecoveryDateEnd, _CaseOrUnitSkuID))
    },


    inventoryLoadLotGet: (_email, _authToken, _WarehouseID, _CustomerID, _LoadID, _LoadStatusID, _ContainerID, _Container, _PalletID, _PalletBarcode, _CaseID, _CaseBarcode, _CaseSkuID, _CaseSku, _CaseLotCode, _CaseSerialNumber, _SkuID, _SKU, _SkuLotCode, _SkuSerialNumber, _CustomerRefID, _PickupDateStart, _PickupDateEnd, _DeliveryDateStart, _DeliveryDateEnd, _Carrier, _BOL, _Trailer, _Seal, _DestinationID, _OrderOutboundID) => {
        dispatch(inventoryActions.inventoryLoadLotGet(_email, _authToken, _WarehouseID, _CustomerID, _LoadID, _LoadStatusID, _ContainerID, _Container, _PalletID, _PalletBarcode, _CaseID, _CaseBarcode, _CaseSkuID, _CaseSku, _CaseLotCode, _CaseSerialNumber, _SkuID, _SKU, _SkuLotCode, _SkuSerialNumber, _CustomerRefID, _PickupDateStart, _PickupDateEnd, _DeliveryDateStart, _DeliveryDateEnd, _Carrier, _BOL, _Trailer, _Seal, _DestinationID, _OrderOutboundID))
    },

    inventoryCaseWarehouseLocationGet: (_email, _authToken, _WarehouseLocationID, _WarehouseID, _CustomerID, _PalletStatusID, _CaseStatusID, _SkuStatusID, _PalletID, _PalletBarcode, _CaseID, _CaseBarcode, _CaseSkuID, _CaseLotCode, _CaseSerialNumber, _SkuID, _SKU, _SkuLotCode, _SkuSerialNumber, _ContainerID, _Container, _RecoveryDateStart, _RecoveryDateEnd) => {
        dispatch(inventoryActions.inventoryCaseWarehouseLocationGet(_email, _authToken, _WarehouseLocationID, _WarehouseID, _CustomerID, _PalletStatusID, _CaseStatusID, _SkuStatusID, _PalletID, _PalletBarcode, _CaseID, _CaseBarcode, _CaseSkuID, _CaseLotCode, _CaseSerialNumber, _SkuID, _SKU, _SkuLotCode, _SkuSerialNumber, _ContainerID, _Container, _RecoveryDateStart, _RecoveryDateEnd))
    },

    inventoryPalletWarehouseLocationGet: (_email, _authToken, _WarehouseLocationID, _WarehouseID, _CustomerID, _PalletStatusID, _CaseStatusID, _SkuStatusID, _PalletID, _PalletBarcode, _CaseID, _CaseBarcode, _CaseSkuID, _CaseLotCode, _CaseSerialNumber, _SkuID, _SKU, _SkuLotCode, _SkuSerialNumber, _ContainerID, _Container, _RecoveryDateStart, _RecoveryDateEnd, _PalletOversize) => {
        dispatch(inventoryActions.inventoryPalletWarehouseLocationGet(_email, _authToken, _WarehouseLocationID, _WarehouseID, _CustomerID, _PalletStatusID, _CaseStatusID, _SkuStatusID, _PalletID, _PalletBarcode, _CaseID, _CaseBarcode, _CaseSkuID, _CaseLotCode, _CaseSerialNumber, _SkuID, _SKU, _SkuLotCode, _SkuSerialNumber, _ContainerID, _Container, _RecoveryDateStart, _RecoveryDateEnd, _PalletOversize))
    },

    inventoryPalletWarehouseLocationGet2: (_email, _authToken, _WarehouseLocationID, _WarehouseID, _CustomerID, _PalletStatusID, _CaseStatusID, _SkuStatusID, _PalletID, _PalletBarcode, _CaseID, _CaseBarcode, _CaseSkuID, _CaseLotCode, _CaseSerialNumber, _SkuID, _SKU, _SkuLotCode, _SkuSerialNumber, _ContainerID, _Container, _RecoveryDateStart, _RecoveryDateEnd, _PalletOversize) => {
        dispatch(inventoryActions.inventoryPalletWarehouseLocationGet2(_email, _authToken, _WarehouseLocationID, _WarehouseID, _CustomerID, _PalletStatusID, _CaseStatusID, _SkuStatusID, _PalletID, _PalletBarcode, _CaseID, _CaseBarcode, _CaseSkuID, _CaseLotCode, _CaseSerialNumber, _SkuID, _SKU, _SkuLotCode, _SkuSerialNumber, _ContainerID, _Container, _RecoveryDateStart, _RecoveryDateEnd, _PalletOversize))
    },

    inventoryPalletContentsGet: (_email, _authToken, _ContainerID, _PalletID) => {
        dispatch(inventoryActions.inventoryPalletContentsGet(_email, _authToken, _ContainerID, _PalletID))
    },


    inventoryCaseWarehouseLocationPost: (_email, _authToken, _WarehouseID, _CustomerID, _CaseID, _CaseBarcode, _NewPalletID, _NewPalletBarcode, _CaseIdList) => {
        dispatch(inventoryActions.inventoryCaseWarehouseLocationPost(_email, _authToken, _WarehouseID, _CustomerID, _CaseID, _CaseBarcode, _NewPalletID, _NewPalletBarcode, _CaseIdList))
    },

    inventoryPalletWarehouseLocationPost: (_email, _authToken, _WarehouseID, _CustomerID, _PalletID, _PalletBarcode, _NewWarehouseLocationID, _Departed, _PalletStatusID, _Oversize, _NewWarehouseLocationBarcode, _NewWarehouseLocationBarcodeNoSpaces) => {
        dispatch(inventoryActions.inventoryPalletWarehouseLocationPost(_email, _authToken, _WarehouseID, _CustomerID, _PalletID, _PalletBarcode, _NewWarehouseLocationID, _Departed, _PalletStatusID, _Oversize, _NewWarehouseLocationBarcode, _NewWarehouseLocationBarcodeNoSpaces))
    },






    inventoryCaseHistoryGet: (_email, _authToken, _WarehouseID, _CustomerID, _PalletID, _PalletBarcode, _CaseID, _CaseBarcode, _CaseSkuID,_ContainerID) => {
        dispatch(inventoryActions.inventoryCaseHistoryGet(_email, _authToken, _WarehouseID, _CustomerID, _PalletID, _PalletBarcode, _CaseID, _CaseBarcode, _CaseSkuID,_ContainerID))
    },

    inventoryPalletWarehouseLocationHistoryGet: (_email, _authToken, _WarehouseID, _CustomerID, _PalletID, _PalletBarcode, _CaseID, _ContainerID) => {
        dispatch(inventoryActions.inventoryPalletWarehouseLocationHistoryGet(_email, _authToken, _WarehouseID, _CustomerID, _PalletID, _PalletBarcode, _CaseID, _ContainerID))
    },

    inventoryLoadPalletsHistoryGet: (_email, _authToken, _WarehouseID, _CustomerID, _LoadID, _PalletID, _PalletBarcode,) => {
        dispatch(inventoryActions.inventoryLoadPalletsHistoryGet(_email, _authToken, _WarehouseID, _CustomerID, _LoadID, _PalletID, _PalletBarcode,))
    },


    inventoryCaseSkusHistoryGet: (_email, _authToken, _WarehouseID, _CustomerID, _PalletID, _PalletBarcode, _CaseID, _CaseBarcode, _CaseSkuID, _UnitSkuID, _ContainerID) => {
        dispatch(inventoryActions.inventoryCaseSkusHistoryGet(_email, _authToken, _WarehouseID, _CustomerID, _PalletID, _PalletBarcode, _CaseID, _CaseBarcode, _CaseSkuID, _UnitSkuID, _ContainerID))
    },


    inventoryUnitWarehouseLocationGroupedGet: (_email, _authToken, _WarehouseLocationID, _WarehouseID, _CustomerID, _PalletStatusID, _CaseStatusID, _SkuStatusID, _PalletID, _PalletBarcode, _CaseID, _CaseBarcode, _CaseSkuID, _CaseSku, _CaseLotCode, _CaseSerialNumber, _SkuID, _SKU, _SkuLotCode, _SkuSerialNumber, _ContainerID, _Container, _RecoveryDateStart, _RecoveryDateEnd, _CaseOrUnitSkuID) => {
        dispatch(inventoryActions.inventoryUnitWarehouseLocationGroupedGet(_email, _authToken, _WarehouseLocationID, _WarehouseID, _CustomerID, _PalletStatusID, _CaseStatusID, _SkuStatusID, _PalletID, _PalletBarcode, _CaseID, _CaseBarcode, _CaseSkuID, _CaseSku, _CaseLotCode, _CaseSerialNumber, _SkuID, _SKU, _SkuLotCode, _SkuSerialNumber, _ContainerID, _Container, _RecoveryDateStart, _RecoveryDateEnd, _CaseOrUnitSkuID))
    },

    inventoryLotHistoryTypeGet: (_email, _authToken, _InventoryDelete) => {
        dispatch(inventoryActions.inventoryLotHistoryTypeGet(_email, _authToken, _InventoryDelete))
    },

    inventoryLotDeleteContainerPalletCaseUnitPost: (_email, _authToken, _WarehouseID, _CustomerID, _HistoryTypeID, _ContainerIdList, _PalletIdList, _PalletBarcode, _CaseIdList, _CaseBarcode, _UnitIdList, _UnitsToRemove) => {
        dispatch(inventoryActions.inventoryLotDeleteContainerPalletCaseUnitPost(_email, _authToken, _WarehouseID, _CustomerID, _HistoryTypeID, _ContainerIdList, _PalletIdList, _PalletBarcode, _CaseIdList, _CaseBarcode, _UnitIdList, _UnitsToRemove))
    },


    inventoryLotPalletRefEditPost: (_email, _authToken, _PalletID, _PalletBarcode, _Notes, _Oversize, _Length, _Width, _RecoveryDateEdit) => {
        dispatch(inventoryActions.inventoryLotPalletRefEditPost(_email, _authToken, _PalletID, _PalletBarcode, _Notes, _Oversize, _Length, _Width, _RecoveryDateEdit))
    },

});

class PalletDetailContainer extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.handleUrlParam();

        // this.props.inventoryCustomerRefGet( this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection )
        // this.props.inventoryProjectGet( this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection )
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.location.search !== this.props.location.search) {
            this.handleUrlParam();
        }
        if (prevProps.warehouseSelection !== this.props.warehouseSelection) {
            this.handleUrlParam();
        }
        if (prevProps.customerSelection !== this.props.customerSelection) {
            this.handleUrlParam();
        }
        if (prevProps.InventorySkuWarehouseLocationPostSuccessful !== this.props.InventorySkuWarehouseLocationPostSuccessful && this.props.InventorySkuWarehouseLocationPostSuccessful) {
            this.handleUrlParam()
        }

        if (prevProps.InventorySkuPostSuccessful !== this.props.InventorySkuPostSuccessful && this.props.InventorySkuPostSuccessful) {
            this.handleUrlParam()
        }

        if (prevProps.InventoryCaseWarehouseLocationPostSuccessful !== this.props.InventoryCaseWarehouseLocationPostSuccessful && this.props.InventoryCaseWarehouseLocationPostSuccessful) {
            this.handleUrlParam()
        }

        if (prevProps.InventoryPalletWarehouseLocationPostSuccessful !== this.props.InventoryPalletWarehouseLocationPostSuccessful && this.props.InventoryPalletWarehouseLocationPostSuccessful) {
            this.handleUrlParam()
        }

        if (prevProps.InventoryDeleteContainerPalletCaseUnitPostSuccessful !== this.props.InventoryDeleteContainerPalletCaseUnitPostSuccessful && this.props.InventoryDeleteContainerPalletCaseUnitPostSuccessful) {
            this.handleUrlParam()
        }


        if (prevProps.InventoryLotPalletEditPostSuccessful !== this.props.InventoryLotPalletEditPostSuccessful && this.props.InventoryLotPalletEditPostSuccessful) {
            this.handleUrlParam()
        }

    }



    handleUrlParam = () => {
        const values = queryString.parse(this.props.location.search)

        try {
            var paramSku = formatStringForAPI(values.sku)
        }
        catch (e) {
            var paramSku = null
        }
        try {
            var paramPalletID = formatStringForAPI(values.palletid)
        }
        catch (e) {
            var paramPalletID = null
        }

        this.props.inventoryLotHistoryTypeGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, 1)
        

        if (paramPalletID != null){
            this.props.inventoryPalletWarehouseLocationGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, this.props.warehouseSelection, this.props.customerSelection, null, null, null, paramPalletID, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null)
            this.props.inventoryPalletContentsGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, paramPalletID)
            this.props.inventoryLotSkuWarehouseLocationGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, this.props.warehouseSelection, this.props.customerSelection, null, null, null, paramPalletID, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null)
            this.props.inventorySkuWarehouseLocationGroupedGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, this.props.warehouseSelection, this.props.customerSelection, null, null, null, paramPalletID, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null)
            this.props.inventoryLoadLotGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection, null, null, null, null, paramPalletID, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null)
            this.props.inventoryCaseWarehouseLocationGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, this.props.warehouseSelection, this.props.customerSelection, null, null, null, paramPalletID, null, null, null, null, null, null, null, null, null, null, null, null, null, null)
   
            
            this.props.inventoryPalletWarehouseLocationHistoryGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection, paramPalletID, null, null, null)
            this.props.inventoryLoadPalletsHistoryGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection, null, paramPalletID, null)


            this.props.inventoryUnitWarehouseLocationGroupedGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, this.props.warehouseSelection, this.props.customerSelection, null, null, null, paramPalletID, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null)


        }

        this.props.userWarehouseLocationsGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection);

    }



    render() {

        return (

            <PalletDetail
                currentUserEmail={this.props.currentUserEmail}
                userSignInData={this.props.userSignInData}
                isMobile={this.props.isMobile}
                warehouseSelection={this.props.warehouseSelection}
                customerSelection={this.props.customerSelection}

                userWarehouseLocationsGet={this.props.userWarehouseLocationsGet}
                isWarehouseLocationsLoading={this.props.isWarehouseLocationsLoading}
                WarehouseLocationsData={this.props.WarehouseLocationsData}

                isInventorySkuWarehouseLocationLoading={this.props.isInventorySkuWarehouseLocationLoading}
                InventorySkuWarehouseLocationData={this.props.InventorySkuWarehouseLocationData}

                isInventoryLotSkuLoading={this.props.isInventoryLotSkuLoading}
                InventoryLotSkuData={this.props.InventoryLotSkuData}

                isInventorySkuRecoveryHistoryLoading={this.props.isInventorySkuRecoveryHistoryLoading}
                InventorySkuRecoveryHistoryData={this.props.InventorySkuRecoveryHistoryData}

                isInventorySkuWarehouseLocationHistoryLoading={this.props.isInventorySkuWarehouseLocationHistoryLoading}
                InventorySkuWarehouseLocationHistoryData={this.props.InventorySkuWarehouseLocationHistoryData}

                inventoryLoadSkuGet={this.props.inventoryLoadSkuGet}
                isInventoryLoadSkuLoading={this.props.isInventoryLoadSkuLoading}
                InventoryLoadSkuData={this.props.InventoryLoadSkuData}

                InventorySkuWarehouseLocationPostSuccessful={this.props.InventorySkuWarehouseLocationPostSuccessful}
                inventorySkuWarehouseLocationPost={this.props.inventorySkuWarehouseLocationPost}


                inventorySkuPost={this.props.inventorySkuPost}
                InventorySkuPostSuccessful={this.props.InventorySkuPostSuccessful}

                inventoryCustomerRefGet={this.props.inventoryCustomerRefGet}
                isInventoryCustomerRefLoading={this.props.isInventoryCustomerRefLoading}
                InventoryCustomerRefData={this.props.InventoryCustomerRefData}

                inventoryProjectGet={this.props.inventoryProjectGet}
                isInventoryProjectLoading={this.props.isInventoryProjectLoading}
                InventoryProjectData={this.props.InventoryProjectData}




                isInventorySkuWarehouseLoading={this.props.isInventorySkuWarehouseLoading}
                InventorySkuWarehouseData={this.props.InventorySkuWarehouseData}

                isInventorySkuWarehouseGroupedLoading={this.props.isInventorySkuWarehouseGroupedLoading}
                InventorySkuWarehouseGroupedData={this.props.InventorySkuWarehouseGroupedData}


                isInventoryLoadLotLoading={this.props.isInventoryLoadLotLoading}
                InventoryLoadLotData={this.props.InventoryLoadLotData}

                isInventoryCaseWarehouseLoading={this.props.isInventoryCaseWarehouseLoading}
                InventoryCaseWarehouseData={this.props.InventoryCaseWarehouseData}

                isInventoryPalletWarehouseLoading={this.props.isInventoryPalletWarehouseLoading}
                InventoryPalletWarehouseData={this.props.InventoryPalletWarehouseData}

                inventoryPalletWarehouseLocationGet2={this.props.inventoryPalletWarehouseLocationGet2}

                isInventoryPalletWarehouseLoading2={this.props.isInventoryPalletWarehouseLoading2}
                InventoryPalletWarehouseData2={this.props.InventoryPalletWarehouseData2}

                isInventoryPalletContentsLoading={this.props.isInventoryPalletContentsLoading}
                InventoryPalletContentsData={this.props.InventoryPalletContentsData}



                inventoryCaseWarehouseLocationPost={this.props.inventoryCaseWarehouseLocationPost}
                InventoryCaseWarehouseLocationPostSuccessful={this.props.InventoryCaseWarehouseLocationPostSuccessful}

                InventoryPalletWarehouseLocationPostSuccessful={this.props.InventoryPalletWarehouseLocationPostSuccessful}
                inventoryPalletWarehouseLocationPost={this.props.inventoryPalletWarehouseLocationPost}








                isInventoryCaseHistoryLoading={this.props.isInventoryCaseHistoryLoading}
                InventoryCaseHistoryData={this.props.InventoryCaseHistoryData}


                isInventoryPalletWarehouseLocationHistoryLoading={this.props.isInventoryPalletWarehouseLocationHistoryLoading}
                InventoryPalletWarehouseLocationHistoryData={this.props.InventoryPalletWarehouseLocationHistoryData}

                isInventoryCaseSkusHistoryLoading={this.props.isInventoryCaseSkusHistoryLoading}
                InventoryCaseSkusHistoryData={this.props.InventoryCaseSkusHistoryData}

                isInventoryLoadPalletsHistoryLoading={this.props.isInventoryLoadPalletsHistoryLoading}
                InventoryLoadPalletsHistoryData={this.props.InventoryLoadPalletsHistoryData}


                isInventoryUnitWarehouseGroupedLoading={this.props.isInventoryUnitWarehouseGroupedLoading}
                InventoryUnitWarehouseGroupedData={this.props.InventoryUnitWarehouseGroupedData}
            
                isInventoryLotHistoryTypeLoading={this.props.isInventoryLotHistoryTypeLoading}
                InventoryLotHistoryTypeData={this.props.InventoryLotHistoryTypeData}

                InventoryDeleteContainerPalletCaseUnitPostSuccessful={this.props.InventoryDeleteContainerPalletCaseUnitPostSuccessful}  
                isInventoryDeleteContainerPalletCaseUnitLoading={this.props.isInventoryDeleteContainerPalletCaseUnitLoading}
                inventoryLotDeleteContainerPalletCaseUnitPost={this.props.inventoryLotDeleteContainerPalletCaseUnitPost}


                InventoryLotPalletEditPostSuccessful={this.props.InventoryLotPalletEditPostSuccessful}
                isInventoryLotPalletRefEditLoading={this.props.isInventoryLotPalletRefEditLoading}
                inventoryLotPalletRefEditPost={this.props.inventoryLotPalletRefEditPost}
            />

        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PalletDetailContainer));



