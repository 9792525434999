import { connect } from 'react-redux';
import React, { Component } from 'react';
import LoadDetails from './LoadDetails'
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import * as actions from '../../../redux/actions/vasActions';
import { queryStringParse, formatStringForAPI } from '../../../resources/logic/functions';

const mapStateToProps = store =>
({

    currentUserEmail:                           store.userServicesReducer.currentUserEmail,
    userSignInData:                             store.userServicesReducer.userSignInData,

    isLoadLoading:                              store.vasReducer.isLoadLoading,
    LoadData:                                   store.vasReducer.LoadData,

    isLoadHawbLoading:                                  store.vasReducer.isLoadHawbLoading,
    LoadHawbData:                                       store.vasReducer.LoadHawbData,

    PostLoadSuccessful:                                 store.vasReducer.PostLoadSuccessful,

    PostLoadRemoveSuccessful:                            store.vasReducer.PostLoadRemoveSuccessful,

    isLoadHawbHistoryLoading:                            store.vasReducer.isLoadHawbHistoryLoading,
    LoadHawbHistoryData:                                 store.vasReducer.LoadHawbHistoryData,

    isHawbLoading: store.vasReducer.isHawbLoading,
    HawbData: store.vasReducer.HawbData,

});

const mapDispatchToProps = dispatch =>
({

    loadGet:( _email, _authToken, _LoadID, _HouseID,_MasterID,_PickupDateStart,_PickupDateEnd,_DeliveryDateStart,_DeliveryDateEnd,_LoadStatusID,_Carrier,_BOL,_Trailer,_Seal,_ConsigneeID,_DestinationID, _WarehouseID, _CustomerID ) =>
    {
        dispatch( actions.loadGet( _email, _authToken, _LoadID, _HouseID,_MasterID,_PickupDateStart,_PickupDateEnd,_DeliveryDateStart,_DeliveryDateEnd,_LoadStatusID,_Carrier,_BOL,_Trailer,_Seal,_ConsigneeID,_DestinationID, _WarehouseID, _CustomerID ) )
    },
    loadHawbGet:( _email, _authToken, _LoadID, _HouseID, _MasterID, _WarehouseID, _CustomerID ) =>
    {
        dispatch( actions.loadHawbGet( _email, _authToken, _LoadID, _HouseID, _MasterID, _WarehouseID, _CustomerID ) )
    },

    loadPost:( _email, _authToken, _LoadID, _PickupDate, _DeliveryDate, _Carrier, _BOL, _Trailer, _Seal, _TotalPCS, _TotalPallets, _LoadStatusID, _LoadHawbData, _HouseID, _PCS, _Pallets, _WarehouseID, _CustomerID  ) =>
    {
        dispatch( actions.loadPost( _email, _authToken, _LoadID, _PickupDate, _DeliveryDate, _Carrier, _BOL, _Trailer, _Seal, _TotalPCS, _TotalPallets, _LoadStatusID, _LoadHawbData, _HouseID, _PCS, _Pallets, _WarehouseID, _CustomerID  ) )
    },

    loadRemovePost:( _email, _authToken, _LoadID, _HouseID, _PCS, _Pallets , _WarehouseID, _CustomerID) =>
    {
        dispatch( actions.loadRemovePost( _email, _authToken, _LoadID, _HouseID, _PCS, _Pallets , _WarehouseID, _CustomerID) )
    },
    loadHawbHistoryGet:( _email, _authToken, _HouseID,  _LoadID, _WarehouseID, _CustomerID ) =>
    {
        dispatch( actions.loadHawbHistoryGet( _email, _authToken, _HouseID,  _LoadID, _WarehouseID, _CustomerID ) )
    },

    hawbGet: (_email, _authToken, _HouseID, _HouseRef, _MasterID, _Master, _ShipmentETDStart, _ShipmentETDEnd, _Origin, _LastDischargePort, _ETADischargeStart, _ETADischargeEnd, _EstCartageDeliveryStart, _EstCartageDeliveryEnd, _ActualCartageDeliveryStart, _ActualCartageDeliveryEnd, _HawbStatusID, _ConsigneeID, _Destination, _WarehouseID, _WarehousePort, _OrderNo, _CustomerID) => {
        dispatch(actions.hawbGet(_email, _authToken, _HouseID, _HouseRef, _MasterID, _Master, _ShipmentETDStart, _ShipmentETDEnd, _Origin, _LastDischargePort, _ETADischargeStart, _ETADischargeEnd, _EstCartageDeliveryStart, _EstCartageDeliveryEnd, _ActualCartageDeliveryStart, _ActualCartageDeliveryEnd, _HawbStatusID, _ConsigneeID, _Destination, _WarehouseID, _WarehousePort, _OrderNo, _CustomerID))
    },

});

class LoadDetailsContainer extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.handleUrlParam();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.warehouseSelection !== this.props.warehouseSelection) {
            this.handleUrlParam();
        }
        if (prevProps.customerSelection !== this.props.customerSelection) {
            this.handleUrlParam();
        }
        
        if (prevProps.location.search !== this.props.location.search) {
            this.handleUrlParam();
        }
        if (prevProps.PostLoadRemoveSuccessful !== this.props.PostLoadRemoveSuccessful && this.props.PostLoadRemoveSuccessful) {
            setTimeout(() => {
                this.handleUrlParam()
            }, 1000);
        }
        else{}
        if (prevProps.PostLoadSuccessful !== this.props.PostLoadSuccessful && this.props.PostLoadSuccessful) {
            setTimeout(() => {
                this.handleUrlParam()
            }, 1000);
        }
        else{}
    }



    handleUrlParam = () => {
        const values = queryString.parse(this.props.location.search)

        try{
            var paramLoadID = queryStringParse( values.loadid )
        }
        catch(e){
            var paramLoadID = null
        }
        // try{
        //     var paramHouseRef = queryStringParse( values.houseref )
        // }
        // catch(e){
        //     var paramHouseRef = null
        // }
        // this.props.hawbGet( this.props.currentUserEmail, this.props.userSignInData.AuthToken, paramHouseID, paramHouseRef, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null );
        // this.props.hawbRecoveryGet( this.props.currentUserEmail, this.props.userSignInData.AuthToken, paramHouseID, paramHouseRef );
        this.props.loadGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, paramLoadID, null, null, null, null, null, null, null, null, null, null, null, null, null, this.props.warehouseSelection, this.props.customerSelection)
        this.props.loadHawbGet( this.props.currentUserEmail, this.props.userSignInData.AuthToken, paramLoadID, null, null, this.props.warehouseSelection, this.props.customerSelection)
        this.props.loadHawbHistoryGet( this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, paramLoadID, this.props.warehouseSelection, this.props.customerSelection)

        this.props.hawbGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            1,
            null,
            null,
            this.props.warehouseSelection,
            null, 
            null,
            this.props.customerSelection
            );

    }



    render() {

        return (

            <LoadDetails
                currentUserEmail={this.props.currentUserEmail}
                userSignInData={this.props.userSignInData}
                isMobile={this.props.isMobile}
                warehouseSelection={this.props.warehouseSelection}
                customerSelection={this.props.customerSelection}

                isLoadLoading={this.props.isLoadLoading}
                LoadData={this.props.LoadData}

                isLoadHawbLoading={this.props.isLoadHawbLoading}
                LoadHawbData={this.props.LoadHawbData}

                loadPost={this.props.loadPost}
                PostLoadSuccessful={this.props.PostLoadSuccessful}

                loadRemovePost={this.props.loadRemovePost}
                PostLoadRemoveSuccessful={this.props.PostLoadRemoveSuccessful}

                isLoadHawbHistoryLoading={this.props.isLoadHawbHistoryLoading}
                LoadHawbHistoryData={this.props.LoadHawbHistoryData}

                isHawbLoading={this.props.isHawbLoading}
                HawbData={this.props.HawbData}
                hawbGet={this.props.hawbGet}
            />
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(LoadDetailsContainer));




