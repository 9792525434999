import * as moment from 'moment';
import queryString from 'query-string';
import React, {
    PureComponent
} from 'react';
import ReactExport from "react-export-excel";
import Loader from 'react-loader-spinner';
import { withRouter } from 'react-router-dom';
import { Button, Col, Panel, PanelGroup, Row, Timeline, InputGroup, Input } from 'rsuite';
import { convertNanToZero, formatAllTextNumbersSymbolsForAPI, formatBitToTrueFalse, formatDateForAPI, formatDateForUrlSearch, formatDateTimeForUrlSearch, formatNumberForAPI, formatStringForAPI, formatTrueFalseToBit } from '../../../../resources/logic/functions';
import RTableGeneral from '../../../componentsGeneral/Rsuite/Table/RTableGeneral';
import InventoryLoadSkuDetailsDataElements from '../../../dataComponentsInventory/InventoryLoadSkuDetailsDataElements';
// import EditSingleWarehouseLocationsDrawer from './EditSingleWarehouseLocationsDrawer';
// import EditSkuDetailsDrawer from './EditSkuDetailsDrawer';
import InventoryCaseWarehouseDataElements from '../../../dataComponentsInventory/InventoryCaseWarehouseDataElements';
// import Barcode from '../Barcode/Barcode';
import InventoryUnitWarehouseGroupedDataElements from '../../../dataComponentsInventory/InventoryUnitWarehouseGroupedDataElements';
import LoadPalletHistoryDiv from '../../_Divs_Lot_History/LoadPalletHistoryDiv';
import PalletWarehouseLocationHistoryDiv from '../../_Divs_Lot_History/PalletWarehouseLocationHistoryDiv';
import BarcodeCard from '../Barcode/BarcodeCard';
import PalletContents from '../Barcode/PalletContents';
import HistoryTypeModal from '../_GeneralLotComponents/HistoryTypeModal';
import EditPalletDrawer from './EditPalletDrawer';
import MoveCaseDrawer from './MoveCaseDrawer';
import MovePalletModal from './MovePalletModal';
import RTablePalletDetailsCases from '../../../componentsGeneral/Rsuite/Table/RTablePalletDetailsCases';
import MoveMultipleCaseDrawer from './MoveMultipleCaseDrawer';


const tableIdCol = "CaseID"



//For excel export
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
var currentDateTime = moment().format("_YYYY-MM-DDTHH-mm-ss")

const mountRef = React.createRef();

class PalletDetail extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            // Table Check Box
            checkedKeys: [],


            //Barcode
            showPrintableContent: false,
            showPrintableBarcode: false,

            //Edit Warehouse Location
            showEditSingleWarehouseLocationsDrawer: false,
            unsavedEditWarehouseLocationValues: false,
            LocationOriginalDict: {},
            LocationToEditDict: {},
            newWarehouseLocationID: null,
            newUnits: 0,
            newCases: 0,
            newPallets: 0,
            newOversize: 0,


            //Edit Po Values
            showEditSkuDetailsDrawer: false,
            unsavedEditSkuValues: false,
            // Po: null,
            CustomerRef: null,
            SKU: null,
            Project: null,
            // ExpectedCases: null,
            // ExpectedPallets: null,
            Notes: null,


            //Move Case 
            isMoveCaseDrawerOpen: false,
            CaseIDToMove: null,

            //Move Multiple Case
            isMoveMultipleCaseDrawerOpen: false,


            //Move Pallet
            movePalletModalShown: false,
            movePalletWarehouseLocationID: null,

            //History Type Modal
            historyTypeModalOpen: false,
            historyTypeID: null,


            //Edit Pallet
            showEditPalletDrawer: false,
            unsavedEditPalletValues: false,
            Notes: null,
            Oversize: null,
            Length: null,
            Width: null,
            CreateDT: null,
            RecoveryDateEdit: null,

        };

        // Table Check Box
        this.handleCheckAllCases = this.handleCheckAllCases.bind(this);
        this.handleCheckAll = this.handleCheckAll.bind(this);
        this.handleCheck = this.handleCheck.bind(this);

        // Table Sort
        this.handleSortColumn = this.handleSortColumn.bind(this);

        // Warehouse Location Details
        this.onClickWarehouseLocationDetails = this.onClickWarehouseLocationDetails.bind(this);

        this.onClickLoadDetails = this.onClickLoadDetails.bind(this);




        //Edit Warehouse Location
        this.onclickEditWarehouseLocation = this.onclickEditWarehouseLocation.bind(this);
        this.closeEditSingleWarehouseLocationsDrawer = this.closeEditSingleWarehouseLocationsDrawer.bind(this);
        this.setStateCloseEditSingleWarehouseLocationsDrawer = this.setStateCloseEditSingleWarehouseLocationsDrawer.bind(this);
        // this.onClickAddLocation = this.onClickAddLocation.bind(this);
        // this.handleAddNewLocation = this.handleAddNewLocation.bind(this);
        this.onClickSaveEditWarehouseLocation = this.onClickSaveEditWarehouseLocation.bind(this);


        //Edit Po Drawer
        this.closeEditSkuDrawer = this.closeEditSkuDrawer.bind(this);
        this.onClickConfirmEditSku = this.onClickConfirmEditSku.bind(this);
        this.setStateCloseAddNewDrawer = this.setStateCloseAddNewDrawer.bind(this)

        this.onChangeCustomerRef = this.onChangeCustomerRef.bind(this)
        this.onChangeSKU = this.onChangeSKU.bind(this)
        this.onChangeProject = this.onChangeProject.bind(this)
        this.onChangeNotes = this.onChangeNotes.bind(this)


        this.onClickSkuDetails = this.onClickSkuDetails.bind(this);
        this.onClickCaseDetails = this.onClickCaseDetails.bind(this);

        //Move Case
        this.onClickMoveCase = this.onClickMoveCase.bind(this);
        this.toggleMoveCaseDrawer = this.toggleMoveCaseDrawer.bind(this);
        this.toggleMoveMultipleCaseDrawer = this.toggleMoveMultipleCaseDrawer.bind(this);
        this.onClickSelectPalletMoveCase = this.onClickSelectPalletMoveCase.bind(this);
        this.onClickSelectPalletMoveMultipleCase = this.onClickSelectPalletMoveMultipleCase.bind(this);

        //Move Pallet
        this.movePalletModalShown = this.movePalletModalShown.bind(this);
        this.handleSavePalletModal = this.handleSavePalletModal.bind(this);
        this.handleClosePalletModal = this.handleClosePalletModal.bind(this);
        this.onChangePalletModal = this.onChangePalletModal.bind(this);

        //Close Pallet 
        this.onClickClosePallet = this.onClickClosePallet.bind(this);
        this.closePalletAction = this.closePalletAction.bind(this);


        this.onClickUnitDetails = this.onClickUnitDetails.bind(this);





        //Edit Pallet Drawer
        this.closeEditPalletDrawer = this.closeEditPalletDrawer.bind(this);
        this.onClickConfirmEditCase = this.onClickConfirmEditCase.bind(this);
        this.setStateCloseEditPalletDrawer = this.setStateCloseEditPalletDrawer.bind(this)
        this.onChangeEditPallet = this.onChangeEditPallet.bind(this)


    }




    componentDidMount() {
        this.loadSortFromStorage()
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.warehouseSelection !== this.props.warehouseSelection) {
            this.setState({
                checkedKeys: []
            });
        }
        if (prevProps.customerSelection !== this.props.customerSelection) {
            this.setState({
                checkedKeys: []
            });
        }


        if (prevProps.InventoryLotSkuData !== this.props.InventoryLotSkuData) {
            try {
                var skuWarehouseID = this.props.InventoryLotSkuData[0].WarehouseID
                var skuCustomerID = this.props.InventoryLotSkuData[0].CustomerID

                this.props.inventoryCustomerRefGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, skuWarehouseID, skuCustomerID)
                this.props.inventoryProjectGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, skuWarehouseID, skuCustomerID)
            }
            catch (e) { }
        }


        if (prevProps.InventorySkuPostSuccessful !== this.props.InventorySkuPostSuccessful && this.props.InventorySkuPostSuccessful) {
            this.setStateCloseAddNewDrawer()
        }

        if (prevProps.InventoryCaseWarehouseLocationPostSuccessful !== this.props.InventoryCaseWarehouseLocationPostSuccessful && this.props.InventoryCaseWarehouseLocationPostSuccessful) {

        }

        if (prevProps.InventoryPalletWarehouseLocationPostSuccessful !== this.props.InventoryPalletWarehouseLocationPostSuccessful && this.props.InventoryPalletWarehouseLocationPostSuccessful) {
            // window.location.reload();
        }




        if (prevProps.InventoryLotPalletEditPostSuccessful !== this.props.InventoryLotPalletEditPostSuccessful && this.props.InventoryLotPalletEditPostSuccessful) {
            this.setStateCloseEditPalletDrawer()
        }

    }



    // Table Check Box
    handleCheckAll(value, checked, data) {
        const checkedKeys = checked ? [] : data.map(item => item[tableIdCol]);
        this.setState({
            checkedKeys
        });
    }
    handleCheckAllCases(value, checked, data) {
        const checkedKeys = checked ? [] : data
            .filter(item => item.CaseStatusID == 1 || item.CaseStatusID == 2)
            .map(item => item[tableIdCol]);
        this.setState({
            checkedKeys
        });
    }
    handleCheck(value, checked) {
        const { checkedKeys } = this.state;
        const nextCheckedKeys = checked
            ? [...checkedKeys, value]
            : checkedKeys.filter(item => item !== value);

        this.setState({
            checkedKeys: nextCheckedKeys
        });
    }

    // Table Sort
    saveSortTosessionStorage(sortColumn, sortType) {
        try {
            sessionStorage.setItem('sortcolumn', sortColumn)
        }
        catch (e) { }
        try {
            sessionStorage.setItem('sortType', sortType)
        }
        catch (e) { }
        try {
            sessionStorage.setItem('sortURLPathname', this.props.location.pathname)
        }
        catch (e) { }
    }
    loadSortFromStorage() {
        var sortColumn = null
        var sortType = null
        var pathName = null
        try {
            sortColumn = sessionStorage.getItem('sortcolumn')
        }
        catch (e) { }
        try {
            sortType = sessionStorage.getItem('sortType')
        }
        catch (e) { }
        try {
            pathName = sessionStorage.getItem('sortURLPathname')
        }
        catch (e) { }
        if (pathName === this.props.location.pathname) {

            this.handleSortColumn(sortColumn, sortType)
        }
    }
    handleSortColumn(sortColumn, sortType) {
        this.setState({
            loading: true
        });
        this.saveSortTosessionStorage(sortColumn, sortType)
        setTimeout(() => {
            this.setState({
                sortColumn,
                sortType,
                loading: false
            });
        }, 100);
    }



    //Table buttons
    onClickWarehouseLocationDetails(e, rowData, history) {
        try {
            var paramID = rowData["WarehouseLocationID"]
            var paramWarehouseID = rowData["WarehouseID"]
            history.push({
                pathname: 'warehouselocationdetails',
                search: 'warehouselocationid=' + paramID + '&' +
                    'warehouseid=' + paramWarehouseID
            });
        }
        catch (e) {
        }
    }



    //On Click Details
    onClickLoadDetails(e, rowData, history) {
        try {
            var paramID = rowData["LoadID"]
            var paramWarehouseID = rowData["WarehouseID"]
            var paramCustomerID = rowData["CustomerID"]
            history.push({
                pathname: 'lotloaddetails',
                search: 'loadid=\'' + paramID + '\'' + '&' +
                    'warehouseid=' + paramWarehouseID + '&' +
                    'customerid=' + paramCustomerID
            });
        }
        catch (e) {
        }
    }

    onClickSkuDetails(e, rowData, history) {
        // try {
        //     var paramID = rowData["SkuID"]
        //     history.push({
        //         pathname: 'unitdetails',
        //         search: 'skuid=\'' + paramID
        //     });
        // }
        // catch (e) {
        // }
    }

    onClickCaseDetails(e, rowData, history) {
        try {
            var paramID = rowData["CaseID"]
            history.push({
                pathname: 'casedetails',
                search: 'caseid=\'' + paramID
            });
        }
        catch (e) {
        }
    }



    //Move Case
    toggleMoveCaseDrawer() {
        this.setState(prevState => ({
            isMoveCaseDrawerOpen: !prevState.isMoveCaseDrawerOpen,
        }));
    }


    onClickMoveCase(e, rowData, history) {
        this.setState({
            CaseIDToMove: rowData['CaseID']
        });

        const warehouseId = rowData['WarehouseID'];
        const customerID = rowData['CustomerID'];

        this.props.inventoryPalletWarehouseLocationGet2(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, warehouseId, customerID, "'1,2'", null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null)

        this.toggleMoveCaseDrawer();
    };


    onClickSelectPalletMoveCase(e, rowData, history) {
        if (window.confirm('Move case to this pallet?')) {
            var newPalletID = formatNumberForAPI(rowData['PalletID'])
            var oldPalletID = formatNumberForAPI(this.props.InventoryPalletWarehouseData[0].PalletID)
            var warehouseID = formatNumberForAPI(this.props.InventoryPalletWarehouseData[0].WarehouseID)
            var customerID = formatNumberForAPI(this.props.InventoryPalletWarehouseData[0].CustomerID)
            var caseIDtoMove = formatNumberForAPI(this.state.CaseIDToMove)
            if (this.props.InventoryPalletWarehouseData.length == 1) {
                if (this.props.InventoryPalletWarehouseData[0].CasesInWhse == 1) {
                    if (window.confirm('After this case is moved, nothing will remain on pallet.  Click OK to set pallet to closed, cancel to remain open.')) {
                        this.props.inventoryPalletWarehouseLocationPost(this.props.currentUserEmail, this.props.userSignInData.AuthToken, warehouseID, customerID, oldPalletID, null, null, 1, 5, null, null, null)
                    }
                }
                else {
                }
            }
            this.props.inventoryCaseWarehouseLocationPost(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, null, caseIDtoMove, null, newPalletID, null, null)

            this.setState({
                isMoveCaseDrawerOpen: false,
                isMoveMultipleCaseDrawerOpen: false,
                checkedKeys: []
            });
        }
    }

    //Move Multiple Case
    toggleMoveMultipleCaseDrawer() {
        this.setState(prevState => ({
            isMoveMultipleCaseDrawerOpen: !prevState.isMoveMultipleCaseDrawerOpen,
        }));
    }

    onClickMoveMultipleCase(e, rowData, history) {
        // this.setState({
        //     checkedKeys: []
        // });

        const warehouseId = this.props.InventoryPalletWarehouseData[0]['WarehouseID'];
        const customerID = this.props.InventoryPalletWarehouseData[0]['CustomerID'];

        this.props.inventoryPalletWarehouseLocationGet2(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, warehouseId, customerID, "'1,2'", null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null)

        this.toggleMoveMultipleCaseDrawer();
    };


    //move multiple cases
    onClickSelectPalletMoveMultipleCase(e, rowData, history) {
        if (window.confirm('Move selected cases to this pallet?')) {


            var newPalletID = formatNumberForAPI(rowData['PalletID'])
            var oldPalletID = formatNumberForAPI(this.props.InventoryPalletWarehouseData[0].PalletID)
            var warehouseID = formatNumberForAPI(this.props.InventoryPalletWarehouseData[0].WarehouseID)
            var customerID = formatNumberForAPI(this.props.InventoryPalletWarehouseData[0].CustomerID)

            const filteredTableData = this.props.InventoryCaseWarehouseData.filter(item => item.CaseStatusID == 1 || item.CaseStatusID == 2);

            if (this.state.checkedKeys.length === filteredTableData.length) {
                if (window.confirm('After this case is moved, nothing will remain on pallet.  Click OK to set pallet to closed, cancel to remain open.')) {
                    this.props.inventoryPalletWarehouseLocationPost(this.props.currentUserEmail, this.props.userSignInData.AuthToken, warehouseID, customerID, oldPalletID, null, null, 1, 5, null, null, null)
                }
            }
            else {
            }

            const checkedKeysString = this.state.checkedKeys.join(','); // Joins the array elements with a comma


            this.props.inventoryCaseWarehouseLocationPost(this.props.currentUserEmail, this.props.userSignInData.AuthToken, warehouseID, customerID, null, null, newPalletID, null, formatStringForAPI(checkedKeysString))


            this.setState({
                isMoveCaseDrawerOpen: false,
                isMoveMultipleCaseDrawerOpen: false,
                checkedKeys: []
            });
        }



    }



    //Move Pallet
    movePalletModalShown() {
        this.props.userWarehouseLocationsGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, formatNumberForAPI(this.props.InventoryPalletWarehouseData[0].WarehouseID), formatNumberForAPI(this.props.InventoryPalletWarehouseData[0].CustomerID));

        // This function could be used to control the visibility of the modal
        this.setState({ movePalletModalShown: true, movePalletWarehouseLocationID: null });
    }

    handleSavePalletModal() {

        if (this.state.movePalletWarehouseLocationID == null) {
            window.alert('Please select new location.')
        }
        else if (formatNumberForAPI(this.state.movePalletWarehouseLocationID) === formatNumberForAPI(this.props.InventoryPalletWarehouseData[0].WarehouseLocationID)) {
            window.alert('Pallet is already in this location.')
        }
        else {
            // This function could be used to handle the saving of data when the modal's save button is clicked
            // You would typically make an API call here to save your data
            this.props.inventoryPalletWarehouseLocationPost(this.props.currentUserEmail, this.props.userSignInData.AuthToken, formatNumberForAPI(this.props.InventoryPalletWarehouseData[0].WarehouseID), formatNumberForAPI(this.props.InventoryPalletWarehouseData[0].CustomerID), formatNumberForAPI(this.props.InventoryPalletWarehouseData[0].PalletID), null, formatNumberForAPI(this.state.movePalletWarehouseLocationID), null, null, null, null, null)

            // After saving the data, you can close the modal
            this.setState({ movePalletModalShown: false });
        }

    }

    handleClosePalletModal() {
        // This function could be used to handle the closing of the modal
        this.setState({ movePalletModalShown: false });
    }

    onChangePalletModal(e) {
        // This function could be used to handle changes in the form inputs in the modal
        // 'e' is the event object, you can get the value of the input like this: e.target.value
        // You would typically set the state here with the new value
        this.setState({ movePalletWarehouseLocationID: e });
    }


    //Warehouse Location Drawer Buttons
    onclickEditWarehouseLocation(e, rowData, history) {
        this.props.userWarehouseLocationsGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, rowData.WarehouseID, rowData.CustomerID);

        var origRowOversize = rowData['Oversize']

        this.setState({
            showEditSingleWarehouseLocationsDrawer: true,
            unsavedEditWarehouseLocationValues: false,

            LocationToEditDict: rowData,
            LocationOriginalDict: rowData,
            newWarehouseLocationID: null,
            newUnits: 0,
            newCases: 0,
            newPallets: 0,
            newOversize: origRowOversize
        });

    }





    // check if same location but oversize
    onChangeEditLocationDrawer = (event, label) => {
        if (label === 'newWarehouseLocationID') {
            // find new warehouse name in existing state:
            //This will be changed to dropdown choice:
            if (this.state.LocationOriginalDict['WarehouseLocationID'] === event) {
                if (this.state.LocationOriginalDict['Oversize'] === true) {
                    var newOversize = false
                }
                else {
                    var newOversize = true
                }
                this.setState({
                    newWarehouseLocationID: event,
                    newOversize: newOversize,
                    unsavedEditWarehouseLocationValues: true
                });
            }
            else {
                this.setState({
                    newWarehouseLocationID: event,
                    unsavedEditWarehouseLocationValues: true
                });
            }
        }
        else if (label === 'newOversize') {
            if (this.state.LocationOriginalDict['WarehouseLocationID'] === this.state.newWarehouseLocationID) {
                if (this.state.LocationOriginalDict['Oversize'] === true) {
                    var newOversize = false
                }
                else {
                    var newOversize = true
                }
                this.setState({
                    newOversize: newOversize,
                    unsavedEditWarehouseLocationValues: true
                });
            }
            else {
                this.setState({
                    newOversize: event,
                    unsavedEditWarehouseLocationValues: true
                });
            }
        }
        else {
            this.setState({
                [label]: event,
                unsavedEditWarehouseLocationValues: true
            });
        }
        this.forceUpdate()
    };


    // handleAddNewLocation = (newLocationDict) => {
    //     this.setState({
    //         unsavedEditWarehouseLocationValues: true,
    //         LocationDict: newLocationDict
    //     }, () => {
    //         // Do an API call with this.state.searchTerm
    //     });
    // }

    // onClickAddLocation = (event) => {
    //     //Check if any Warehouse names are null
    //     if (this.state.LocationDict[this.state.LocationToEditDict.SkuID].filter(item => item.WarehouseLocationID === null || item.WarehouseLocationID === '').length > 0) {
    //         window.alert('Please enter all location values first.')
    //     }
    //     else {
    //         //save warehouse name
    //         var currentLocationDict = this.state.LocationDict

    //         currentLocationDict[this.state.LocationToEditDict.SkuID].push({ 'WarehouseLocationID': null, 'Units': null, 'WarehouseID': null, 'Cases': null, 'Pallets': null, 'Oversize': null })

    //         this.handleAddNewLocation(currentLocationDict)

    //         this.forceUpdate()
    //     }
    // }



    onClickSaveEditWarehouseLocation(e) {

        try {
            var warehouseID = this.state.LocationOriginalDict.WarehouseID
            var customerID = this.state.LocationOriginalDict.CustomerID

            var skuID = this.state.LocationOriginalDict.SkuID

            var originalLocationWarehouseID = formatNumberForAPI(this.state.LocationOriginalDict.WarehouseLocationID)
            var originalLocationSkuWarehouseLocID = formatNumberForAPI(this.state.LocationOriginalDict.SkuWarehouseLocationID)
            var originalLocationUnits = convertNanToZero(this.state.LocationOriginalDict.Units) - convertNanToZero(this.state.newUnits)
            var originalLocationCases = convertNanToZero(this.state.LocationOriginalDict.Cases) - convertNanToZero(this.state.newCases)
            var originalLocationPallets = convertNanToZero(this.state.LocationOriginalDict.Pallets) - convertNanToZero(this.state.newPallets)
            var originalLocationOversize = formatTrueFalseToBit(this.state.LocationOriginalDict.Oversize)

            var newLocationID = formatNumberForAPI(this.state.newWarehouseLocationID)
            var newLocationOversize = formatTrueFalseToBit(this.state.newOversize)
            var newLocationUnits = convertNanToZero(this.state.newUnits)
            var newLocationCases = convertNanToZero(this.state.newCases)
            var newLocationPallets = convertNanToZero(this.state.newPallets)

            if ((newLocationUnits === null || newLocationUnits == 0) && (newLocationCases === null || newLocationCases == 0) && (newLocationPallets === null || newLocationPallets == 0)) {
                window.alert('Please select Units, Cases, Pallets to move.')
            }
            else if (newLocationID === null) {
                window.alert('Please select new location.')
            }
            else {
                try {
                    // call Inventory_SKU_Warehouse_Location_Post twice
                    // first time call with old location and send new totals for old location
                    this.props.inventorySkuWarehouseLocationPost(
                        this.props.currentUserEmail,
                        this.props.userSignInData.AuthToken,
                        skuID,
                        originalLocationSkuWarehouseLocID,
                        originalLocationWarehouseID,
                        convertNanToZero(originalLocationUnits),
                        convertNanToZero(originalLocationCases),
                        convertNanToZero(originalLocationPallets),
                        originalLocationOversize,
                        originalLocationWarehouseID,
                        warehouseID,
                        customerID,
                        null,
                        null,
                        null,
                        null
                    )
                    // second time call with new location and new counts
                    this.props.inventorySkuWarehouseLocationPost(
                        this.props.currentUserEmail,
                        this.props.userSignInData.AuthToken,
                        skuID,
                        null,
                        newLocationID,
                        convertNanToZero(newLocationUnits),
                        convertNanToZero(newLocationCases),
                        convertNanToZero(newLocationPallets),
                        newLocationOversize,
                        originalLocationWarehouseID,
                        warehouseID,
                        customerID,
                        null,
                        null,
                        null,
                        null
                    )

                    this.setStateCloseEditSingleWarehouseLocationsDrawer()
                }
                catch (e) { }
            }
        }
        catch (e) {

        }


    }
    closeEditSingleWarehouseLocationsDrawer() {
        if (this.state.unsavedEditWarehouseLocationValues) {
            if (window.confirm('Leave without saving changes?')) {
                this.setStateCloseEditSingleWarehouseLocationsDrawer()
                window.location.reload();
            }
        }
        else {
            this.setStateCloseEditSingleWarehouseLocationsDrawer()
        }
    }
    setStateCloseEditSingleWarehouseLocationsDrawer() {
        this.setState({
            showEditSingleWarehouseLocationsDrawer: false,
            unsavedEditWarehouseLocationValues: false,
            LocationOriginalDict: {},
            LocationToEditDict: {},
            newWarehouseLocationID: null,
            newUnits: 0,
            newCases: 0,
            newPallets: 0,
            newOversize: 0,

        });
    }





    //Edit Po Drawer
    onClickEditSku() {
        this.openEditSku()
        this.setState({
            // Po: this.props.InventoryLotSkuData[0].PO,

            CustomerRef: this.props.InventoryPalletWarehouseData[0].CustomerRefID,
            SKU: this.props.InventoryPalletWarehouseData[0].SKU,
            Project: this.props.InventoryPalletWarehouseData[0].ProjectID,
            // ExpectedCases: this.props.InventoryLotSkuData[0].CaseCount,
            // ExpectedPallets: this.props.InventoryLotSkuData[0].PalletCount,
            Notes: this.props.InventoryPalletWarehouseData[0].Notes
        });
    }
    openEditSku(placement) {
        this.setState({
            placement,
            showEditSkuDetailsDrawer: true,
            // unsavedEditSkuValues: true,
        });
    }

    onChangeNotes = (e) => {
        this.setState({
            unsavedEditSkuValues: true,
            Notes: e
        });
    }

    // onChangeCustomerRef = (e, value, item) => {
    //     this.setState({
    //         CustomerRef: e
    //     });
    // }
    // onChangeProject = (e, value, item) => {
    //     this.setState({
    //         Project: e
    //     });
    // }
    onChangeSKU = (e) => {
        this.setState({
            unsavedEditSkuValues: true,
            SKU: e
        });
    }


    // onChangeEditPo = (event, field) => {
    //     var dict = {};
    //     dict[field] = event;
    //     this.setState(
    //         dict
    //     );
    //     this.setState({
    //         unsavedEditSkuValues: true,
    //     });
    // };

    onChangeCustomerRef = (e, value, item) => {
        this.setState({
            unsavedEditSkuValues: true,
            CustomerRef: e
        });
    }

    onChangeProject = (e, value, item) => {
        this.setState({
            unsavedEditSkuValues: true,
            Project: e
        });
    }

    onClickConfirmEditSku(e) {

        this.props.inventorySkuPost(
            this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.InventoryLotSkuData[0].SkuID, formatStringForAPI(this.state.SKU), null, null, null, null, null, formatStringForAPI(this.state.CustomerRef), null, formatStringForAPI(this.state.Project), formatAllTextNumbersSymbolsForAPI(this.state.Notes)
        )


    }
    closeEditSkuDrawer() {
        if (this.state.unsavedEditSkuValues) {
            if (window.confirm('Leave without saving changes?')) {
                this.setStateCloseAddNewDrawer()
                window.location.reload();
            }
        }
        else {
            this.setStateCloseAddNewDrawer()
        }
    }
    setStateCloseAddNewDrawer() {
        this.setState({
            showEditSkuDetailsDrawer: false,
            unsavedEditSkuValues: false,

            Po: null,
            CustomerRef: null,
            Project: null,
            ExpectedCases: null,
            ExpectedPallets: null,
            Notes: null,
        });
    }









    onPrintContent = () => {
        this.setState({ showPrintableContent: true }, () => {
            // window.print();
            // var container = document.getElementById("div-svg");
            // var mySVG = document.getElementById("barcode-canvas");
            // var width = "100%";
            // var height = "100%";
            // var printWindow = window.open('', 'PrintMap',
            //     'width=' + width + ',height=' + height);
            // printWindow.document.writeln(container.innerHTML);
            // printWindow.document.close();
            // printWindow.print();
            // printWindow.close();

            var container = document.getElementById("div-svg");
            var mySVG = document.getElementById("barcode-canvas");
            var printWindow = window.open('', 'PrintMap',
                'width=' + window.innerWidth + ',height=' + window.innerHeight);
            printWindow.document.writeln(container.innerHTML);
            printWindow.document.close();
            printWindow.print();
            printWindow.close();



            this.setState({ showPrintableContent: false });


        });


        // var container = document.getElementById("div-svg");
        // var mySVG = document.getElementById("barcode-canvas");
        // var width = "100%";
        // var height = "100%";
        // var printWindow = window.open('', 'PrintMap',
        //     'width=' + width + ',height=' + height);
        // printWindow.document.writeln(container.innerHTML);
        // printWindow.document.close();
        // printWindow.print();
        // printWindow.close();
    }




    onPrintBarcode = () => {

        this.setState({ showPrintableBarcode: true }, () => {

            // var container = document.getElementById("div-svg");
            // var mySVG = document.getElementById("barcode-canvas");
            // var width = "100%";
            // var height = "100%";
            // var printWindow = window.open('', 'PrintMap',
            //     'width=' + width + ',height=' + height);
            // printWindow.document.writeln(container.innerHTML);
            // printWindow.document.close();
            // printWindow.print();
            // printWindow.close();

            // var container = document.getElementById("div-svg");
            // var svgElements = container.getElementsByTagName("svg");

            // // Scale SVGs to fit their containers
            // for (let i = 0; i < svgElements.length; i++) {
            //     let svg = svgElements[i];
            //     svg.setAttribute("width", "100%");
            //     svg.setAttribute("height", "100%");
            // }

            // var printWindow = window.open('', 'PrintMap',
            //     'width=100%,height=100%');
            // printWindow.document.writeln(container.innerHTML);
            // printWindow.document.close();
            // printWindow.print();
            // printWindow.close();



            // var container = document.getElementById("div-svg");
            // var mySVG = document.getElementById("barcode-canvas");
            // var width = "100%";
            // var height = "100%";
            // var printWindow = window.open('', 'PrintMap',
            //     'width=' + width + ',height=' + height);
            // printWindow.document.writeln(container.innerHTML);
            // printWindow.document.close();
            // printWindow.print();
            // printWindow.close();


            var container = document.getElementById("div-svg");
            var printWindow = window.open('', 'PrintMap', 'width=' + window.innerWidth + ',height=' + window.innerHeight);
            printWindow.document.writeln(container.innerHTML);
            printWindow.document.close();
            printWindow.print();
            printWindow.close();


            this.setState({ showPrintableBarcode: false });


        });

    }


    //Close Pallet
    onClickClosePallet(e) {
        if (window.confirm('Confirm Close Pallet?')) {
            this.closePalletAction()
        }
    }


    closePalletAction() {
        this.props.inventoryPalletWarehouseLocationPost(this.props.currentUserEmail, this.props.userSignInData.AuthToken, formatNumberForAPI(this.props.InventoryPalletWarehouseData[0].WarehouseID), formatNumberForAPI(this.props.InventoryPalletWarehouseData[0].CustomerID), formatNumberForAPI(this.props.InventoryPalletWarehouseData[0].PalletID), null, null, 1, 4, null, null, null)

    }





    //save customer selection
    saveCustomerSelectionTosessionStorage(customerToSave) {
        try {
            sessionStorage.setItem('currentCustomer', customerToSave)
        }
        catch (e) {

        }
    }


    onClickUnitDetails(e, rowData, history) {

        var newCustID = rowData.CustomerID
        this.saveCustomerSelectionTosessionStorage(newCustID)

        var newSku = rowData.SKU
        var newLotCode = rowData.LotCode
        var newSerial = rowData.SerialNumber
        var selectedWarehouseID = rowData.WarehouseLocationID
        var selectedStatusID = rowData.SkuStatusID

        let searchParams = new URLSearchParams(this.props.location.search);
        searchParams.set('warehouselocationid', selectedWarehouseID);
        searchParams.set('unitsku', newSku);
        searchParams.set('unitlotcode', newLotCode);
        searchParams.set('unitserial', newSerial);
        searchParams.set('reportnav', 'sku');
        searchParams.set('statusid', selectedStatusID);
        // searchParams.set('unitsku', this.props.InventorySkuRefData[0].SKU.toString());
        // searchParams.set('caseid', this.props.InventoryCaseWarehouseData[0].CaseID);
        searchParams.set('palletid', this.props.InventoryPalletWarehouseData[0].PalletID);
        searchParams.set('filtershown', false);

        let newSearchString = searchParams.toString();

        try {
            history.push({
                pathname: 'inventorypcs',
                search: newSearchString
            });

            // window.location.reload();
        }
        catch (e) {
        }
    }


    //History Type Modal
    handleModalOpen = () => {

        this.setState({ historyTypeModalOpen: true });

    };

    handleModalClose = () => {
        this.setState({ historyTypeModalOpen: false });
    };

    handleModalSelect = (value) => {
        if (window.confirm('Are you sure you want to delete this pallet?  This will also remove contents from inventory.')) {
            this.props.inventoryLotDeleteContainerPalletCaseUnitPost(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection, value, null, formatStringForAPI(this.props.InventoryPalletWarehouseData[0].PalletID), null, null, null, null, null)
        }
    };

    onClickInventoryDelete = (e) => {
        this.handleModalOpen();
    };





    //Edit Pallet Drawer
    onClickEditPallet() {
        this.setState({
            Notes: this.props.InventoryPalletWarehouseData[0].Notes,
            Oversize: this.props.InventoryPalletWarehouseData[0].Oversize,
            Length: this.props.InventoryPalletWarehouseData[0].Length,
            Width: this.props.InventoryPalletWarehouseData[0].Width,
            CreateDT: this.props.InventoryPalletWarehouseData[0].CreateDT,
            RecoveryDateEdit: null,

            showEditPalletDrawer: true,
        });

        // this.openEditSku()
    }
    openEditSku(placement) {
        this.setState({
            placement,
            showEditPalletDrawer: true,

        });
    }


    onChangeEditPallet = (event, label) => {
        this.setState({
            [label]: event,
            unsavedEditPalletValues: true
        });
    }

    onClickConfirmEditCase(e) {
        this.props.inventoryLotPalletRefEditPost(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.InventoryPalletWarehouseData[0].PalletID, null, formatStringForAPI(this.state.Notes), formatTrueFalseToBit(this.state.Oversize), formatNumberForAPI(this.state.Length), formatNumberForAPI(this.state.Width), formatDateTimeForUrlSearch(this.state.RecoveryDateEdit))
    }
    closeEditPalletDrawer() {
        if (this.state.unsavedEditPalletValues) {
            if (window.confirm('Leave without saving changes?')) {
                this.setStateCloseEditPalletDrawer()
                // window.location.reload();
            }
        }
        else {
            this.setStateCloseEditPalletDrawer()
        }
    }
    setStateCloseEditPalletDrawer() {
        this.setState({
            showEditPalletDrawer: false,
            unsavedEditPalletValues: false,

            Notes: null,
            Oversize: null,
            Length: null,
            Width: null,
            CreateDT: null, 
            RecoveryDateEdit: null
        });
    }





    render() {

        const { checkedKeys, sortColumn, sortType } = this.state;
        const { showPrintableContent, showPrintableBarcode } = this.state;



        let unitDiv;

        if (this.props.isInventorySkuWarehouseGroupedLoading) {
            unitDiv = <div align="center">
                <section className="panel">
                </section>
                <Loader
                    type="TailSpin"
                    color="#3396E5"
                    height="50"
                    width="50"
                />
            </div>

        }

        else if (typeof (this.props.InventorySkuWarehouseGroupedData[0]) !== 'undefined' && this.props.InventorySkuWarehouseGroupedData != null) {
            var CasesInWhse = this.props.InventorySkuWarehouseGroupedData.map(({ CasesInWhse }) => CasesInWhse).reduce((a, b) => a + b, 0);
            var CasesLoaded = this.props.InventorySkuWarehouseGroupedData.map(({ CasesLoaded }) => CasesLoaded).reduce((a, b) => a + b, 0);
            var CasesReceived = this.props.InventorySkuWarehouseGroupedData.map(({ CasesReceived }) => CasesReceived).reduce((a, b) => a + b, 0);

            var UnitsInWhse = this.props.InventorySkuWarehouseGroupedData.map(({ UnitsInWhse }) => UnitsInWhse).reduce((a, b) => a + b, 0);
            var UnitsLoaded = this.props.InventorySkuWarehouseGroupedData.map(({ UnitsLoaded }) => UnitsLoaded).reduce((a, b) => a + b, 0);
            var UnitsRecovered = this.props.InventorySkuWarehouseGroupedData.map(({ UnitsRecovered }) => UnitsRecovered).reduce((a, b) => a + b, 0);



            unitDiv =
                <div>
                    <Row>

                        {/* <Col md={6} sm={24}>
                            <Panel bordered style={{ marginBottom: 10 }}>
                                Units Expected: <strong>{this.props.InventorySkuWarehouseGroupedData[0].UnitsExpected}</strong>
                                <br />
                                Cases Expected: <strong>{this.props.InventorySkuWarehouseGroupedData[0].CasesExpected}</strong>
                                <br />
                                Pallets Expected: <strong>{this.props.InventorySkuWarehouseGroupedData[0].PalletsExpected}</strong>
                                <br />
                            </Panel>
                        </Col> */}
                        <Col md={8} sm={24}>
                            <Panel bordered style={{ marginBottom: 10 }}>
                                Units Received: <strong>{UnitsRecovered}</strong>
                                <br />
                                Cases Received: <strong>{CasesReceived}</strong>
                                <br />
                                {/* Pallets Received: <strong>{this.props.InventorySkuWarehouseGroupedData[0].PalletsReceived}</strong> */}
                                {/* <br /> */}
                            </Panel>
                        </Col>
                        <Col md={8} sm={24}>
                            <Panel bordered style={{ marginBottom: 10 }}>
                                Units In Whse: <strong>{UnitsInWhse}</strong>
                                <br />
                                Cases In Whse: <strong>{CasesInWhse}</strong>
                                <br />
                                {/* Pallets In Whse: <strong>{this.props.InventorySkuWarehouseGroupedData[0].Pallets}</strong> */}
                                {/* <br /> */}
                            </Panel>
                        </Col>
                        <Col md={8} sm={24}>
                            <Panel bordered style={{ marginBottom: 10 }}>
                                Units Shipped: <strong>{UnitsLoaded}</strong>
                                <br />
                                Cases Shipped: <strong>{CasesLoaded}</strong>
                                <br />
                                {/* Pallets Shipped: <strong>{this.props.InventorySkuWarehouseGroupedData[0].PalletsLoaded}</strong> */}
                                {/* <br /> */}
                            </Panel>
                        </Col>

                    </Row>
                </div>



        }
        else {
            unitDiv = <div></div>
        }




        // let skuDetailsDiv;
        // let pageHeader;
        // if (this.props.isInventoryLotSkuLoading) {
        //     pageHeader = <div>Inventory Management - SKU</div>

        //     skuDetailsDiv = <div align="center">
        //         <section className="panel">
        //         </section>
        //         <Loader
        //             type="TailSpin"
        //             color="#3396E5"
        //             height="50"
        //             width="50"
        //         />
        //     </div>

        // }
        // else if (typeof (this.props.InventoryLotSkuData[0]) !== 'undefined' && this.props.InventoryLotSkuData != null) {
        //     try {
        //         var skuName = this.props.InventoryLotSkuData[0].SKU.toString()
        //         pageHeader = <div style={{ marginBottom: '15px' }}>  {'Inventory Management - SKU: ' + skuName} </div>
        //     }
        //     catch (e) {
        //         pageHeader = <div>Inventory Management - SKU</div>
        //     }

        //     skuDetailsDiv =
        //         <div>
        //             <Row >
        //                 <Col md={24} sm={24}>
        //                     <Panel bordered style={{ marginBottom: 10 }} header={
        //                         <div style={{ float: 'right' }}>
        //                             <Button style={{ float: 'right' }} onClick={(e) => { this.onClickEditSku(e) }} >Edit</Button>
        //                         </div>
        //                     }>





        //                         Warehouse: <strong>{this.props.InventoryLotSkuData[0].WarehouseName}</strong>
        //                         <br />
        //                         Customer: <strong>{this.props.InventoryLotSkuData[0].CustomerName}</strong>
        //                         <br />
        //                         Customer Ref: <strong>{this.props.InventoryLotSkuData[0].CustomerRef}</strong>
        //                         <br />
        //                         Project: <strong>{this.props.InventoryLotSkuData[0].Project}</strong>
        //                         <br />
        //                         Status: <strong>{this.props.InventoryLotSkuData[0].SkuStatusName}</strong>
        //                         <br />
        //                         Notes: <strong>{this.props.InventoryLotSkuData[0].Notes}</strong>
        //                         <br />
        //                     </Panel>
        //                 </Col>
        //             </Row>
        //             <Row>

        //             InventorySkuWarehouseGroupedData

        //                 {/* <Col md={6} sm={24}>
        //                     <Panel bordered style={{ marginBottom: 10 }}>
        //                         Units Expected: <strong>{this.props.InventoryLotSkuData[0].UnitsExpected}</strong>
        //                         <br />
        //                         Cases Expected: <strong>{this.props.InventoryLotSkuData[0].CasesExpected}</strong>
        //                         <br />
        //                         Pallets Expected: <strong>{this.props.InventoryLotSkuData[0].PalletsExpected}</strong>
        //                         <br />
        //                     </Panel>
        //                 </Col> */}
        //                 <Col md={8} sm={24}>
        //                     <Panel bordered style={{ marginBottom: 10 }}>
        //                         Units Received: <strong>{this.props.InventoryLotSkuData[0].UnitsReceived}</strong>
        //                         <br />
        //                         Cases Received: <strong>{this.props.InventoryLotSkuData[0].CasesReceived}</strong>
        //                         <br />
        //                         Pallets Received: <strong>{this.props.InventoryLotSkuData[0].PalletsReceived}</strong>
        //                         <br />
        //                     </Panel>
        //                 </Col>
        //                 <Col md={8} sm={24}>
        //                     <Panel bordered style={{ marginBottom: 10 }}>
        //                         Units In Whse: <strong>{this.props.InventoryLotSkuData[0].Units}</strong>
        //                         <br />
        //                         Cases In Whse: <strong>{this.props.InventoryLotSkuData[0].Cases}</strong>
        //                         <br />
        //                         Pallets In Whse: <strong>{this.props.InventoryLotSkuData[0].Pallets}</strong>
        //                         <br />
        //                     </Panel>
        //                 </Col>
        //                 <Col md={8} sm={24}>
        //                     <Panel bordered style={{ marginBottom: 10 }}>
        //                         Units Shipped: <strong>{this.props.InventoryLotSkuData[0].UnitsLoaded}</strong>
        //                         <br />
        //                         Cases Shipped: <strong>{this.props.InventoryLotSkuData[0].CasesLoaded}</strong>
        //                         <br />
        //                         Pallets Shipped: <strong>{this.props.InventoryLotSkuData[0].PalletsLoaded}</strong>
        //                         <br />
        //                     </Panel>
        //                 </Col>

        //             </Row>
        //         </div>



        // }
        // else {
        //     pageHeader = <div>Inventory Management - SKU</div>
        //     skuDetailsDiv = <div></div>
        // }


        // let shipmentPoTable;
        // let tableDataDiv;
        // if (this.props.isInventorySkuWarehouseLocationLoading) {
        //     shipmentPoTable = <div></div>
        //     tableDataDiv =
        //         <div align="center">
        //             <section className="panel">
        //             </section>
        //             <Loader
        //                 type="TailSpin"
        //                 color="#3396E5"
        //                 height="50"
        //                 width="50"
        //             />
        //         </div>
        // }
        // else if (typeof (this.props.InventorySkuWarehouseLocationData) !== 'undefined' && this.props.InventorySkuWarehouseLocationData != null) {
        //     shipmentPoTable = RTableGeneral(
        //         "SkuWarehouseLocationID",     //   keyColumn,
        //         this.props.InventorySkuWarehouseLocationData,     //   tableData,
        //         InventorySkuWarehouseLocationDataElements,       //   DataElements,
        //         this.props.isInventorySkuWarehouseLocationLoading,        //   loading,

        //         this.props.isMobile,        //   isMobile,
        //         this.props.history,     //   history,

        //         sortColumn,     //   sortColumn,
        //         sortType,       //   sortType,
        //         this.handleSortColumn,      //   handleSortColumn,

        //         false, // checkColEntry,
        //         null,       //   checkedKeys,
        //         null,       //   handleCheckAll,
        //         null,       //   handleCheck,

        //         false,      //   boolSelectButton,
        //         null,       //   onClickSelect,
        //         null,       //   selectButtonText,
        //         null,       //   selectHighlightRowKey,

        //         true,       //   link1bool,
        //         'Details',     //   link1text,
        //         this.onClickWarehouseLocationDetails,     //   link1onClick,

        //         true,       //   link2bool,
        //         'Edit',     //   link2text,
        //         this.onclickEditWarehouseLocation,     //   link2onClick,
        //     )

        //     tableDataDiv = <Row>
        //         <ExcelFile element={<Button style={{ float: 'right' }}>Export Excel</Button>} filename={'Warehouse_' + currentDateTime}>
        //             <ExcelSheet data={this.props.InventorySkuWarehouseLocationData} name="Warehouse">
        //                 {
        //                     InventorySkuWarehouseLocationDataElements.map(
        //                         ({ dBKey, displayName }, idx) => {
        //                             return (
        //                                 <ExcelColumn label={displayName} value={dBKey} />
        //                             );
        //                         }
        //                     )
        //                 }
        //             </ExcelSheet>
        //         </ExcelFile>
        //     </Row>
        // }
        // else {
        //     shipmentPoTable = <div></div>
        //     tableDataDiv = <div></div>
        // }







        let skuRecoveryTimeline;
        if (this.props.isInventorySkuRecoveryHistoryLoading) {
            skuRecoveryTimeline =
                <div align="center">
                    <section className="panel">
                    </section>
                    <Loader
                        type="TailSpin"
                        color="#3396E5"
                        height="50"
                        width="50"
                    />
                </div>
        }
        else if (typeof (this.props.InventorySkuRecoveryHistoryData[0]) !== 'undefined' && this.props.InventorySkuRecoveryHistoryData != null) {
            skuRecoveryTimeline =
                <div>
                    <Row >
                        <Col md={24} sm={24}>
                            <Panel bordered header="SKU Recovery History" style={{ marginBottom: 10 }}>
                                <Timeline>
                                    {this.props.InventorySkuRecoveryHistoryData.map(item => (
                                        <Timeline.Item>
                                            {item.SkuRecoveryDT}  &nbsp;
                                            {item.EmailAddr}
                                            <l></l>
                                            {/* {item.SKU != null ? <l><br /> SKU:  {item.SKU} </l> : ' '} */}
                                            {item.Container != null ? <l><br /> Received From Container:  {item.Container} </l> : ' '}
                                            {item.UnitsReceived != null ? <l><br /> Units Received:  {item.UnitsReceived} </l> : ' '}
                                            {item.CasesReceived != null ? <l><br /> Cases Received:  {item.CasesReceived} </l> : ' '}
                                            {item.PalletsReceived != null ? <l><br /> Pallets Received:  {item.PalletsReceived} </l> : ' '}
                                            {/* {item.Oversize != null ? <l><br /> Oversize:  {item.Oversize === true ? "True" : "False"} </l> : ' '} */}
                                            {item.SkuDescription != null ? <l><br /> Description:  {item.SkuDescription} </l> : ' '}

                                        </Timeline.Item>
                                    ))}
                                </Timeline>

                            </Panel>
                        </Col>
                    </Row>

                </div>
        }
        else {
            skuRecoveryTimeline = <div></div>
        }



        let skuWarehouseLocationHistoryTimeline;
        if (this.props.isInventorySkuWarehouseLocationHistoryLoading) {
            skuWarehouseLocationHistoryTimeline =
                <div align="center">
                    <section className="panel">
                    </section>
                    <Loader
                        type="TailSpin"
                        color="#3396E5"
                        height="50"
                        width="50"
                    />
                </div>
        }
        else if (typeof (this.props.InventorySkuWarehouseLocationHistoryData[0]) !== 'undefined' && this.props.InventorySkuWarehouseLocationHistoryData != null) {
            skuWarehouseLocationHistoryTimeline =
                <div>
                    <Row >
                        <Col md={24} sm={24}>
                            <Panel bordered header="Warehouse Location History" style={{ marginBottom: 10 }}>
                                <Timeline>
                                    {this.props.InventorySkuWarehouseLocationHistoryData.map(item => (
                                        <Timeline.Item>
                                            {item.CreatedDT}  &nbsp;
                                            {item.EmailAddr}
                                            <l><br />{item.Notes} &nbsp;
                                            </l>
                                            {item.WarehouseLocationName != null ? <l><br /> Warehouse Location:  {item.WarehouseLocationName} </l> : ' '}
                                            {item.PreviousWarehouseLocationName != null ? <l><br /> Previous Warehouse Location:  {item.PreviousWarehouseLocationName} </l> : ' '}
                                            {item.Units != null ? <l><br /> Units:  {item.Units} </l> : ' '}
                                            {item.Cases != null ? <l><br /> Cases:  {item.Cases} </l> : ' '}
                                            {item.Pallets != null ? <l><br /> Pallets:  {item.Pallets} </l> : ' '}
                                            {item.Oversize != null ? <l><br /> Oversize:  {item.Oversize === true ? "True" : "False"} </l> : ' '}


                                        </Timeline.Item>
                                    ))}
                                </Timeline>

                            </Panel>
                        </Col>
                    </Row>

                </div>
        }
        else {
            skuWarehouseLocationHistoryTimeline = <div></div>
        }




        let loadDiv;
        let rTable;
        let palletLoadDiv;
        if (this.props.isInventoryLoadLotLoading) {
            // rTable = <div></div>
            palletLoadDiv =
                <div align="center">
                    <section className="panel">
                    </section>
                    <Loader
                        type="TailSpin"
                        color="#3396E5"
                        height="50"
                        width="50"
                    />
                </div>
        }
        else if (typeof (this.props.InventoryLoadLotData) !== 'undefined' && this.props.InventoryLoadLotData != null) {

            if (this.props.InventoryLoadLotData.length === 0) {
                palletLoadDiv = <div></div>
            }
            else {

                // let rTable;
                rTable = RTableGeneral(
                    'LoadID',     //   keyColumn,
                    this.props.InventoryLoadLotData,     //   tableData,
                    InventoryLoadSkuDetailsDataElements,       //   DataElements,
                    this.props.isInventoryLoadLotLoading,        //   loading,

                    this.props.isMobile,        //   isMobile,
                    this.props.history,     //   history,

                    sortColumn,     //   sortColumn,
                    sortType,       //   sortType,
                    this.handleSortColumn,      //   handleSortColumn,

                    false, // checkColEntry,
                    null,       //   checkedKeys,
                    null,       //   handleCheckAll,
                    null,       //   handleCheck,

                    false,      //   boolSelectButton,
                    null,       //   onClickSelect,
                    null,       //   selectButtonText,
                    null,       //   selectHighlightRowKey,

                    true,       //   link1bool,
                    'Details',     //   link1text,
                    this.onClickLoadDetails,     //   link1onClick,

                    false,       //   link2bool,
                    null,       //   link2text,
                    null  //   link2onClick
                )

                loadDiv = <div>


                    {(this.props.isMobile == true) ? <div></div> :
                        <div>
                            <Row>
                                <ExcelFile element={<Button style={{ float: 'right' }}>Export Excel</Button>} filename={'Load_' + currentDateTime}>
                                    <ExcelSheet data={this.props.InventoryLoadLotData} name="Load">

                                        {
                                            InventoryLoadSkuDetailsDataElements.map(
                                                ({ dBKey, displayName }, idx) => {
                                                    return (
                                                        <ExcelColumn label={displayName} value={dBKey} />
                                                    );
                                                }
                                            )
                                        }

                                    </ExcelSheet>
                                </ExcelFile>
                            </Row>
                        </div>
                    }

                </div>


                palletLoadDiv = <Row >
                    <Col md={24} sm={24}>
                        <Panel bordered header="Loads" style={{ marginBottom: 10 }}>
                            {loadDiv}
                            {rTable}
                        </Panel>
                    </Col>
                </Row>


            }



        }
        else {
            palletLoadDiv = <div></div>
        }



        // let editWarehouseLocationDrawerDiv;
        // let unsavedLocationChanges
        // if (!this.state.showEditSingleWarehouseLocationsDrawer) {
        //     editWarehouseLocationDrawerDiv = <div></div>
        //     unsavedLocationChanges = false
        // }
        // else {
        //     unsavedLocationChanges = this.state.unsavedEditWarehouseLocationValues
        //     editWarehouseLocationDrawerDiv = <EditSingleWarehouseLocationsDrawer {...this.props} state={this.state} onChangeLocation={this.onChangeEditLocationDrawer} onClickAddLocation={this.onClickAddLocation} confirm={this.onClickSaveEditWarehouseLocation} close={this.closeEditSingleWarehouseLocationsDrawer} unsavedChanges={unsavedLocationChanges} />
        // }






        let countOfCases = 1;
        // let shipmentSkuTable;
        // let tableSkuDataDiv;
        if (this.props.isInventorySkuWarehouseLoading) {
            countOfCases = 1
            // shipmentSkuTable = <div></div>
            // tableSkuDataDiv =
            //     <div align="center">
            //         <section className="panel">
            //         </section>
            //         <Loader
            //             type="TailSpin"
            //             color="#3396E5"
            //             height="50"
            //             width="50"
            //         />
            //     </div>
        }
        else if (typeof (this.props.InventorySkuWarehouseData) !== 'undefined' && this.props.InventorySkuWarehouseData != null) {
            countOfCases = this.props.InventorySkuWarehouseData.length

            // const values = queryString.parse(this.props.location.search)
            // try {
            //     var paramPoStatusID = values.statusid.split(',').map(Number);
            // }
            // catch (e) {
            //     var paramPoStatusID = []
            // }
            // shipmentSkuTable = RTableGeneral(
            //     'CaseSkuID',     //   keyColumn,
            //     this.props.InventorySkuWarehouseData,     //   tableData,
            //     InventorySkuWarehouseDataElements,       //   DataElements,
            //     this.props.isInventorySkuWarehouseLoading,        //   loading,

            //     this.props.isMobile,        //   isMobile,
            //     this.props.history,     //   history,

            //     sortColumn,     //   sortColumn,
            //     sortType,       //   sortType,
            //     this.handleSortColumn,      //   handleSortColumn,
            //     false, // checkColEntry,
            //     null,       //   checkedKeys,
            //     null,       //   handleCheckAll,
            //     null,       //   handleCheck,

            //     false,      //   boolSelectButton,
            //     null,       //   onClickSelect,
            //     null,       //   selectButtonText,
            //     null,       //   selectHighlightRowKey,

            //     true,       //   link1bool,
            //     'Details',     //   link1text,
            //     this.onClickSkuDetails,     //   link1onClick,

            //     false,       //   link2bool,
            //     null,       //   link2text,
            //     null  //   link2onClick
            // )
            // tableSkuDataDiv = <div>
            //     {(this.props.isMobile == true) ? <div></div> :
            //         <div>
            //             <Row>
            //                 {/* {paramPoStatusID.includes(1) || paramPoStatusID.includes(2) || paramPoStatusID == null ? <Button style={{ float: 'left' }} onClick={(e) => { this.onClickBuildLoad(e) }}>Build Load Selected</Button> : ''} */}
            //                 <ExcelFile element={<Button style={{ float: 'right' }}>Export Excel</Button>} filename={'Warehouse_' + currentDateTime}>
            //                     <ExcelSheet data={this.props.InventorySkuWarehouseData} name="Warehouse">
            //                         {
            //                             InventorySkuWarehouseDataElements.map(
            //                                 ({ dBKey, displayName }, idx) => {
            //                                     return (
            //                                         <ExcelColumn label={displayName} value={dBKey} />
            //                                     );
            //                                 }
            //                             )
            //                         }
            //                     </ExcelSheet>
            //                 </ExcelFile>
            //             </Row>
            //         </div>
            //     }
            // </div>
        }
        else {
            countOfCases = 1
            // shipmentSkuTable = <div></div>
            // tableSkuDataDiv = <div></div>
        }



        let lotSkuTable;
        let lotSkuDataDiv;
        let unitGroupedDiv;
        if (this.props.isInventoryUnitWarehouseGroupedLoading) {
            unitGroupedDiv =
                <div align="center">
                    <section className="panel">
                    </section>
                    <Loader
                        type="TailSpin"
                        color="#3396E5"
                        height="50"
                        width="50"
                    />
                </div>
        }
        else if (typeof (this.props.InventoryUnitWarehouseGroupedData) !== 'undefined' && this.props.InventoryUnitWarehouseGroupedData != null) {

            lotSkuTable = RTableGeneral(
                'ID',     //   keyColumn,
                this.props.InventoryUnitWarehouseGroupedData,     //   tableData,
                InventoryUnitWarehouseGroupedDataElements,       //   DataElements,
                this.props.isInventoryUnitWarehouseGroupedLoading,        //   loading,

                this.props.isMobile,        //   isMobile,
                this.props.history,     //   history,

                sortColumn,     //   sortColumn,
                sortType,       //   sortType,
                this.handleSortColumn,      //   handleSortColumn,
                false, // checkColEntry,
                null,       //   checkedKeys,
                null,       //   handleCheckAll,
                null,       //   handleCheck,

                false,      //   boolSelectButton,
                null,       //   onClickSelect,
                null,       //   selectButtonText,
                null,       //   selectHighlightRowKey,

                true,       //   link1bool,
                'Units',     //   link1text,
                this.onClickUnitDetails,     //   link1onClick,

                false,       //   link2bool,
                null,       //   link2text,
                null  //   link2onClick
            )
            lotSkuDataDiv = <div>
                {(this.props.isMobile == true) ? <div></div> :
                    <div>
                        <Row>
                            <ExcelFile element={<Button style={{ float: 'right' }}>Export Excel</Button>} filename={'Units_' + currentDateTime}>
                                <ExcelSheet data={this.props.InventoryUnitWarehouseGroupedData} name="Units">
                                    {
                                        InventoryUnitWarehouseGroupedDataElements.map(
                                            ({ dBKey, displayName }, idx) => {
                                                return (
                                                    <ExcelColumn label={displayName} value={dBKey} />
                                                );
                                            }
                                        )
                                    }
                                </ExcelSheet>
                            </ExcelFile>
                        </Row>
                    </div>
                }
            </div>

            if (this.props.InventoryUnitWarehouseGroupedData.length > 0) {
                unitGroupedDiv = <Row >
                    <Col md={24} sm={24}>
                        <Panel bordered header="Units" style={{ marginBottom: 10 }}>
                            {lotSkuDataDiv}
                            {lotSkuTable}
                        </Panel>
                    </Col>
                </Row>

            }
            else {
                unitGroupedDiv = <div></div>
            }
        }
        else {
            unitGroupedDiv = <div></div>
        }





        let palletStatusID = 0;
        if (this.props.isInventoryPalletWarehouseLoading) {
            palletStatusID = 0
        }
        else if (typeof (this.props.InventoryPalletWarehouseData[0]) !== 'undefined' && this.props.InventoryPalletWarehouseData != null && typeof (this.props.InventoryPalletWarehouseData[0]) !== 'undefined' && this.props.InventoryPalletWarehouseData != null) {
            palletStatusID = this.props.InventoryPalletWarehouseData[0].PalletStatusID
        }
        else {
            palletStatusID = 0
        }


        let countOfUnits = 1;
        let shipmentCaseTable;
        let tableCaseDataDiv;
        let caseDiv;
        if (this.props.isInventoryCaseWarehouseLoading) {
            countOfUnits = 1
            shipmentCaseTable = <div></div>
            caseDiv =
                <div align="center">
                    <section className="panel">
                    </section>
                    <Loader
                        type="TailSpin"
                        color="#3396E5"
                        height="50"
                        width="50"
                    />
                </div>
        }
        else if (typeof (this.props.InventoryCaseWarehouseData) !== 'undefined' && this.props.InventoryCaseWarehouseData != null) {
            countOfUnits = this.props.InventoryCaseWarehouseData.length

            const values = queryString.parse(this.props.location.search)
            try {
                var paramPoStatusID = values.statusid.split(',').map(Number);
            }
            catch (e) {
                var paramPoStatusID = []
            }
            shipmentCaseTable = RTablePalletDetailsCases(
                'CaseID',     //   keyColumn,
                this.props.InventoryCaseWarehouseData,     //   tableData,
                InventoryCaseWarehouseDataElements,       //   DataElements,
                this.props.isInventoryCaseWarehouseLoading,        //   loading,

                this.props.isMobile,        //   isMobile,
                this.props.history,     //   history,

                sortColumn,     //   sortColumn,
                sortType,       //   sortType,
                this.handleSortColumn,      //   handleSortColumn,

                // true, // checkColEntry,
                (palletStatusID == 1 || palletStatusID == 2 ? true : false), // checkColEntry,
                checkedKeys,       //   checkedKeys,
                this.handleCheckAllCases,       //   handleCheckAll,
                this.handleCheck,       //   handleCheck,

                false,      //   boolSelectButton,
                null,       //   onClickSelect,
                null,       //   selectButtonText,
                null,       //   selectHighlightRowKey,

                true,       //   link1bool,
                'Details',     //   link1text,
                this.onClickCaseDetails,     //   link1onClick,

                true,       //   link2bool,
                'Move',       //   link2text,
                this.onClickMoveCase  //   link2onClick
            )
            tableCaseDataDiv = <div>
                {(this.props.isMobile == true) ? <div></div> :
                    <div>
                        <Row>



                            {(palletStatusID == 1 || palletStatusID == 2) && (this.state.checkedKeys.length > 0) ? <Button style={{ float: 'left' }} onClick={(e) => { this.onClickMoveMultipleCase(e) }}>Move Selected Cases</Button> : ''}



                            {/* Display count of selected cases */}
                            {this.state.checkedKeys && this.state.checkedKeys.length > 0 && (
                                <div style={{ float: 'left', marginLeft: '10px' }}>
                                    {/* Selected Cases: {this.state.checkedKeys.length} */}

                                    <InputGroup style={{ float: 'left', marginLeft: '10px', marginBottom: '10px' }}>
                                        <InputGroup.Addon>Selected Cases:</InputGroup.Addon>
                                        <Input
                                            id="barcodeinput"
                                            placeholder="Barcode"
                                            value={this.state.checkedKeys.length}
                                            readOnly
                                        />
                                    </InputGroup>
                                </div>
                            )}



                            {/* {paramPoStatusID.includes(1) || paramPoStatusID.includes(2) || paramPoStatusID == null ? <Button style={{ float: 'left' }} onClick={(e) => { this.onClickBuildLoad(e) }}>Build Load Selected</Button> : ''} */}
                            <ExcelFile element={<Button style={{ float: 'right' }}>Export Excel</Button>} filename={'Warehouse_' + currentDateTime}>
                                <ExcelSheet data={this.props.InventoryCaseWarehouseData} name="Warehouse">
                                    {
                                        InventoryCaseWarehouseDataElements.map(
                                            ({ dBKey, displayName }, idx) => {
                                                return (
                                                    <ExcelColumn label={displayName} value={dBKey} />
                                                );
                                            }
                                        )
                                    }
                                </ExcelSheet>
                            </ExcelFile>
                        </Row>
                    </div>
                }
            </div>

            if (this.props.InventoryCaseWarehouseData.length > 0) {
                caseDiv = <Row >
                    <Col md={24} sm={24}>
                        <Panel bordered header="Cases" style={{ marginBottom: 10 }}>
                            {tableCaseDataDiv}
                            {shipmentCaseTable}
                        </Panel>
                    </Col>
                </Row>
            }
            else {
                caseDiv = <div></div>
            }


        }
        else {
            countOfUnits = 1
            shipmentCaseTable = <div></div>
            tableCaseDataDiv = <div></div>
            caseDiv = <div></div>
        }




        let closePalletButton;



        let palletLabel;
        let palletContents;
        let skuDetailsDiv;
        let pageHeader = <div>Inventory Management - Pallet</div>
        if (this.props.isInventoryPalletWarehouseLoading) {
            skuDetailsDiv = <div align="center">
                <section className="panel">
                </section>
                <Loader
                    type="TailSpin"
                    color="#3396E5"
                    height="50"
                    width="50"
                />
            </div>
            palletStatusID = 0
        }

        else if (typeof (this.props.InventoryPalletWarehouseData[0]) !== 'undefined' && this.props.InventoryPalletWarehouseData != null && typeof (this.props.InventoryPalletWarehouseData[0]) !== 'undefined' && this.props.InventoryPalletWarehouseData != null) {
            try {
                var skuName = this.props.InventoryPalletWarehouseData[0].PalletBarcode.toString()
                pageHeader = <div style={{ marginBottom: '15px' }}>  {'Inventory Management - Pallet: ' + skuName} </div>
            }
            catch (e) {

            }



            if (this.props.isInventoryPalletContentsLoading) {
                palletLabel = <div align="center">
                    <section className="panel">
                    </section>
                    <Loader
                        type="TailSpin"
                        color="#3396E5"
                        height="50"
                        width="50"
                    />
                </div>
                palletContents = <div></div>

            }
            else {


                const remainingItems = Math.max(this.props.InventoryPalletContentsData.length - 9, 0);

                const palletBarcodesContent = this.props.InventoryPalletContentsData.slice(0, 9).map((content, index) => {
                    const barcode = content.PalletBarcode;
                    return (

                        <p style={{ fontSize: '12px', margin: '0', textAlign: 'left' }}>
                            Case SKU: {content.Case_SKU} (Qty: {content.CaseQty})
                            {content.LotCode ? ` Lot: ${content.LotCode}` : null}
                        </p>


                    );
                });

                if (remainingItems > 0) {
                    palletBarcodesContent.push(
                        <p style={{ fontSize: '12px', margin: '0', textAlign: 'left' }}>
                            {remainingItems} more rows...
                        </p>
                    );
                }

                palletLabel = <BarcodeCard
                    barcode={this.props.InventoryPalletWarehouseData[0].PalletBarcode}
                    barcodeContent={palletBarcodesContent}
                />


                // palletLabel = <BarcodeCard barcode={this.props.InventoryPalletWarehouseData[0].PalletBarcode} />
                palletContents = <PalletContents barcode={this.props.InventoryPalletWarehouseData[0].PalletBarcode} content={this.props.InventoryPalletContentsData} />
            }


            if (countOfCases == 0 && countOfUnits == 0 && this.props.InventoryPalletWarehouseData[0].PalletStatusID != 4) {
                closePalletButton = <Button style={{ float: 'right', marginRight: '10px' }} onClick={(e) => { this.onClickClosePallet(e) }} >Close Pallet</Button>
            }
            else {
                closePalletButton = <div></div>
            }


            skuDetailsDiv =
                <div>
                    <Row >
                        <Col md={24} sm={24}>
                            <Panel bordered style={{ marginBottom: 10 }} header={
                                <div style={{ float: 'right' }}>

                                    {
                                        [0, 1, 2].includes(this.props.InventoryPalletWarehouseData[0].PalletStatusID) ?
                                            <Button style={{ float: 'right', marginRight: '10px' }} onClick={(e) => { this.onClickInventoryDelete(e) }} >Delete Pallet</Button>
                                            : null
                                    }
                                    <Button style={{ float: 'right', marginRight: '10px' }} onClick={(e) => { this.movePalletModalShown(e) }} >Move Pallet Location</Button>
                                    <Button style={{ float: 'right', marginRight: '10px' }} onClick={(e) => { this.onClickEditPallet(e) }} >Edit Pallet</Button>
                                    {closePalletButton}
                                </div>
                            }>
                                
                                Warehouse Location: <strong>{this.props.InventoryPalletWarehouseData[0].WarehouseLocationName}</strong>
                                <br />
                                Warehouse: <strong>{this.props.InventoryPalletWarehouseData[0].WarehouseName}</strong>
                                <br />
                                Customer: <strong>{this.props.InventoryPalletWarehouseData[0].CustomerName}</strong>
                                <br />
                                Pallet Barcode: <strong>{this.props.InventoryPalletWarehouseData[0].PalletBarcode}</strong>
                                <br />
                                Recovery Date: <strong>{this.props.InventoryPalletWarehouseData[0].CreateDT}</strong>
                                <br />
                                Pallet Status: <strong>{this.props.InventoryPalletWarehouseData[0].PalletStatusName}</strong>
                                <br />
                                Oversize: <strong>{formatBitToTrueFalse(this.props.InventoryPalletWarehouseData[0].Oversize)}</strong>
                                <br />
                                Pallet Length: <strong>{this.props.InventoryPalletWarehouseData[0].Length}</strong>
                                <br />
                                Pallet Width: <strong>{this.props.InventoryPalletWarehouseData[0].Width}</strong>
                                <br />
                                Notes: <strong>{this.props.InventoryPalletWarehouseData[0].Notes}</strong>
                                <br />


                            </Panel>
                        </Col>
                    </Row>

                    <Row >
                        <Col md={24} sm={24}>
                            <Panel bordered style={{ marginBottom: 10 }} header={
                                <div >
                                    <Col md={12} sm={12}>
                                        <Button block onClick={(e) => { this.onPrintBarcode(e) }} >Print Pallet Label</Button>
                                    </Col>
                                    <Col md={12} sm={12}>
                                        <Button block onClick={(e) => { this.onPrintContent(e) }} >Print Pallet Contents</Button>
                                    </Col>
                                </div>
                            }>

                                <div>

                                    {showPrintableContent && (
                                        <div >

                                            {palletContents}
                                        </div>
                                    )}

                                    {showPrintableBarcode && (
                                        <div >
                                            {palletLabel}

                                        </div>
                                    )}

                                </div>
                            </Panel>
                        </Col>
                    </Row>




                </div>



        }
        else {
            skuDetailsDiv = <div></div>
            palletStatusID = 0
        }







        return (
            <div>
                <h3>
                    {pageHeader}
                </h3>

                <PanelGroup>
                    {skuDetailsDiv}
                </PanelGroup>


                {unitDiv}

                {caseDiv}


                {unitGroupedDiv}


                {/* <Row >
                    <Col md={24} sm={24}>
                        <Panel bordered header="Units" style={{ marginBottom: 10 }}>
                            {tableSkuDataDiv}
                            {shipmentSkuTable}
                        </Panel>
                    </Col>
                </Row> */}


                {palletLoadDiv}



                {this.state.isMoveCaseDrawerOpen && (
                    <MoveCaseDrawer
                        {...this.props}
                        {...this.state}
                        isOpen={this.state.isMoveCaseDrawerOpen}
                        toggleDrawer={this.toggleMoveCaseDrawer}
                        sortColumn={sortColumn}
                        sortType={sortType}
                        handleSortColumn={this.handleSortColumn}
                        onClickSelectPalletMoveCase={this.onClickSelectPalletMoveCase}
                    />
                )}

                {this.state.isMoveMultipleCaseDrawerOpen && (
                    <MoveMultipleCaseDrawer
                        {...this.props}
                        {...this.state}
                        isOpen={this.state.isMoveMultipleCaseDrawerOpen}
                        toggleDrawer={this.toggleMoveMultipleCaseDrawer}
                        sortColumn={sortColumn}
                        sortType={sortType}
                        handleSortColumn={this.handleSortColumn}
                        onClickSelectPalletMoveMultipleCase={this.onClickSelectPalletMoveMultipleCase}
                    />
                )}

                <MovePalletModal {...this.props} state={this.state}
                    movePalletModalShown={this.state.movePalletModalShown}
                    handleSavePalletModal={this.handleSavePalletModal}
                    handleClosePalletModal={this.handleClosePalletModal}
                    onChangePalletModal={this.onChangePalletModal}
                />


                <PalletWarehouseLocationHistoryDiv
                    isInventoryPalletWarehouseLocationHistoryLoading={this.props.isInventoryPalletWarehouseLocationHistoryLoading}
                    InventoryPalletWarehouseLocationHistoryData={this.props.InventoryPalletWarehouseLocationHistoryData}
                />
                {/* <CaseHistoryDiv
                    isInventoryCaseHistoryLoading={this.props.isInventoryCaseHistoryLoading}
                    InventoryCaseHistoryData={this.props.InventoryCaseHistoryData}
                /> */}
                {/* <CaseSKUsHistoryDiv
                    isInventoryCaseSkusHistoryLoading={this.props.isInventoryCaseSkusHistoryLoading}
                    InventoryCaseSkusHistoryData={this.props.InventoryCaseSkusHistoryData}
                /> */}

                <LoadPalletHistoryDiv
                    isInventoryLoadPalletsHistoryLoading={this.props.isInventoryLoadPalletsHistoryLoading}
                    InventoryLoadPalletsHistoryData={this.props.InventoryLoadPalletsHistoryData}
                />




                {/* {skuRecoveryTimeline}
                {skuWarehouseLocationHistoryTimeline} */}


                {/* {editWarehouseLocationDrawerDiv} */}

                {/* <EditSkuDetailsDrawer {...this.props} state={this.state} onChangeProject={this.onChangeProject} onChangeNotes={this.onChangeNotes} onChangeCustomerRef={this.onChangeCustomerRef} onChangeSKU={this.onChangeSKU} confirm={this.onClickConfirmEditSku} close={this.closeEditSkuDrawer} unsavedChanges={this.state.unsavedEditSkuValues} /> */}


                {/* {BarcodeDiv} */}

                <HistoryTypeModal
                    open={this.state.historyTypeModalOpen}
                    handleClose={this.handleModalClose}
                    data={this.props.InventoryLotHistoryTypeData}
                    loading={this.props.isInventoryLotHistoryTypeLoading}
                    handleSelect={this.handleModalSelect}
                />



                <EditPalletDrawer {...this.props} state={this.state} show={this.state.showEditPalletDrawer} onChangeEditPallet={this.onChangeEditPallet} confirm={this.onClickConfirmEditCase} close={this.closeEditPalletDrawer} />


            </div>
        );

    }
}

export default withRouter(PalletDetail);
